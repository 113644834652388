import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'
import PublicationArticle from '../PublicationArticle'

import Link from '../../helpers/Link'

import publication01 from '../../assets/publications/01.jpg'
import publication02 from '../../assets/publications/02.jpg'
import publication03 from '../../assets/publications/03.jpg'
import publication04 from '../../assets/publications/04.jpg'
import publication05 from '../../assets/publications/05.jpg'
import publication06 from '../../assets/publications/06.jpg'
import publication09 from '../../assets/publications/09.jpg'
import publication10 from '../../assets/publications/10.jpg'
import publication11 from '../../assets/publications/11.jpg'
import publication12 from '../../assets/publications/12.jpg'
import publication13 from '../../assets/publications/13.jpg'
import publication14 from '../../assets/publications/14.jpg'
import publication15 from '../../assets/publications/15.jpg'
import publication16 from '../../assets/publications/16-02.png'
import og from '../../assets/publications/og_v2.png'

export default class extends Component {
  state = {
    publications: [],
    publications_heading: '',
    publications_description: ''
  }

  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentDidMount () {
    document.documentElement.style.overflow = ''

	  /*
    const test = {
      api: '42Rf0Ds3p27b84F',
      link: 'https://test.shuka.design/api/publications/list'
    }

    const production = {
      api: '42Rf0Ds3.p27b84F',
      link: '/api/publications/list'
    }
    */

    const data = new FormData()
    data.append('_api_key', process.env.REACT_APP_API_KEY)

    fetch(process.env.REACT_APP_PUBLICATIONS_LIST_URL, {
      method: 'POST',
      body: data
    })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad response from server")
        }
        return response.json()
      })
      .then((data) => {
        const { publications, publications_description, publications_heading, publications_title } = data._data

        this.setState({
          publications,
          publications_description,
          publications_heading,
          publications_title
        })
      })
  }

  render () {
    const { publications, publications_description, publications_heading } = this.state

    return (
      <div className='case-root'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka-related 👍 mentions</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books, Logotypes, Logotype" />
          <meta name="description" content="We have put together a little collection. What people think of us. What have they asked us." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="We have put together a little collection. What people think of us. What have they asked us." />
          <meta property="og:title" content="Shuka-related 👍 mentions" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <TouchNav navBg='transparent' color='dark' />
          <CaseHead leftTitle='publica<wbr>tions:' rightTitle={ publications_heading } menuColor='dark' isTech='true' />
        </div>
        <div className='head-line Cf' style={{marginBottom: '100px'}}>
          <div className="head-line-start icon zero">e i e</div>
          <p className="head-line-end mercury" dangerouslySetInnerHTML={{ __html: publications_description }}></p>
        </div>
        <div className='portfolio at-main' style={{marginTop: '-40px'}}>
          { publications.map((publication, idx) => {
              return (
                <PublicationArticle key={idx}
                  img={publication.image_url}
                  youtube={publication.custom_html}
                  case={publication.case_link}
                  magazine={publication.source_name} year={publication.year}
                  text={publication.description}
                  link={publication.link} />
              )
            })
          }
        </div>
      </div>
    )
  }
}
