import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import og from '../../assets/og_awards.png'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'
import Link from '../../helpers/Link'

export default class extends Component {
	componentWillMount () {
		window.scrollTo(0, 0)
	}
	componentDidMount () {
		document.documentElement.style.overflow = ''
	}
	render () {
		return (
			<div className="case-root">
				<Helmet>
					<meta charSet="utf-8"/>
					<title>Shuka 🏆 awards</title>
					<meta name="keywords"
					      content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books"/>
					<meta name="description" content="Our design is award-winning. Check out why."/>
					<meta property="og:site_name" content="Shuka Brand Bureau"/>
					<meta property="og:description" content="Our design is award-winning. Check out why."/>
					<meta property="og:title" content="Shuka 🏆 awards"/>
					<meta property="og:type" content="website"/>
					<meta property="og:image" content={og}/>
					<meta property="og:image:width" content="1200"/>
					<meta property="og:image:height" content="630"/>
				</Helmet>
				<div className="case-root--inner" data-trigger="dark">
					<TouchNav navBg="transparent" color="dark"/>
					<CaseHead leftTitle="awards:" rightTitle="gold trophies and consolation prizes" menuColor="dark"
					          isTech="true"/>
				</div>
				<div className="head-line Cf">
					<div className="head-line-start icon zero">iii</div>
					<p className="head-line-end mercury">The professional contests go like this: some designers present other
						designers with design awards. Sounds a bit dull but anyway who would refuse to have a haircut done by a
						champion barber among the other barbers?</p>
				</div>
				<div className="events-table Cf">


					{/*2021*/}
					{/* --- 1520 --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a
								href="https://awards.artdirectorsclub.ru/winners/?year=2021"
								target="_blank">Graphic Communication</a><br/>Gold</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/1520" className="events-cell-a"><b className="underline"/>1520</Link>
							</span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021" target="_blank">Corporate Brand Identity</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>1520
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021" target="_blank">Illustration</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>1520
				        </span>
						</div>
					</div>

					{/* --- Yyermolova Theatre --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021" target="_blank">Typography</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><a className="events-cell-a"
			                                     href="https://www.behance.net/gallery/122873723/Yermolova-Theatre-Logo-and-Typeface"
			                                     target="_blank">
				        <b className="underline"/>Yermolova Theatre</a>
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021" target="_blank">Logotype</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>Yermolova Theatre
				        </span>
						</div>
					</div>

					{/* --- Photozhir --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021"
							                                                           target="_blank">Graphic Communication</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/photozhir" className="events-cell-a">
				        <b className="underline"/>Photozhir</Link>
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021" target="_blank">Corporate Brand Identity</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>Photozhir
				        </span>
						</div>
					</div>

					{/* --- Dolyami --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021" target="_blank">Corporate Brand Identity</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/dolyame" className="events-cell-a">
				        <b className="underline"/>Dolyami</Link>
				        </span>
						</div>
					</div>

					{/* --- Cian --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021"
							                                                           target="_blank">Graphic Communication</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/cian" className="events-cell-a">
				        <b className="underline"/>Cian</Link>
				        </span>
						</div>
					</div>

					{/* --- ABBYY --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021" target="_blank">Corporate Brand Identity</a><br/>Shortlist</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/abbyy" className="events-cell-a">
				        <b className="underline"/>ABBYY</Link>
				        </span>
						</div>
					</div>

					{/* --- Freezers --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Dieline<br/><a href="https://thedieline.com/blog/2021/1/15/-freezers--packaging-for-contemporary-russian-ice-cream?" target="_blank">Conceptual</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><a className="events-cell-a"
			                                     href="https://www.behance.net/gallery/116947607/Freezers-Brand-Identity"
			                                     target="_blank">
				        <b className="underline"/>Freezers</a>
				        </span>
						</div>
					</div>

					{/* --- Porter & Sail --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2021</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">The White Square<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2021" target="_blank">Rebrand</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/porter-and-sail" className="events-cell-a">
				        <b className="underline"/>Porter & Sail</Link>
				        </span>
						</div>
					</div>

					{/*2020*/}
					{/* --- Sber --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Big Fish<br/><a href="https://www.sostav.ru/vote/51/awards?cat=267#workId6307" target="_blank">Branding</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/sber" className="events-cell-a">
				        <b className="underline"/>Sber</Link>
				        </span>
						</div>
					</div>

					{/* --- Lamoda --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="http://2020.festivalsreda.ru/winners/" target="_blank">Rebranding</a><br/>Gold</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/lamoda" className="events-cell-a">
				        <b className="underline"/>Lamoda</Link>
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Red Dot<br/><a href="https://www.red-dot.org/project/lamoda-48636" target="_blank">Brands & Communication Design</a><br/>Winner</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>Lamoda
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2020" target="_blank">Corporate Brand Identity</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>Lamoda
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="https://2020.fstivalsreda.ru/winners/"
							                                       target="_blank">Typography in Identity</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>Lamoda
				        </span>
						</div>
					</div>

					{/* --- World Chess Armageddon --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="http://2020.festivalsreda.ru/winners/" target="_blank">Events Design</a><br/>Gold</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/armageddon" className="events-cell-a">
				        <b className="underline"/>World Chess Armageddon</Link>
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="http://2020.festivalsreda.ru/winners/" target="_blank">Новая айдентика</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>World Chess Armageddon
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="http://2020.festivalsreda.ru/winners/"
							                                       target="_blank">Typography in Identity</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>World Chess Armageddon
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2020"
							                                                           target="_blank">Graphic Communication</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>World Chess Armageddon
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="http://2020.festivalsreda.ru/winners/" target="_blank">Paratype Special Prize</a></span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>World Chess Armageddon
				        </span>
						</div>
					</div>

					{/* --- Nike Running --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2020"
							                                                           target="_blank">Graphic Communication</a><br/>Gold</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/nike" className="events-cell-a">
				        <b className="underline"/>Nike Running</Link>
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="http://2020.festivalsreda.ru/winners/" target="_blank">Illustration</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>Nike Running
				        </span>
						</div>
					</div>

					{/* --- Flacon --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="http://2020.festivalsreda.ru/winners/" target="_blank">Public Spaces</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/flacon" className="events-cell-a">
				        <b className="underline"/>Flacon</Link>
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="http://2020.festivalsreda.ru/winners/" target="_blank">Rebranding</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>Flacon
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2020" target="_blank">Corporate Brand Identity</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>Flacon
				        </span>
						</div>
					</div>

					{/* --- Skyeng --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Sreda<br/><a href="http://2020.festivalsreda.ru/winners/" target="_blank">Rebranding</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/skyeng" className="events-cell-a">
				        <b className="underline"/>Skyeng</Link>
				        </span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2020" target="_blank">Corporate Brand Identity</a><br/>Shortlist</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury">
				        <b className="underline"/>Skyeng
				        </span>
						</div>
					</div>

					{/* --- ORBI --- */}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2020</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2020" target="_blank">Corporate Brand Identity</a><br/>Shortlist</span>
						</div>
						<div className="events-cell events-cell--c Cf">
			        <span className="mercury"><Link to="/orbi" className="events-cell-a">
				        <b className="underline"/>ORBI</Link>
				        </span>
						</div>
					</div>

					{/*2019*/}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2019</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/>
								<a href="https://awards..ru/winners/?year=2019" target="_blank">Graphic Communication</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
							<span className="mercury"><Link to="/world-chess-london" className="events-cell-a"><b
	className="underline"/>World Chess Championship 2018 London</Link></span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2019</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Cannes Lions<br/>Refresh of an Existing Brand<br/>Shortlist</span>
						</div>
						<div className="events-cell events-cell--c Cf">
							<span className="mercury"><b
	className="underline"/>World Chess Championship 2018 London</span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2019</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Art Directors Club Russia<br/><a href="https://awards.artdirectorsclub.ru/winners/?year=2019" target="_blank">Corporate Brand Identity</a>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
							<span className="mercury"><Link to="/iitp-identity" className="events-cell-a"><b
								className="underline"></b> Institute for Information Transmission Problems</Link></span>
						</div>
					</div>

					{/*2016*/}
					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2016</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">European Design Awards<br/>
								<a href="" target="_blank">Integrated Identity Applications</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
							<span className="mercury"><Link to="/pure-app" className="events-cell-a"><b className="underline"/>Pure</Link></span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2015</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">Zhar-Kniga<br/><a href="http://zharkniga.ru/2015/ru/short/spam-karabasa" target="_blank">Grand Prix</a></span>
						</div>
						<div className="events-cell events-cell--c Cf">
							<span className="mercury"><Link to="/karabas" className="events-cell-a"><b className="underline"/>Karabas’ Spam</Link></span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2014</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">European Design Awards<br/>
								<a href="https://europeandesign.org/submissions/moscow-heritage-2/" target="_blank">Magazine</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
							<span className="mercury"><a href="https://shuka.design/moscow-heritage-2013" className="events-cell-a"><b
	className="underline"/>The Moscow Heritage Magazine (Redesign)</a></span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2013</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">European Design Awards<br/>
								<a href="https://europeandesign.org/submissions/moscow-heritage/" target="_blank">Magazine</a><br/>Silver</span>
						</div>
						<div className="events-cell events-cell--c Cf">
							<span className="mercury"><a href="https://shuka.design/moscow-heritage-2012" className="events-cell-a"><b
	className="underline"/>The Moscow Heritage Magazine</a></span>
						</div>
					</div>

					<div className="events-table--row">
						<div className="events-cell events-cell--a Cf">
							<span className="mercury">2009</span>
						</div>
						<div className="events-cell events-cell--b Cf">
							<span className="mercury">European Design Awards<br/>
								<a href="https://europeandesign.org/submissions/concept-007/" target="_blank">Promotional Site</a><br/>Bronze</span>
						</div>
						<div className="events-cell events-cell--c Cf">
							<span className="mercury">
								<a href="http://concept007.ru/" className="events-cell-a" target="_blank"
							                             rel="noopener noreferrer"><b className="underline"></b>Concept 007</a>
								</span>
						</div>
					</div>

				</div>
			</div>
		)
	}
}
