import React, { Component, Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'
import * as css from 'classnames'
import { Fade } from 'react-slideshow-image'
import Link from '../helpers/Link.js'
import ImageWebp from '../helpers/ImageWebp.js'
import TouchNav from './TouchNav'
// import og from '../assets/og3.png'
// import chess from '../assets/work-preview-new/chess/chess.jpg'
// import chesswebp from '../assets/work-preview-new/chess/chess.webp'
// import chess_eyes from '../assets/work-preview-new/chess/girl_eyes.png'
// import chess_eyeswebp from '../assets/work-preview-new/chess/girl_eyes.webp'
// import flacon from '../assets/work-preview-new/flacon_sign.svg'
// import nike from '../assets/work-preview-new/nike/nike.jp2'
// import nikewebp from '../assets/work-preview-new/nike/nike.webp'
// import run from '../assets/work-preview-new/nike/Nike_kv.svg'
// import runwebp from '../assets/work-preview-new/nike/Nike_kv.webp'
// import lamoda from '../assets/work-preview-new/16-6.jpg'
// import ba from '../assets/work-preview-new/biblioteka_main.jpg'
// import bawebp from '../assets/work-preview-new/biblioteka_main.webp'
// import rossiyskoe from '../assets/work-preview-new/derbent-main-rose.jp2'
// import rossiyskoewebp from '../assets/work-preview-new/derbent-main-rose.webp'
// import semrush from '../assets/work-preview-new/SQUARE_KV.mp4'
// import semrushFallback from '../assets/work-preview-new/SQUARE_KV.gif'
// import porterReverse from '../assets/work-preview-new/PS Cover for site.mp4'
// import porter from '../assets/work-preview-new/PS Cover for site reverse.mp4'
// import porterFallback from '../assets/work-preview-new/ezgif-frame-001.jpg'
// import skyeng from '../assets/work-preview-new/skyeng.mp4'
// import skyengFallback from '../assets/work-preview-new/skyeng.png'
// import abbyy from '../assets/work-preview-new/_Header_1800x900.mp4'
// import abbyyFallback from '../assets/work-preview-new/abbyy-3.jpg'
// import photozhir from '../assets/work-preview-new/photozhir.jpg'
// import photozhirwebp from '../assets/work-preview-new/photozhir.webp'
const og = `${process.env.REACT_APP_MEDIA}/media/og3.png`
const chess = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/chess/chess.jpg`
const chesswebp = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/chess/chess.webp`
const chess_eyes = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/chess/girl_eyes.png`
const chess_eyeswebp = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/chess/girl_eyes.webp`
const flacon = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/flacon_sign.svg`
const nike = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/nike/nike.jp2`
const nikewebp = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/nike/nike.webp`
const run = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/nike/Nike_kv.svg`
const runwebp = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/nike/Nike_kv.webp`
const lamoda = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/16-6.jpg`
const ba = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/biblioteka_main.jpg`
const bawebp = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/biblioteka_main.webp`
const rossiyskoe = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/derbent-main-rose.jp2`
const rossiyskoewebp = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/derbent-main-rose.webp'`
const semrush = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/SQUARE_KV.mp4`
const semrushFallback = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/SQUARE_KV.gif`
const porterReverse = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/PS Cover for site.mp4`
const porter = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/PS Cover for site reverse.mp4`
const porterFallback = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/ezgif-frame-001.jpg`
const skyeng = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/skyeng.mp4`
const skyengFallback = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/skyeng.png`
const abbyy = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/abbyy/header_square.mp4`
const abbyyReverse = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/abbyy/header_square.mp4`
const abbyyFallback = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/abbyy/header_square.jpg`
const photozhir = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/photozhir.jpg`
const photozhirwebp = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/photozhir.webp`
const sber = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/sber/mainpage_video.mp4`
const sberFallback = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/sber/mainpage_video.gif`
const sberFallbackWebp = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/sber/mainpage_video.webp`
const dolyame = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/dolyame/project_preview.mp4`
const dolyameFallback = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/dolyame/project_preview.png`
const delivery1520 = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/delivery1520/project_preview.mp4`
const delivery1520Fallback = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/delivery1520/project_preview.png`
const cian = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/cian/project_preview.mp4`
const cianReverse = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/cian/project_preview.mp4`
const cianFallback = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/cian/project_preview.png`
const seasons = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/seasons/mainpage_video.mp4`
const seasonsFallback = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/seasons/mainpage_video.png`
const PortfolioCellLarge = lazy(() => import('./PortfolioCellLarge'))
const PortfolioCellNew = lazy(() => import('./PortfolioCellNew'))
const SubjectCases = lazy(() => import('./SubjectCases'))
export default class extends Component {
	state = {
		work1Ran: false,
		work2Ran: false
	}
	componentWillMount () {
		window.scrollTo(0, 0)
	}
	componentWillUnmount () {
		document.removeEventListener('scrolled', this.onScroll)
	}
	componentDidMount () {
		document.addEventListener('scrolled', this.onScroll)
	}
	onScroll = (ev) => {
		const scrolled = ev.data.top
		if (scrolled + (window.innerHeight * .7) >= this.refs['more-work-1'].offsetTop && !this.state.work1Ran) {
			this.setState({
				work1Ran: true
			})
		}
		if (scrolled + (window.innerHeight * .7) >= this.refs['more-work-2'].offsetTop && !this.state.work2Ran) {
			this.setState({
				work2Ran: true
			})
		}
	}
	render () {
		const fadeProperties = {
			duration: 2000,
			transitionDuration: 500,
			infinite: true,
			indicators: false,
			arrows: false
		}
		return (
			<div className="main-wrap">
				<Helmet>
					<meta charSet="utf-8"/>
					<title>Shuka ★ Brand Bureau</title>
					<meta name="keywords"
					      content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books"/>
					<meta name="description"
					      content="We tailor brand identities out of your own story. One size that uniquely fits you"/>
					<meta property="og:site_name" content="Shuka Brand Bureau"/>
					<meta property="og:description"
					      content="We tailor brand identities out of your own story. One size that uniquely fits you"/>
					<meta property="og:title" content="Shuka ★ Brand Bureau"/>
					<meta property="og:type" content="website"/>
					<meta property="og:image" content={og}/>
					<meta property="og:image:width" content="1200"/>
					<meta property="og:image:height" content="630"/>
				</Helmet>
				<TouchNav/>
				<header className="head new new2022">
					<div className="head-body">
						<div className="head-body--inner">
							<div className="head-logo mars">
								<div className="head-logo-text">
									<div className="head-logo-text--main"><span>shuka</span><span className="umlaut">shüka</span></div>
									<span className="head-logo-text--desc">the brand bureau</span>
								</div>
							</div>
						</div>
					</div>
				</header>

				<div className="new_first">

					<div className="case-block case-block--margins-mobile case-block--with-margins jiffy-title-wrap"
					     data-trigger="dark">

						<div className="jiffy-title-wrap--block">
							<div>London's instant <br/>delivery, Sir and <br/>Madam</div>
							<a href="/jiffy">Take off</a>
						</div>

					</div>

					<div className="is-desk">
						<iframe title="1"
						        loading="lazy"
						        src="https://player.vimeo.com/video/712869344?quality=1080p&20badge=0&autopause=0&background=1&player_id=0&app_id=58479"
						        frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
					</div>

					<div className="is-table">
						<iframe title="1"
						        loading="lazy"
						        src="https://player.vimeo.com/video/712869072?quality=1080p&20badge=0&autopause=0&background=1&player_id=0&app_id=58479"
						        frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
					</div>

					<div className="is-mob">
						<iframe title="1"
						        loading="lazy"
						        src="https://player.vimeo.com/video/712869189?quality=1080p&20badge=0&autopause=0&background=1&player_id=0&app_id=58479"
						        frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
					</div>

				</div>

				<div className="portfolio at-main" style={{ zIndex: 2 }} ref="portfolio">
					<Suspense fallback={<div className="loader loader--small"></div>}>


						{/*<PortfolioCellLarge setPreset={this.props.setPreset} video={sber} videoFallback={sberFallback} link='/sber' title="Scaling Sber brand identity" secondTitle="to the size of an ecosystem" show={true}/>*/}


						{/*<PortfolioCellLarge setPreset={this.props.setPreset} video={sber} videoFallback={sberFallback} link='/sber' title="Scaling Sber brand identity" secondTitle="to the size of an ecosystem" show={true}/>*/}

						<PortfolioCellNew setPreset={this.props.setPreset}
						                  videoReverse={cianReverse}
						                  video={cian}
						                  videoFallback={cianFallback}
						                  reverse={true}
						                  link="/cian"
						                  title='Building Cian brand<br class="is-hidden--mob"/><br class="is-hidden--mob"/>'
						                  secondTitle="a five-story communication"/>

						<PortfolioCellNew setPreset={this.props.setPreset}
						                  video={delivery1520}
						                  videoFallback={delivery1520Fallback}
						                  link="/1520"
						                  title='boosting 1520 delivery<br class="is-hidden--mob"/><br class="is-hidden--mob"/>'
						                  secondTitle="with the juicy visual envelope"/>

						<PortfolioCellLarge setPreset={this.props.setPreset}
						                    video={seasons}
						                    videoFallback={seasonsFallback}
						                    link="/seasons"
						                    title="Growing Seasons IT series"
						                    secondTitle="a dystopian visual identity" show={true}/>

						<PortfolioCellNew setPreset={this.props.setPreset}
						                  videoReverse={abbyyReverse}
						                  video={abbyy}
						                  videoFallback={abbyyFallback}
						                  reverse={true}
						                  link="/abbyy"
						                  title='Making Digital Intelligence<br class="is-hidden--mob"/><br class="is-hidden--mob"/>'
						                  secondTitle="Exciting and soulful"/>

						<PortfolioCellNew setPreset={this.props.setPreset}
						                  video={dolyame}
						                  videoFallback={dolyameFallback}
						                  link="/dolyame"
						                  title='Uniting Долями quarters<br class="is-hidden--mob"/><br class="is-hidden--mob"/>'
						                  secondTitle="into ascending visual language"/>

						{/*<PortfolioCellLarge setPreset={this.props.setPreset} video={abbyy} videoFallback={abbyyFallback} link='/abbyy' title="Making Digital Intelligence" secondTitle="Exciting and soulful" show={true}/>*/}
						<PortfolioCellNew setPreset={this.props.setPreset}
						                  videoReverse={porterReverse}
						                  video={porter}
						                  videoFallback={porterFallback}
						                  reverse={true}
						                  link="/porter-and-sail"
						                  title="Assisting Porter & Sail"
						                  secondTitle="to reimagine all the booking"/>

						<PortfolioCellNew setPreset={this.props.setPreset} video={semrush} videoFallback={semrushFallback}
						                  link="/semrush" title="Igniting marketing mechanism"
						                  secondTitle="By the semrush of fire"/>

						<PortfolioCellLarge setPreset={this.props.setPreset} img={[chess, chess_eyes]}
						                    imgWebp={[chesswebp, chess_eyeswebp]} side="right" link="/world-chess-london"
						                    title="re-humanising <br class='is-hidden--desk'/>world chess <br class='is-hidden--desk'/>brand"
						                    secondTitle="with the <span class='icon'>q</span> <br class='is-hidden--desk'/>'pawnographic' identity"/>
						<PortfolioCellNew setPreset={this.props.setPreset} img={lamoda} link="/lamoda" background={true}
						                  title="dressing up lamoda" secondTitle="into the daily fashion consultant"/>
						<PortfolioCellNew setPreset={this.props.setPreset} video={skyeng} videoFallback={skyengFallback}
						                  link="/skyeng"
						                  title='rethinking skyeng identity<br class="is-hidden--mob"/><br class="is-hidden--mob"/>'
						                  secondTitle="by creating skyway brand architecture"/>
						<PortfolioCellLarge setPreset={this.props.setPreset} img={[nike, run]} imgWebp={[nikewebp, runwebp]}
						                    side="left" link="/nike"
						                    title="enriching <br class='is-hidden--desk'/>nike berlin <br class='is-hidden--desk'/>collection"
						                    secondTitle="with <br class='is-hidden--desk'/>the <span class='icon'>D</span> raving <br class='is-hidden--desk'/>graphics"/>
						<PortfolioCellNew setPreset={this.props.setPreset}
						                  img={photozhir}
						                  imgWebp={photozhirwebp}
						                  link="/photozhir"
						                  title="Embodying Photozhir brand"
						                  secondTitle="with the dove’s anatomy"/>
						{/*<PortfolioCellNew setPreset={this.props.setPreset} img={flacon} link='/flacon' data-bodymovin="flacon" title='revitalising flacon brand' secondTitle='from the design factory into the design district' />*/}
						<PortfolioCellNew setPreset={this.props.setPreset} img={ba} imgWebp={bawebp} link="/fragrance-library"
						                  title="crafting an odd identity" secondTitle="for the biblioteka aromatov bizzare odors"/>
					</Suspense>
				</div>
				<div className="portfolio-more-btn pluto" ref="more-work-1">
					<Link to="/work" className="portfolio-href" setPreset={this.props.setPreset}>
						<div className="portfolio-more-btn--inner">
							<span className={css('portfolio-more-btn--pretext', 'pluto', { 'is-animated': this.state.work1Ran })}>tap to view</span><span
							className="portfolio-more-btn--href">MORE PROJECTS</span>
						</div>
					</Link>
				</div>
				<Suspense fallback={<div className="loader loader--small"></div>}>
					<SubjectCases/>
				</Suspense>
				<div className="portfolio-more-btn pluto" ref="more-work-2">
					<Link to="/work" className="portfolio-href" setPreset={this.props.setPreset}>
						<div className="portfolio-more-btn--inner">
							<span
								className={css('portfolio-more-btn--pretext', 'is-long', 'pluto', { 'is-animated': this.state.work2Ran })}>last call to view</span><span
							className="portfolio-more-btn--href">MORE PROJECTS</span>
						</div>
					</Link>
				</div>
			</div>
		)
	}
}
