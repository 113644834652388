import React from 'react'
import { Link } from 'react-router-dom'

import scrollBy from './scrollBy'

export default (props) => {
  const techPages= ['/', '/newsletters', '/work', '/about', '/contact', '/publications', '/awards', '/education', '/press', '/careers', '/hints', '/emojinarium']

  let classes = props.className

  if (props.currentPage === props.to) {
    classes += ' is-active'
  }

  if (props.secondTitle !== undefined) {
    classes += ' has-second'
  }

  if (props.children === undefined) {
    let second = (props.subtitle !== undefined) ? `<sup class="portfolio-title-index mercury nobr">${props.subtitle}</sup>` : (props.secondTitle !== undefined) ? `<span class="portfolio-href--second is-hidden--mob">${props.secondTitle}</span>` : ''
    return (
      <Link to={{
        pathname: props.to,
        state: { prev: window.location.pathname },
      }} className={ classes }><div dangerouslySetInnerHTML={{ __html: `<span class="first">${props.content}</span>${second}` }}></div></Link>
    )
  } else if (props.content === undefined) {
    return (
      <Link to={{
        pathname: props.to,
        state: { prev: window.location.pathname },
      }} className={ classes }>{ props.children }</Link>
    )
  }
}
