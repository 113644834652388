import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import og from '../../assets/careers/og.v3.png'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'

import setAspectRatio from '../../helpers/setAspectRatio'

import stuffed from '../../assets/careers/nobody-wanted.jpg'

export default class extends Component {
  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    document.documentElement.style.overflow = ''
  }

  render () {
    return (
      <div className='case-root'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka 💡 careers</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="Check out whether you are ready to get devoured by Shuka." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="Check out whether you are ready to get devoured by Shuka." />
          <meta property="og:title" content="Shuka 💡 careers" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <TouchNav navBg='transparent' color='dark' />
          <CaseHead leftTitle='careers:' rightTitle="vacant positions and internships" menuColor='dark' isTech='true' />
        </div>
        <div className='portfolio at-main' style={{marginTop: '100px'}}>
          <div className="career-cell-stuffed slide-in">
            <div className="career-cell-inner Cf">
              <div className="career-href">
                <div className="career-src">
                  <div className="career-src-inner">
                    <img className="career-img" src={stuffed} alt='case related' onLoad={ev => setAspectRatio(ev.currentTarget)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
