import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import CaseHead from '../CaseHead'
import $ from 'jquery'

import og from '../../assets/contact/og.png'

export default class extends Component {
  componentDidMount () {
    document.documentElement.style.overflow = ''
    
    var hintSubMenus = [].slice.call(document.querySelectorAll('.hints-contents-list--submenu ul'))
    var hintSubMenuLinks = [].slice.call(document.querySelectorAll('.hints-contents-list--submenu a'))

    hintSubMenuLinks.forEach(function(hintSubMenuLink){
      hintSubMenuLink.addEventListener('click', function(ev) {
        ev.preventDefault()
        var id = hintSubMenuLink.getAttribute('href')
        var target = document.querySelector(id)

        $([document.documentElement, document.body]).animate({
          scrollTop: $(target).offset().top
        }, 1000)
      })
    })

    hintSubMenus.forEach(function(hintSubMenu) {
      var subMenuArrow = hintSubMenu.parentNode.querySelector('span')

      $(subMenuArrow).click(function() {
        subMenuArrow.classList.toggle('open')
      	$(this).parent(".hints-contents-list--submenu").children("ul").slideToggle("100");
      });
    })
  }

  render () {
    return (
      <div className='case-root'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka's 📝 Hints</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="There is no place like home. Except for the office." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="There is no place like home. Except for the office." />
          <meta property="og:title" content="Contact 📠 Shuka" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <CaseHead leftTitle='Щучьи' rightTitle="Подсказки" menuColor='dark' isTech='true' />
        </div>
        <div className='head-line Cf'>
          <div className="head-line-start icon zero">iii</div>
          <p className="head-line-end mercury">Данный документ – это свод базовых правил и подсказок о рабочих буднях. <br/>Design Bureau Shuka. В разделах описаны существующие должности, соответствующие им обязанности и рекомендации о том, как стоит решать насущные задачи, например: брать отпуск или передавать проект другому дизайнеру. Подсказки адресованы всем сотрудникам. Если вы работаете в Щуке (в штате, удаленно или на полставки), этот документ – для вас.</p>
        </div>
        <div className='events-table Cf'>
          <div className="contacts">
            <div className="Mb(100px)--md Mb(80px)--sm">
              <div className='hints-wrapper'>
                <div className='hints-container'>
                  <div className='hints-block'>
                    <div className='hints-contents-list--wraper'>
                      <div className='mars zero hints-contents-list--headline'>Содержание</div>
                      <ul className='hints-contents-list'>
                        <li className='hints-contents-list--submenu'>
                          <span>→</span>
                          <a className='hints-contents-list--submenu-link' href='#common'>Общее</a>
                          <ul>
                            <li><a className='hints-contents-list--submenu-item' href='#work-hours'>Режим работы</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#approaches-to-work'>Подходы к работе</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#doing-and-done'>Делать и сделать</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#calendar'>Календарь</a></li>
                          </ul>
                        </li>
                        <li className='hints-contents-list--submenu'>
                          <span>→</span>
                          <a className='hints-contents-list--submenu-link' href='#people'>Люди</a>
                          <ul>
                            <li><a className='hints-contents-list--submenu-item' href='#designer'>Дизайнер</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#senior-designer'>Старший дизайнер</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#art-director'>Арт-директор</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#trainee'>Стажер</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#developer'>Фронтенд разработчик</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#coordinator'>Координатор</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#manager'>Менеджер по работе с клиентами</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#development-director'>Директор по развитию</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#velichko'>Иван Величко</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#vasin'>Иван Васин</a></li>
                          </ul>
                        </li>
                        <li className='hints-contents-list--submenu'>
                          <span>→</span>
                          <a className='hints-contents-list--submenu-link' href='#project'>Проект</a>
                          <ul>
                            <li><a className='hints-contents-list--submenu-item' href='#projects-life-cycle'>Жизненный цикл проекта</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#projects-begin'>Начало проекта</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#projects-deadline'>Сроки и рентабельность</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#how-to-deliver-project'>Что значит сдать проект</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#projects-time'>Время</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#projects-responsibilty'>Ответственность</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#projects-upsale'>Как сделать апсейл</a></li>
                          </ul>
                        </li>
                        <li className='hints-contents-list--submenu'><a className='hints-contents-list--submenu-link' href='#first-work-week'>Начало работы в Щуке</a></li>
                        <li className='hints-contents-list--submenu'>
                          <span>→</span>
                          <a className='hints-contents-list--submenu-link' href='#vocations-days-off'>Отпуск</a>
                          <ul>
                            <li><a className='hints-contents-list--submenu-item' href='#calendar'>Календарь</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#vocation'>Отпуск</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#before-vocation'>Перед отпуском</a></li>
                            <li><a className='hints-contents-list--submenu-item' href='#sick-leave'>Больничный</a></li>
                          </ul>
                        </li>
                        <li className='hints-contents-list--submenu'><a className='hints-contents-list--submenu-link' href='#links'>Ссылки</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className='hints-block'>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='common'>Общее</div>
                      <div>
                        <p className='pluto'>Должности и бонусы в Щуке распределяются вне зависимости от гендерной, религиозной, национальной принадлежности и убеждений сотрудников.</p>
                        <br/>
                        <p className='pluto'>Каждый сотрудник может обратиться с насущным вопросом к любому из своих коллег.</p>
                        <br/>
                        <p className='pluto'>В щучьем коллективе принято уважать свое и чужое время, работу и просто людей вокруг:</p>
                        <br/>
                        <p className='pluto'>– Если у вас намечается встреча или созвон по проекту, предупредите всех участников заранее, чтобы у них было время подготовиться, если это необходимо, и больше шансов не опоздать.</p>
                        <p className='pluto'>– Если ваша встреча с клиентом проходит на первом этаже Щуки, предупредите всех заранее (за час и больше), отправив сообщение в канал Slack #general.</p>
                        <p className='pluto'>– Если вы в чем-то не уверены, задайте вопрос. Потраченные на формулирование и произнесение вслух нервы стоят результата. Один вопрос может сэкономить несколько часов вашей работы. </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='work-hours'>Режим работы</div>
                      <div>
                        <p className='pluto'>Рабочий день начинается в 11:00. Официальный конец рабочего дня – 20:00. Если сотрудник выполнил все задачи на день, обсудил насущные вопросы и закончил раньше 20:00, никто не мешает ему уйти. Единственное, о чем стоит при этом помнить: каждый несет ответственность за свою работу и принятые решения, а у каждой задачи есть срок, в который ее нужно выполнить, и объем отведенного времени (квота).</p>
                        <br/>
                        <p className='pluto'>Каждый сотрудник имеет право оставаться на работе после окончания рабочего дня столько, сколько ему хочется, а также приходить на выходных (не забывайте закрывать окна и двери и сдавать ключи на охрану).</p>
                        <br/>
                        <p className='pluto'>Каждый рабочий день заканчивается подведением итога за день и формированием документа по проекту (или проектам), способного презентовать результат достаточно ясно, чтобы он был понят и оценен.</p>
                        <br/>
                        <p className='pluto'>Автор должен убедиться в том, что представленный им результат доступен, то есть:
                          <br/>— скачивается и открывается
                          <br/>— является ответом на поставленную задачу
                          <br/>— легок для просмотра и восприятия
                        </p>
                        <p className='pluto'>Ссылку на документ можно прислать в канал обсуждения проекта в Slack.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='approaches-to-work'>Подходы к работе</div>
                      <div>
                        <p className='pluto zero'>Потоковая разработка</p>
                        <p className='pluto'>Суть: весь проект делится на части, каждой занимается отдельная команда или человек, по ходу проекта результаты отдельных задач сводятся в один. Самый частый пример – создание сайта масштабного продукта (биржи, банка, сервисов с искусственным интеллектом).</p>
                        <p className='pluto'>Часто встречается на крупных проектах или в разных проектах для одного клиента по одному брендбуку, проектах, состоящих из множества самостоятельных задач, и в вебе.</p>
                        <br/><br/>
                        <p className='pluto zero'>Прогрессивный джипег</p>
                        <p className='pluto'>Метод прогрессивного джипега в 2010 году описал Артемий Лебедев.</p>
                        <p className='pluto'>Но мы придумали и внедрили его еще раньше.</p>
                        <p className='pluto'>Суть сводится к тому, что в каждую минуту рабочего времени уже существует результат. Степень детализации и объем этого результата могут не быть большими, но они должны отвечать тем или иным образом на главные вопросы проекта или его этапа.</p>
                        <p className='pluto'>При сдаче важнее прочего аккуратность и сроки (на других этапах тоже, но не так заметно), в процессе – ритм, а в концепции – широта. Исходя из специфики каждого этапа можно регулировать работу и не тратить силы впустую.</p>
                        <p className='pluto'>Пример: клиент не оценит высокую проработку презентации концепций, но точно пожалуется на недостаточное количество вариантов, если вы обещали 4, а сделали 2. Концентрируйтесь на важном, определяя самое главное в текущих задачах.</p>
                        <br/><br/>
                        <p className='pluto zero'>Хаос</p>
                        <p className='pluto'>Самый популярный и неуместно используемый способ организации рабочего процесса. Считается, что он идеально подходит для творческих коллективов. Это действительно так, но с оговоркой: он подходит на этапе креатива, генерации идей, когда нужно как можно больше вариантов и неважно, что половину из них невозможно реализовать.</p>
                        <p className='pluto'>В процессе работы он вреден. В постоянном хаосе в тысячу раз сложнее организовать сдачу проекта, всё помнить и сохранять душевное равновесие. Он приводит к бесконечной череде задач «в работе» и редко — к результату. Если с вами такое случалось, стоит прочитать главу про «делать» и «сделать». Это про то же самое, но иными словами.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='doing-and-done'>Делать и сделать</div>
                      <div>
                        <p className='pluto'>(адаптированный материал Николая Товеровского)</p>
                        <p className='pluto'>Если присмотреться, очень многие люди не понимают разницы в значении этих слов.</p>
                        <p className='pluto'>Основная ошибка в том, что «сделать» и «делать» — совершенно разные вещи.</p>
                        <br/>
                        <p className='pluto'>Ключевые слова для «делать»: процесс, анализ, расчёт, система, план, алгоритм, монотонность, стабильность.</p>
                        <br/>
                        <p className='pluto'>Ключевые слова для «сделать»: результат, продукт, упорство, преодоление, подвиг.</p>
                        <br/><br/>
                        <p className='pluto zero'>Сделать</p>
                        <p className='pluto'>«Сделать» — значит получить результат. К заданному сроку и с заданным качеством. Есть нужный результат — сделано, нет результата — не сделано. Всё остальное не имеет значения. Совсем. Даже если получить результат помешала комета, взорвавшая Землю, задание всё равно будет не сделано. Причина не имеет значения. Сделано / не сделано и точка.</p>
                        <p className='pluto'>Кроме этого, сделать означает сдать.</p>
                        <p className='pluto'>Сделать за 5 дней значит на пятый день получить от заказчика подтверждение, что всё ок. Для этого лучше начинать сдачу заранее.</p>
                        <p className='pluto'>Ошибка в том, что исполнителю кажется, что если задача на неделю, значит он должен показать макет в следующий понедельник. То есть, он думает, что у него есть 5 дней и выходные про запас. Конечно, когда команда проекта видит его/ее макет только в понедельник, задача фейлится. Поэтому стоит идти шажками и придерживаться третьей схемы на рисунке ниже:</p>
                        <div className='Pos(r) D(b) W(100%)'>
                          <img className='Pos(r) D(b) W(50%) Maw(400px)' src='' />
                        </div>
                        <br/><br/>
                        <p className='pluto zero'>Делать</p>
                        <p className='pluto'>Существуют штуки, которые в принципе нельзя сделать. Можно сделать «сходить на тренировку», но нельзя сделать «держать себя в форме». Как только перестанете ходить на тренировки, потеряете форму. Можно сделать «сдать TOEFL», но нельзя сделать «выучить английский». Изучение языка бесконечный процесс.</p>
                        <p className='pluto'>Во-вторых, и это главное, чтобы сделать что-то, в любом случае придётся делать. Делал-делал и сделал. Если ничего не делать, то ничего и не сделаешь.</p>
                        <p className='pluto'><i>Результату предшествует процесс.</i></p>
                        <p className='pluto'>Если уметь что-то одно, например, только «делать» или только «сделать», возникает дисбаланс.</p>
                        <p className='pluto'>Если уметь только «делать», получается болото. Результата нет, проекты разрабатываются годами, команда тратит своё время жизни впустую, ничего не производя на свет. Так работали предприятия в позднебрежневскую эпоху.</p>
                        <p className='pluto'>Если уметь только «сделать», получается великолепный результат, но внутри команда умирает. Стабильно великолепный результат получить проблематично. Здоровье и нервы тратятся бесконтрольно. Люди ненавидят друг друга, ссорятся, уходят. Так Джобс сделал первый Макинтош.</p>
                        <p className='pluto'>Чтобы выпускать хорошие продукты важно уметь и то, и то — и «делать», и «сделать».</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='calendar'>Календарь</div>
                      <div>
                        <p className='pluto'>В общем гугл-календаре Shuka Design отмечены отпуска, встречи с клиентами и проектные обсуждения. Если вы хотите добавить в него событие, создайте его со своего аккаунта и поставьте <b>hello@shuka.ru</b> в качестве гостя с возможностью изменять состав участников и детали мероприятия.</p>
                        <br/><br/>
                        <p className='pluto'>Как подключиться к Google Calendar "Shuka Design":</p>
                        <p className='pluto'>Настройки – Подписаться на календарь – поиск по почте <b>hello@shuka.ru</b>. Доступно всем с почтой в нашем домене. </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='people'>Люди</div>
                      <div>
                        <p className='pluto'>В Щуке работают дизайнеры, арт-директора, креативные директора, а также верстальщики, бухгалтеры, менеджеры и юристы. У каждого из них есть свое поле деятельности и обязанности.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='designer'>Дизайнер</div>
                      <div>
                        <p className='pluto'>Святая пчелка.</p>
                        <p className='pluto'>Дизайнер — креативная роль. Он ищет пластические и графические решения, которые раскрывают идею. Дизайнер получает задания от арт-директора или старшего дизайнера, а также сам ставит себе цели и задачи по проекту. Рисует, пишет, снимает на камеру.</p>
                        <br/><br/>
                        <p className='pluto zero'>Коммуникация</p>
                        <br/>
                        <p className='pluto'>Даже на проекте с одним дизайнером в процессе участвует, не считая его самого, не менее четырех человек:
                          <br/>– sales (человек, заключивший сделку с клиентом), он же часто является креативный директором,
                          <br/>– арт-директор,
                          <br/>– креативный директор,
                          <br/>– менеджер по работе с клиентами, отвечающий за финансы, документацию,
                          <br/>– координатор проектов.
                        </p>
                        <br/>
                        <p className='pluto'>Действие каждого плотно завязано на действия коллег, а проект в целом зависит от информированности каждого участника о происходящем. Поэтому результат работ нужно высылать в общий канал по проекту, а не личные сообщения.</p>
                        <br/><br/>
                        <p className='pluto zero'>Концепции</p>
                        <br/>
                        <p className='pluto'>У каждого этапа проекта есть своя специфика. Учитывая её, можно не тратить силы впустую. Помните, что при сдаче проекта важны аккуратность и сроки (на других этапах тоже, но не так заметно), в процессе – ритм, а в концепции – широта.</p>
                        <p className='pluto'>Пример: клиент не оценит высокую проработку концепций, но точно пожалуется на недостаточное количество вариантов, если вы обещали 4, а сделали 2. Концентрируйтесь на важном, определяя самое главное в текущих задачах.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='senior-designer'>Старший дизайнер</div>
                      <div>
                        <p className='pluto'>Имеет несколько бо́льшую свободу, опыт и, как следствие, бо́льшую ответственность, чем дизайнер.</p><br/>
                        <p className='pluto'>Ключевое отличие старшего дизайнера от дизайнера в том, что он может сам представлять работу клиенту. То есть он обладает не только высокими художественными навыками, но и может вести грамотную коммуникацию с клиентом, достаточно эффективную, чтобы продвигать проект к завершению.</p><br/>
                        <p className='pluto'>В некоторых случаях старший дизайнер может работать без арт-директора, но на больших проектах арт-директор принимает работу у старшего дизайнера и представляет ее клиенту.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='art-director'>Арт-директор</div>
                      <div>
                        <p className='pluto'>Арт-директор является представителем Щуки для заказчика, транслирует не только собственное экспертное мнение по вопросам дизайна, но и мнение всей компании. Он (или она) выдерживает курс проекта и помнит про вводные, комментарии, бюджет и портфолийность. Арт-директор априори имеет больше опыта, чем дизайнер или старший дизайнер. И в глазах клиента пользуется бо́льшим авторитетом. </p><br/>
                        <p className='pluto'>Арт-директор работает с идеей и формой ее воплощения, учитывает при этом бизнес-цели клиента и его планы на будущее. </p><br/>
                        <p className='pluto'>Он совершенно спокойно рисует макеты «руками», но как правило, реже дизайнера.</p><br/>
                        <p className='pluto'>Что делает арт-директор:
                          <br/>– участвует в брифинге (от клиента или креативного директора);
                          <br/>– оценивает сроки входящих проектов и их стоимость в часах;
                          <br/>– создает концепции под актуальные цели клиентов (при необходимости объясняет, почему поставленные цели не актуальны и помогает клиенту найти новые);
                          <br/>– проводит презентации концепций, промежуточных и финальных результатов работ;
                          <br/>– делится своим опытом с дизайнерами;
                          <br/>– при необходимости регулирует загрузку дизайнеров на проекте (если чья-то нагрузка близка к максимальной, можно обсудить это с менеджером и выделить еще одного человека или большее количество часов на задачи);
                          <br/>– дает отмашку на закрытие проекта менеджеру по работе с клиентами.
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='trainee'>Стажер</div>
                      <div>
                        <p className='pluto'>Участвует в рабочем процессе наравне со всеми. Во время стажировки он имеет те же права, что и любой другой сотрудник, и обязанности той должности, на которую он претендует.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='developer'>Фронтенд разработчик</div>
                      <div>
                        <p className='pluto'>
                          <br/>– Верстает новые кейсы на щучий сайт в соответсвии с макетом кейса от дизайн-команды и имеющейся у сайта сетки.
                          <br/>– Обновляет информацию о Событиях, Публикациях, Рассылках бюро на сайте.
                          <br/>– Настраивает шеринг-информацию для каждой страницы сайта (мета данные).
                          <br/>– Следит за исправностью админки.
                          <br/>– Оптимизирует сайт в целом.
                        </p>
                        <br/>
                        <p className='pluto'>Фронтендер не является верстальщиком, так как отвечает за больший спектр функционирования сайта, его интерактивность. Проблемы с рассылкой он не решает, за этим стоит обращаться к Жилкину.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='coordinator'>Координатор</div>
                      <div>
                        <p className='pluto'>Координатор проектов отвечает за:
                          <br/>– поддержание оптимальной загрузки дизайнеров и арт-директоров;
                          <br/>– создание сетки загрузки, чтобы все участники процесса были в курсе происходящего;
                          <br/>– общение с клиентами по организационным вопросам (согласование встреч, отправка материалов и т.п.);
                          <br/>– тестирование и настройка инструментов управления процессом.
                        </p><br/>
                        <p className='pluto'>Благодаря этому человеку бриф собран до начала работ, с ним ознакомлены все участники проекта, работа начинается после внесения аванса, а на крупных проектах есть связующий между дизайн-командой и клиентом, способный говорить на языках обеих сторон.</p><br/>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='manager'>Менеджер по работе с клиентами</div>
                      <div>
                        <p className='pluto'>Отвечает за получение финансов, документооборот (обмен документами с клиентами и бухгалтерией), закупки.</p><br/>
                        <p className='pluto'>В обязанности по части документооборота входит:
                          <br/>– составление договора;
                          <br/>– проверка присланных и отправляемых документов;
                          <br/>– согласование условий договора;
                          <br/>– отправка и получение сканов и оригиналов документов по проекту и закупкам;
                          <br/>– контроль графика выплат по проекту (напоминает клиенту, что он задолжал, следит, чтобы деньги все-таки пришли);
                          <br/>– обеспечение выплат от должников (запугивание, физическое и психологическое насилие запрещены).
                        </p><br/>
                        <p className='pluto'>Менеджер может подсказать насчет цены следующего проекта или этапа работ, опираясь на финансовый план Щуки.</p><br/>
                        <p className='pluto'>Кроме этого, если:
                          <br/>– в принтере закончились чернила;
                          <br/>– у нас мало или уже закончились: вода, туалетная бумага, салфетки, бумажные полотенца, мешки для мусора;
                          <br/>– нет нужной канцелярки;
                          <br/>– нужен заказ и/или доставка чего-либо;
                          <br/>– нужно составить, отправить, получить или подписать какой-то документ,
                          <br/>– можно и нужно написать по этим вопросам менеджеру.
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='development-director'>Директор по развитию</div>
                      <div>
                        <p className='pluto'>New business director занимается развитием Щуки в направлениях, определенных на основе анализа актуального рабочего процесса и представлений о будущем компании.</p><br/>
                        <p className='pluto'>Отвечает за корректировку, достижение и средства достижения целей, поставленных в рамках направления развития. Кроме этого, участвует в планировании загрузки компании по проектам, опираясь на финансовый годовой план, оформляет проекты на конкурсы и занимается прочими активностями, способствующими увеличению узнаваемости, уровня доверия и популярности компании на рынке.</p><br/>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='velichko'>Иван Величко</div>
                      <div>
                        <p className='pluto'>Креативный директор, партнер и brand keeper Shuka Design, арт-директор щучьего сайта.</p><br/>
                        <p className='pluto'>Что делает:
                          <br/>– интерпретирует коммуникационную стратегию клиента и помогает дизайнерам в построении творческих концепций;
                          <br/>– отвечает за качество реализации творческой идеи, ее этическую и смысловую нагрузку;
                          <br/>– отслеживает эффективность реализации проекта;
                          <br/>– проводит встречи с клиентами с целью выяснения представлений клиента о заказе на первых этапах работы;
                          <br/>– инициирует, поддерживает и развивает творческие идеи всех вовлеченных в творческий процесс;
                          <br/>– отсматривает, комментирует и ревизирует кейсы выполненных проектов перед публикацией.
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='vasin'>Иван Васин</div>
                      <div>
                        <p className='pluto'>Коммерческий и креативный директор. Занимается развитием и корректировкой образа Shuka Design в социальных сетях.</p><br/>
                        <p className='pluto'>Что делает:
                          <br/>– занимается разработкой и реализацией ценовой политики Щуки;
                          <br/>– осуществляет анализ и оценку финансовых рисков, разрабатывает меры по сведению их к минимуму;
                          <br/>– занимается финансовым планированием, разрабатывает и осуществляет меры по обеспечению финансовой устойчивости компании;
                          <br/>– контролирует экономические показатели деятельности Щуки, расходование финансовых средств;
                          <br/>– следит за соблюдением высокого уровня качества работ и соответствием проектов требованиям и потребностям заказчика;
                          <br/>– интерпретирует коммуникационную стратегию клиента и помогает дизайнерам в построении творческих концепций;
                          <br/>– ведёт некоторые проекты в качестве аккаунта.
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='project'>Проект</div>
                      <div>
                        <p className='pluto'>В этом разделе собраны полезные заметки о том, из чего состоит, чем заканчивается, к чему ведет проект и что важно для его успешного выполнения.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='projects-life-cycle'>Жизненный цикл проекта</div>
                      <div>
                        <p className='pluto'>У каждого проекта, который находится в работе, есть этапы. Сменяя друг друга, они образуют рабочий процесс. Финальные этапы одного проекта приводят к появлению новых клиентов, а значит и новых проектов. Если какой-то проект не доходит до финального этапа или пропускает несколько, он ни к чему не ведет, не попадает в портфолио и, как следствие, не привлекает новых заказчиков.</p>
                        <br/>
                        <p className='pluto'>Из чего состоит жизненный цикл проекта в Щуке:</p>
                        <br/>
                        <p className='pluto zero'>1. Входящий</p>
                        <p className='pluto'>Запрос от клиента на сотрудничество. </p>
                        <br/>
                        <p className='pluto zero'>2. Оценка сроков </p>
                        <p className='pluto'>Считаем время, которое нужно для выполнения задачи, примерные календарные сроки старта и окончания работ.</p>
                        <br/>
                        <p className='pluto zero'>3. Переговоры</p>
                        <p className='pluto'>Торг о стоимости, составе работ, сроках проекта. Утверждение сметы и технических требований. </p>
                        <br/>
                        <p className='pluto zero'>4.1 Внесение в план работ</p>
                        <p className='pluto'>Добавление примерного срока выполнения работ в календарь загрузки.</p>
                        <br/>
                        <p className='pluto zero'>4.2 Документы</p>
                        <p className='pluto'>Согласование, подписание обеими сторонами, внесение аванса клиентом.</p>
                        <br/>
                        <p className='pluto zero'>5. Концепция</p>
                        <p className='pluto'>Создание, корректировка, презентация клиенту. Выбор концепции для дальнейшей проработки.</p>
                        <br/>
                        <p className='pluto zero'>6. Развитие концепции</p>
                        <p className='pluto'>Проработка выбранного варианта, согласование внутри Щуки, подготовка презентации клиенту. Согласование с клиентом.</p>
                        <br/>
                        <p className='pluto zero'>7. Носители</p>
                        <p className='pluto'>Применение созданной концепции на конкретные предметы и средства коммуникации. Согласование с клиентом.</p>
                        <br/>
                        <p className='pluto zero'>8. Результат</p>
                        <p className='pluto'>Передача результата заказчику. Производство или авторский надзор за производством, если они включены в состав работ.</p>
                        <br/>
                        <p className='pluto zero'>9. Подписание закрывающих документов.</p>
                        <br/>
                        <p className='pluto zero'>10. Оплата закрывающего счета, если таковой имеется. </p>
                        <br/>
                        <p className='pluto zero'>11. Анонсы </p>
                        <p className='pluto'>Кейс на сайт, презентация на Behance, пост на Facebook и Instagram.</p>
                        <br/>
                        <p className='pluto zero'>12. Входящий…</p>
                        <div className='Pos(r) D(b) W(100%)'>
                          <img className='Pos(r) D(b) W(50%) Maw(400px)' src='' />
                        </div>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='projects-begin'>Начало проекта</div>
                      <div>
                        <p className='pluto'>Когда в Щуку приходит новый проект, его отдают на оценку объема работы арт-директору. Оценка обсуждается внутри и согласовывается с клиентом, корректируется и ложится в основу договора и сроков проекта. Выбор арт-директора, который будет заниматься проектом, происходит исходя из загрузки, навыков и желания исполнителя.</p>
                        <br/>
                        <p className='pluto'>Постановкой задачи дизайнерам после сбора всех вводных от клиента на старте занимается арт-директор, и в дальнейшем он отвечает за проект. Он же может вести коммуникацию с клиентом или передать ее старшему дизайнеру проекта. Если есть возможность поехать на бриф с клиентом вместе с дизайнером, ее лучше использовать, чтобы избежать путаницы и потери информации.</p>
                        <br/>
                        <p className='pluto'>Если проект был принят в работу через согласование всех условий напрямую с одним из партнеров Щуки, все вводные и материалы передаются арт-директору и дизайнерам, которые будут работать над проектом.</p>
                        <br/>
                        <p className='pluto'>Если на проекте присутствует Asana или другой инструмент ведения проектов, доступ к которому есть и у сотрудников Щуки, и у клиента или исполнителя не на нашей стороне, постановка задачи обязательно фиксируется в нем, чтобы быть доступной в изначальном письменном виде. При наличии инструмента ведения проектов, доступного только сотрудникам Щуки, фиксирование изначальной постановки задачи желательна и полезна, но не обязательна.</p>
                        <br/>
                        <p className='pluto'>Дальнейшая постановка задач на проекте происходит одним из следующих образов:
                          <br/>– клиент сообщается о новой задаче арт-директору;
                          <br/>– клиент сообщает о новой задаче в общую почту или одному из директоров Щуки, затем ее передают арт-директору (нежелательный вариант, подходит для концептуальных вех, но не рутинных дел; стоит сразу условиться с клиентом о порядке взаимодействия на проекте);
                          <br/>– арт-директор предлагает клиенту доработку или свежие идеи для развития проекта.
                        </p>
                        <br/>
                        <p className='pluto'>После появления новой задачи ее необходимо передать исполнителю и убедиться, что у него есть вся необходимая информация. Со своей стороны исполнитель также должен помнить о том, что не все материалы можно получить в тот же день или час, когда они запрошены у клиента, поэтому стоит обсудить это с арт-директором или менеджером в начале работы над задачей и запросить материалы заранее.</p>
                        <br/>
                        <p className='pluto'>После получения задачи от клиента не стоит сразу давать конкретные сроки исполнения или предсказания на этот счет. «Мы поняли суть задачи, нам нужно обсудить ее внутри команды, чтобы дать вам оценку времени и, возможно, задать пару вопросов» – отличная замена желанию сделать всё «к концу следующей недели». <br/>Не называйте срок выполнения задачи в том же разговоре с клиентом.</p>
                        <br/>
                        <p className='pluto'>Фиксирование вводных по проекту или пулу работ внутри большого проекта важно для взаимопонимания и прозрачного взаимодействия с клиентом. Для самой работы над проектом внутри постоянное письменное обновление вводных отнимает много времени и нервов. Поэтому проще обсуждать новости устно, тем более что команда проекта редко насчитывает более 3 человек.</p>
                        <br/>
                        <p className='pluto'>Чтобы у ваших коллег была возможность распределять задачи между дизайнерами и давать новые проекты, полезно фиксировать уже оговоренные задачи в таймли. В сетке хорошо видно, у кого какая загрузка на ближайшие несколько дней.</p>
                        <br/>
                        <p className='pluto zero'>Понимание задачи</p>
                        <br/>
                        <p className='pluto'>Практика подсказывает, что исполнители (дизайнеры, арт-директора, фрилансеры) не всегда понимают, что именно они делают, для чего это клиенту, какую и чью задачу они решают и есть ли она в принципе. С полноценными вводными проще работать и ниже вероятность обнаружить в середине проекта, что всё сделанное не попадает в бриф.</p>
                        <br/>
                        <p className='pluto'>Чтобы предотвратить непонимание внутри команды проекта, нужно разговаривать. Перед началом работы арт-директору стоит проговорить постановку задачи. Еще лучше если это сделает дизайнер, даже если на созвоне/встрече присутствовали и слышали всё, что произнес клиент, оба.</p>
                        <br/>
                        <p className='pluto'>После промежуточной встречи, тоже полезно проговорить дальнейшие шаги и вспомнить, к чему стремится проект, какой результат должен быть. Постоянно держать задачу в фокусе бывает сложно, «классные» идеи часто уводят в сторону. Среди прочих задач арт-директора – держать курс и помнить про вводные, комментарии и портфолийность проекта.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='projects-deadline'>Сроки и рентабельность</div>
                      <div>
                        <p className='pluto'>У каждого проекта есть:
                          <br/>– срок работ (календарное начало и конец; фиксируются в договоре);
                          <br/>– квота (объем часов, который мы можем потратить; считается как «бюджет / ставка * количество людей на проекте»);
                          <br/>– рентабельность (насколько выгоден проект для компании, исходя из количества потраченного на него времени).
                        </p>
                        <br/>
                        <p className='pluto'>От количества потраченного на проект времени зависит выгода, которую мы получаем. Соответственно, выгоднее всего продать проект дорого и сделать качественно, но быстро.</p>
                        <br/>
                        <p className='pluto'>Чтобы контролировать сроки и знать, где есть запас времени, а где придется ускориться, работу по проекту стоит разбить на этапы с промежуточными результатами, которые будут понятны и команде, и клиенту. У каждого этапа будет соответствующее задаче время на исполнение от общего на проект времени и, соответственно, бюджета. Как вести и применять ли подобные этапы, решает арт-директор. В случае разделения на этапы стоит использовать таймли и отмечать в нем время по частям с помощью системы тегов.</p>
                        <br/>
                        <p className='pluto'>Дважды, а лучше трижды, за проект необходимо проверить, насколько темп работ и скорость приближения к результату соответствуют выделенным ресурсам. Об изменении сроков важно предупредить арт-директора и креативного директора, а также человека, который общается с клиентом (если это не креативный директор), затем предупредить клиента и обозначить новый срок, который уже будет некрасиво нарушить.</p>
                        <br/>
                        <p className='pluto'>Если становится ясно, что времени на проект в принципе выделено маловато, стоит обсудить продление сроков внутри Щуки и решить, стоит ли договариваться с клиентом о увеличении бюджета проекта. Затем необходимо предупредить клиента о продлении сроков. Ограничения по времени не должны влиять на качество.</p>
                        <br/>
                        <p className='pluto'>Просить дополнительную плату уместно, когда клиент вносит много изменений или меняет постановку задач на ходу, то есть препятствует спокойной работе команды.</p>
                        <br/>
                        <p className='pluto'>Увеличение сроков и выход за квоту = уменьшение прибыли.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='how-to-deliver-project'>Что значит сдать проект</div>
                      <div>
                        <p className='pluto'>Когда финальные макеты отправлены клиенту, проект не заканчивается.</p>
                        <br/>
                        <p className='pluto zero'>Архив</p>
                        <br/>
                        <p className='pluto'>Проект нужно собрать в понятный архив, который будет лежать в общем доступе и в котором легко ориентироваться по папкам, хотя бы с помощью названий этапов проекта или дат, указанных в названии папки.</p>
                        <br/>
                        <p className='pluto zero'>Анонс (он же кейс)</p>
                        <br/>
                        <p className='pluto'>Работу Щуки во внешнем мире оценивают по материалам и информации в открытом доступе. То есть если проект не анонсирован значит его и не было. Поэтому о своей работе необходимо рассказывать. Вовремя выпущенный анонс привлекает новых клиентов, внимание СМИ и конкурентов заказчика. А еще повышает ценность работ Shuka Design, узнаваемость бюро, его вес на рынке и авторитетность.</p>
                        <br/>
                        <p className='pluto'>После архива выпускаются анонсы проекта на разных интернет-ресурсах:
                          <br/>1. Кейс на Behance
                          <br/>2. Кейс на сайт shuka.design
                          <br/>3. Пост в Facebook
                          <br/>4. Пост в Instagram
                        </p>
                        <br/>
                        <p className='pluto'>Что нужно для кейса:
                          <br/>– собрать картинки;
                          <br/>– сделать видео/гифку. Для facebook не обязательно;
                          <br/>– написать текст, за помощью можно обратиться креативному директору и/или    копирайтеру. Для Behance текст не обязателен;
                          <br/>– утвердить анонс с креативным и/или коммерческим директором;
                          <br/>– опубликовать пост или анонс на сайте.
                        </p>
                        <br/>
                        <p className='pluto zero'>Документы и платежи</p>
                        <br/>
                        <p className='pluto'>Для закрытия проекта клиенту выставляется закрывающий счет и акт-приемки работ. Документальные отношения с клиентом по данному проекту считаются законченными, когда деньги поступили на счет и обе стороны имеют на руках подписанные оригиналы всех необходимых документов. Решение о закрытии проекта принимают Иван Васин, Иван Величко, арт-директора.</p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='projects-time'>Время</div>
                      <div>
                        <p className='pluto'>Время, потраченное на рабочие задачи, вносится в Таймли – https://timelyapp.com/. Этот же инструмент используется, чтобы планировать грядущие задачи заранее, а также отмечать больничный и отпуск.</p>
                        <br/>
                        <p className='pluto'>Обсуждение проекта, эскизы (удачные и неудачные), встречи с клиентом – всё это является работой над проектом и оплачивается клиентом. Поэтому важно отмечать потраченные часы на все активности, связанные с решением поставленной задачи, в таймли, это поможет узнать реальную стоимость проектов и продавать выгоднее.</p>
                        <br/>
                        <p className='pluto'>При этом анализ прошедших проектов помогает увидеть, сколько времени на самом деле нужно, чтобы выполнить ту или иную задачу. Сопоставляя данные, легко отрегулировать обещания про сроки клиенту, работать спокойнее и за бо́льшие деньги.</p>
                        <br/>
                        <p className='pluto'>Третья причина использовать Таймли – возможность быстро и без потерь для других проектов найти замену заболевшему дизайнеру и создать ситуацию, в которой без арт-директора ничего не рухнет, если он заболел или уехал читать лекцию в другой город.</p>
                        <br/>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='projects-responsibilty'>Ответственность</div>
                      <div>
                        <p className='pluto'>Кратко о том, кто за что отвечает на типичном проекте:</p>
                        <br/>
                        <p className='pluto'>Сроки и качество задачи – дизайнер.
                          <br/>Сроки и качество проекта – арт-директор.
                          <br/>Ответственность перед клиентом – арт-директор и команда проекта.
                          <br/>Общение с клиентом по текучке проекта – арт-директор, старший дизайнер.
                          <br/>Общение про оплату и документы проекта  – менеджер по работе с клиентами.
                        </p>
                        <br/>
                        <p className='pluto'>Разрешение спорных вопросов и конфликтов с клиентом  – человек, который передал входящий арт-директору (это может быть один из партнеров Щуки или сам арт-директор).</p>
                        <br/>
                        <p className='pluto'>Сетка проектов и загрузка сотрудников, соблюдение вектора развития, внутренние проекты – директор по развитию.
                          <br/>Финансы, входящие заказы и креатив – Иван Васин.
                          <br/>Сайт, кейсы и креатив – Иван Величко.
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='projects-upsale'>Как сделать апсейл</div>
                      <div>
                        <p className='pluto'>Делиться с клиентом идеями о развитии проекта полезно. Если к айдентике хорошо бы сделать оформление нескольких мероприятий или мёрч, о котором клиент даже не заикался, обсудите идеи внутри Щуки, предложите идеи клиенту. Многие из них не будут приняты, это нормально. Но даже в случае отказа клиент будет знать, что вы готовы развивать проект.</p>
                        <br/>
                        <p className='pluto'>Все идеи, не входящие в список в договоре или не оговоренные до начала работ, оплачиваются отдельно!</p>
                        <br/>
                        <p className='pluto'>Если клиент соглашается на доработку, на всякий случай, уточните, что это будет стоить ему некоторых денег. Конкретную сумму можно вычислить двумя способами:
                          <br/>1. посчитать количество часов на работу и умножить на среднюю ставку;
                          <br/>2. уточнить у одного из директоров.
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='first-work-week'>Начало работы в Щуке</div>
                      <div>
                        <p className='pluto'>Что должно появиться у человека в самом начале работы в Щуке: </p>
                        <br/>
                        <p className='pluto'>1. Рабочее место
                          <br/>Компьютер, мышка, клавиатура, стол, стул, канцелярка.
                        </p>
                        <br/>
                        <p className='pluto'>2. Документы для оформления на работу
                          <br/>От сотрудника потребуются: паспорт, трудовая книжка; СНИЛС и ИНН, если они есть.
                          <br/>От Щуки: должность, документы, запись в трудовую книжку.
                        </p>
                        <br/>
                        <p className='pluto'>3. Ссылка на щучьи подсказки, чтобы ознакомиться с основными моментами работы.</p>
                        <br/>
                        <p className='pluto'>4. Ключ от калитки.</p>
                        <br/>
                        <p className='pluto'>5. Аккаунты в Slack, Timely, Dropbox; программы Adobe и служебная почта.</p>
                        <br/>
                        <p className='pluto'>6. Экскурсия по домикам и рассказ про быт: где кухня, что делать с грязной посудой, где взять чай, т/бумагу или салфетки, если закончились и пр.</p>
                        <br/>
                        <p className='pluto'>Пример подписи в почте:</p>
                        <br/>
                        <p className='pluto zero'>--</p>
                        <p className='pluto zero'>name surname | your position</p>
                        <p className='pluto zero'>shuka.design | +7 000 00-00-00</p>
                        <br/>
                        <p className='pluto'>Не забудьте поставить ссылку на shuka.design, с нее человек из письма сможет перейти на щучий сайт. Номер мобильного телефона необязательно ставить, если вы дизайнер/арт-директор.</p>
                        <p className='pluto'></p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='vocations-days-off'>Отпуск</div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='calendar'>Календарь</div>
                      <div>
                        <p className='pluto'>Все отпуска и отгулы зафиксированы в Google Calendar «Shuka Design».</p>
                        <br/>
                        <p className='pluto'>Если у вас еще нет доступа, вам нужно:
                          <br/>– зайти в календарь со своей щучьей почты;
                          <br/>– выбрать в левом меню «другие календари»;
                          <br/>– нажать плюс и ввести hello@shuka.design;
                          <br/>– подписаться на календарь «Shuka Design».
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='vocation'>Отпуск</div>
                      <div>
                        <p className='pluto'>По протяженности</p>
                        <br/>
                        <p className='pluto'>1) 3 дня и больше - оформляется как стандартный отпуск, предупреждать о нем необходимо не менее чем за 2 недели до начала. Изменить даты можно также не позднее двух недель до начала отпуска, чтобы успеть передать информацию в бухгалтерию и вовремя перевести отпускные.</p>
                        <p className='pluto'>2) дей офф на 2 дня - если идет рядом с выходными, выходные не считаются отпускными днями. Можно брать не более трех раз в год. Больше 3 раз брать можно, но такие дэй оффы будут захватывать выходные и оформляться отпуском на 4 календарных дня. Предупреждать нужно не позже чем за неделю, переносить тоже. Дни копятся до 7 и потом закрываются единым отпуском.</p>
                        <p className='pluto'>3) дэй офф на 1 день бывает двух видов:</p>
                        <p className='pluto'>а) в счет отпуска – предупреждать о нем нужно, минимум, за неделю. В эту категорию попадают дэй оффы на 1 день заранее, дэй оффы как компенсация за переработки и отгулы в срочном порядке. “В срочном порядке” значит, что предупреждаешь меньше, чем за неделю, потому что есть не просто желание, а явная необходимость - переутомление или срочных поход к зубному. Срочные отгулы не отменяются. Дни копятся до 7 дней и потом закрываются единым отпуском. </p>
                        <p className='pluto'>б) не в счет отпуска – вариант urgent, меньше чем за неделю. Причина должна быть весомая, дэй офф согласовывается с тем, кто согласовывает отпуска, в индивидуальном порядке. В этот день человека стараются не дергать постоянно, но при этом надо быть на связи по телефону (т.е. брать трубку, если тебе звонят). Соответственно, если кто-то из коллег отсутствует на работе по причине urgent, остальные оповещаются и если что-то срочно нужно уточнить или обсудить, не стоит писать в слек/телеграм/куда-то еще, надо звонить.</p>
                        <p className='pluto'>Не оформляется как отпуск и по сути им не является.</p>
                        <br/>
                        <p className='pluto'>В месяц, когда оформляется “закрывающий отпуск” за все дэй оффы, будут начислены отпускные и сокращена зп. У бухгалтерии можно попросить расчет выплат на этот период, если нужны конкретные суммы (запрос отправлять через Машу О).</p>
                        <br/>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='before-vocation'>Перед отпуском</div>
                      <div>
                        <p className='pluto zero'>Арт-директор</p>
                        <br/>
                        <p className='pluto'>– сверить обещанное и сделанное, отдельно выписать задачи, которые выпали из зоны внимания;
                          <br/>– распределить задачи по людям;
                          <br/>– собраться с дизайнерами по каждому проекту и проговорить ближайшие шаги, выяснить, все ли понимают, что происходит и куда нужно прийти за время отпуска;
                          <br/>– закрыть срочные дела, которые без вас не могут быть закончены в срок.
                        </p>
                        <br/>
                        <p className='pluto'>У дизайнеров остаются актуальными чек-дни по проекту, это повод проверить, где они в рамках проекта, всё ли сделано. Если вы не уверены, что все знают о важных датах проекта, которые попадают на ваш отпуск, продублируйте их в канал проекта в Slack.</p>
                        <br/>
                        <p className='pluto zero'>Дизайнер и старший дизайнер</p>
                        <br/>
                        <p className='pluto'>– сверить обещанное и сделанное, отдельно выписать задачи, которые мы обсуждали, но по каким-либо причинам отложили на будущее, клиент в любое время вашего отсутствия может про них вспомнить;
                          <br/>– составить письмо или презентацию для передачи проектов на время отпуска;
                          <br/>– предупредить клиента, в каких числах вы будете отсутствовать;
                          <br/>– закрыть срочные дела, которые без вас не могут быть закончены в срок.
                        </p>
                        <br/>
                        <p className='pluto'>Что полезно указать в письме для передачи проектов:
                          <br/>– где лежат актуальные файлы (не папка проекта, а то, что клиент видел последним и над чем ведется работа);
                          <br/>– что сделано, что на согласовании, что предстоит сделать вкратце;
                          <br/>– письмо клиенту о том, что вы уходите в отпуск (с какого по какое число, к кому обращаться в ваше отсутствие - имя и контакт). Если вы не общаетесь напрямую с клиентом, это сделает арт-директор или менеджер.
                        </p>
                        <br/>
                        <p className='pluto zero'>Верстальщик</p>
                        <br/>
                        <p className='pluto'>Так как верстальщик в Щуке один, все задачи по верстке на время отпуска приостанавливаются. Поэтому самое важное – закрыть до отпуска те задачи, которые не могут быть закрыты без верстки или просто в другие даты.</p>
                        <br/>
                        <p className='pluto zero'>Менеджер по работе с клиентами</p>
                        <br/>
                        <p className='pluto'>На время отпуска менеджера Щука не может выставлять договоры, счета и акты. Документы могут только подписаны или высланы. Поэтому самое важное – закрыть до отпуска те задачи, которые не могут быть закрыты позднее. От подписания и согласования договора зависит старт работ.</p>
                        <br/>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='sick-leave'>Больничный</div>
                      <div>
                        <p className='pluto'>Если сотрудник заболел:
                          <br/>– нужно предупредить своих коллег и уточнить, сможете ли вы быть на связи и по каким каналам;
                          <br/>– для истории стоит отметить в таймли дни больничного, проект «отпуска и отгулы».
                          <br/>Это поможет избежать непонимания в дальнейшем.
                        </p>
                        <br/>
                        <p className='pluto zero'>Арт-директор</p>
                        <br/>
                        <p className='pluto'>Если нет возможности отвечать в течение дня, лучше раздать задачи и назначить конкретные сроки и ответственных. И напомнить, что за помощью дизайнер всегда может подойти не только к друзьям, но и любому арт-директору или креативному, каждый с радостью поможет.</p>
                        <br/>
                        <p className='pluto'>Если возможность отвечать в течение дня есть, постарайтесь разбить задачи на более мелкие, результат которых можно отсматривать каждый вечер или с другой удобной периодичностью. Не забудьте проставить больничный в таймли.</p>
                        <br/>
                        <p className='pluto zero'>Дизайнеры, менеджер, верстальщик</p>
                        <br/>
                        <p className='pluto'>Если нет возможности отвечать в течение дня, обязательно предупредите, на сколько дней вы точно выпадаете из рабочего процесса. Спокойно болейте и быстрее выздоравливайте. Не забудьте проставить больничный в таймли, если вы в нем зарегистрированы.</p>
                        <br/>
                        <p className='pluto zero'>Директор</p>
                        <br/>
                        <p className='pluto'>Если нет возможности отвечать в течение дня, обязательно предупредите, на сколько дней вы точно выпадаете из рабочего процесса. Спокойно болейте и быстрее выздоравливайте. Срочные задачи лучше передать другим исполнителям: менеджеру или второму директору. Встречи можно так же распределить между сотрудниками или перенести, в зависимости от ее цели.</p>
                        <br/>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='links'>Ссылки</div>
                      <div>
                        <p className='pluto zero'>
                          <a target='_blank' href='https://www.dropbox.com/s/cpjkbb0qm9khm4j/shuka_brand_bureau_n.pdf?dl=0' className="C(#000)"><b className="underline"></b>Щучья презентация (октябрь 2018)</a>
                          <br/><br/>
                          <a target='_blank' href='https://www.artlebedev.ru/kovodstvo/sections/167/' className="C(#000)"><b className="underline"></b>Метод прогрессивного джипега в 2010 году описал Артемий Лебедев.</a>
                          <br/><br/>
                          <a target='_blank' href='https://ksoftware.livejournal.com/397808.html' className="C(#000)"><b className="underline"></b>Оригинальный пост Товеровского о разнице между «делать» и «сделать».</a>
                        </p>
                        <br/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
