import React, { Component } from 'react'
import Link from '../helpers/Link.js'
import Logo from './Logo'

export default class extends Component {
  render () {
    return (
      <nav className={`nav-touch on-touch mercury ${this.props.color}`} data-elem="nav" style={{backgroundColor: `${this.props.navBg}`}}>
        <h1 className="semantic">Main navigation</h1>
        <div className="nav-touch-body">
          <div className="nav-touch-scroll">
            <ul className="nav-touch-ul">
              <li className="nav-touch-i">
                <Link to="/" className="head-logo nav-touch-a Mb(0)--md mars is-crosslink">
                  <span className="head-logo-icon on-touch">
                    <Logo />
                  </span>
                </Link>
              </li>
              <li className="nav-touch-i">
                <Link to="/work" className="nav-touch-a caps">
                  <b className="underline"></b>Work
                </Link>
              </li>
              <li className="nav-touch-i">
                <Link to="/about" className="nav-touch-a caps">
                  <b className="underline"></b>About
                </Link>
              </li>
              <li className="nav-touch-i">
                <Link to="/contact" className="nav-touch-a caps">
                  <b className="underline"></b>Contact
                </Link>
              </li>
              <li className="nav-touch-i">
                <Link to="/publications" className="nav-touch-a caps">
                  <b className="underline"></b>Publications
                </Link>
              </li>
              <li className="nav-touch-i">
                <Link to="/newsletters" className="nav-touch-a caps">
                  <b className="underline"></b>Newsletter
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
