import React, { Component } from 'react'
import * as css from 'classnames'

import Logo from './Logo'
import Link from '../helpers/Link.js'

export default class extends Component {
  render () {
    const { rightTitle, leftTitle, isTech } = this.props

    return (
      <div className="case-head">
        <div className="case-head--body">
          <div className="case-head--body-inner">
            { this.props.isNextCase
              ? <span className={`case-head--logo ${this.props.menuColor} mars`}>
                  <span className="case-head--logo-icon on-touch"><Logo /></span>
                  <div className="case-head--logo-text"><span>shuka</span><span className='umlaut'>shüka</span></div>
                </span>
              : <Link to="/" className={`case-head--logo ${this.props.menuColor} mars`}>
                  <span className="case-head--logo-icon on-touch"><Logo /></span>
                  <div className="case-head--logo-text"><span>shuka</span><span className='umlaut'>shüka</span></div>
                </Link>
            }
          </div>
          <h2 className={css('case-head--lead', 'case-head--lead--half', 'mars', 'zero', `${this.props.menuColor}`, {'wide-margin': !this.props.isTech, 'margin-vertical--no-bottom': isTech})} dangerouslySetInnerHTML={{ __html: leftTitle }}></h2>
          <h2 className={css('case-head--lead', 'mars', 'zero', `${this.props.menuColor}`, {'case-head--lead-right': !this.props.isTech})} dangerouslySetInnerHTML={{ __html: rightTitle }}></h2>
        </div>
      </div>
    )
  }
}
