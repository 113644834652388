import React from 'react'

export default () => (
  <div className='portfolio at-main'>
    <div className="portfolio-cell slide-in" data-parallax-item >
      <div className="portfolio-cell-inner">
        <span className="portfolio-title is-preloader"></span>
        <div className="portfolio-href portfolio-href--image">
          <div className="portfolio-src portfolio-src--bg">
            <div className="portfolio-src-inner is-preloader">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="portfolio-cell slide-in" data-parallax-item >
      <div className="portfolio-cell-inner">
        <span className="portfolio-title is-preloader"></span>
        <div className="portfolio-href portfolio-href--image">
          <div className="portfolio-src portfolio-src--bg">
            <div className="portfolio-src-inner is-preloader">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="portfolio-cell slide-in" data-parallax-item >
      <div className="portfolio-cell-inner">
        <span className="portfolio-title is-preloader"></span>
        <div className="portfolio-href portfolio-href--image">
          <div className="portfolio-src portfolio-src--bg">
            <div className="portfolio-src-inner is-preloader">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="portfolio-cell slide-in" data-parallax-item >
      <div className="portfolio-cell-inner">
        <span className="portfolio-title is-preloader"></span>
        <div className="portfolio-href portfolio-href--image">
          <div className="portfolio-src portfolio-src--bg">
            <div className="portfolio-src-inner is-preloader">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
