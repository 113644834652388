import React, { Component } from 'react'
import * as css from 'classnames'

export default class extends Component {
  state = {
    isVisible: false
  }

  componentDidMount () {
    const publicationArticle = this.refs.publicationArticle

    if (publicationArticle.getBoundingClientRect().top <= (window.innerHeight * .8)) {
      this.setState({
        isVisible: true
      })
    }

    document.addEventListener('scrolled', this.onScroll)
  }

  onScroll = () => {
    const publicationArticle = this.refs.publicationArticle

    if (publicationArticle.getBoundingClientRect().top <= (window.innerHeight * .8)) {
      this.setState({
        isVisible: true
      })

      document.removeEventListener('scrolled', this.onScroll)
    }

  }

  componentWillUnmount () {
    document.removeEventListener('scrolled', this.onScroll)
  }

  render () {
    return (
      <article className={css("publication-article", 'Cf', {'slide-in': this.state.isVisible})} ref='publicationArticle'>
        <div className='publication-article--img'>
          <a className="publication-article--href" href={this.props.link} target='_blank' rel="noopener noreferrer">
            { this.props.youtube === undefined
              ? <img alt='publication related' src={this.props.img} />
              : <span dangerouslySetInnerHTML={{ __html: this.props.youtube }}></span>
            }
          </a>
        </div>
        <p className='publication-article--text mercury'>
          <a href={this.props.link} target="_blank" rel="noopener noreferrer"><b className="underline"></b>{this.props.magazine}</a> {this.props.year}
          <span>
            <span dangerouslySetInnerHTML={{ __html: this.props.text }}></span>
            { this.props.case !== undefined &&
              <nobr><span className="case"> → <a href={this.props.case}><b className="underline"></b>view case</a></span></nobr>
            }
          </span>
        </p>
      </article>
    )
  }
}
