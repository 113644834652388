import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import og from '../../assets/education/og.png'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'

import img01 from '../../assets/education/01.jpg'

export default class extends Component {
  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    document.documentElement.style.overflow = ''
  }

  render () {
    return (
      <div className='case-root'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka 👩‍🎓 education</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="We teach and we tutor to share our experience. Learn where you can learn from us." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="We teach and we tutor to share our experience. Learn where you can learn from us." />
          <meta property="og:title" content="Shuka 👩‍🎓 education" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <script async defer src="//assets.pinterest.com/js/pinit.js"></script>
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <TouchNav navBg='transparent' color='dark' />
          <CaseHead leftTitle='education:' rightTitle="courses and tutorial materials" menuColor='dark' isTech='true' />
        </div>
        <div className='head-line Cf'>
          <div className="head-line-start icon zero">sss</div>
          <p className="head-line-end mercury">Both <a href="//tr.im/m6Wea"><b className="underline"></b>Ivan Velichko</a> and <a href="//tr.im/f3PI6"><b className="underline"></b>Ivan Vasin</a> are giving a course of typography at The British Higher School of Art and Design. While there’s no properly compiled manual yet, we have gathered a lot of teaching material over the years</p>
        </div>
        <div className='education-portfolio at-main'>
          <article className="education-cell PinterestWrapper Mb(50px)--sm Mb(0)--md">
            <div className="education-cell--img">
              <img src={img01} alt="" />
            </div>
            <p className="education-cell--text mercury"><span className="case"><a href="//tr.im/Ovy1T"><b className="underline"></b>Polish poster collection</a> → flickr</span><br/>In 2009 <a href="//tr.im/m6Wea"><b className="underline"></b>Ivan Velichko</a> finished his phd partly based on this material</p>
          </article>
          <article className="education-cell PinterestWrapper Mb(50px)--sm Mb(0)--md">
            <div className="education-cell--img">
              <a data-pin-do="embedBoard" data-pin-scale-height="600" data-pin-scale-width="1920" href="https://ru.pinterest.com/snegotron/modernism-for-students/"></a>
            </div>
            <p className="education-cell--text mercury"><span className="case"><a href="http://tr.im/PuFMN"><b className="underline"></b>Modernism essentials</a> → pinterest</span><br/>We compiled this board for our module of typography course: it collects our favourite works by Wim Crowel, Muller-Brockmann and others</p>
          </article>
        </div>
      </div>
    )
  }
}
