import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import CaseHead from '../CaseHead'
import $ from 'jquery'

import Link from '../../helpers/Link.js'
import og from '../../assets/contact/og.png'

export default class extends Component {
  componentDidMount () {
    document.documentElement.style.overflow = ''

    var hintSubMenus = [].slice.call(document.querySelectorAll('.hints-contents-list--submenu ul'))
    var hintSubMenuLinks = [].slice.call(document.querySelectorAll('.hints-contents-list--submenu a'))

    hintSubMenuLinks.forEach(function(hintSubMenuLink){
      hintSubMenuLink.addEventListener('click', function(ev) {
        ev.preventDefault()
        var id = hintSubMenuLink.getAttribute('href')
        var target = document.querySelector(id)

        $([document.documentElement, document.body]).animate({
          scrollTop: $(target).offset().top
        }, 1000)
      })
    })

    hintSubMenus.forEach(function(hintSubMenu) {
      var subMenuArrow = hintSubMenu.parentNode.querySelector('span')

      $(subMenuArrow).click(function() {
        subMenuArrow.classList.toggle('open')
      	$(this).parent(".hints-contents-list--submenu").children("ul").slideToggle("100");
      });
    })
  }

  render () {
    return (
      <div className='case-root'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka's 📝 Hints</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="There is no place like home. Except for the office." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="There is no place like home. Except for the office." />
          <meta property="og:title" content="Contact 📠 Shuka" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <CaseHead leftTitle='Щучье' rightTitle="содержание" menuColor='dark' isTech='true' />
        </div>
        <div className='head-line Cf'>
          <div className="head-line-start icon zero">iii</div>
          <p className="head-line-end mercury">Данный документ – это свод всех существующих страниц сайта. На большее их количество можно перейти с меню сайта, но некоторые из них - скрыты, и на них можно перейти только зная ссылку.</p>
        </div>
        <div className='events-table Cf'>
          <div className="contacts">
            <div className="Mb(100px)--md Mb(80px)--sm">
              <div className='hints-wrapper'>
                <div className='hints-container'>
                  <div className='hints-block'>
                    <div className='hints-contents-list--wraper'>
                      <div className='mars zero hints-contents-list--headline'>категории</div>
                      <ul className='hints-contents-list'>
                        <li className='hints-contents-list--submenu'>
                          <a className='hints-contents-list--submenu-link' href='#techpages'>Технически страницы</a>
                        </li>
                        <li className='hints-contents-list--submenu'>
                          <a className='hints-contents-list--submenu-link' href='#internal'>Страницы внутреннего пользования</a>
                        </li>
                        <li className='hints-contents-list--submenu'>
                          <a className='hints-contents-list--submenu-link' href='#facades'>Facades</a>
                        </li>
                        <li className='hints-contents-list--submenu'>
                          <a className='hints-contents-list--submenu-link' href='#projects'>Кейсы</a>
                        </li>
                        <li className='hints-contents-list--submenu'>
                          <a className='hints-contents-list--submenu-link' href='#links'>Ссылки на документы</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='hints-block'>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='techpages'>Технически страницы</div>
                      <div>
                        <p className='pluto zero'>
                          <Link to="/error" data-action="tap"><b className="underline"></b>Ошибка</Link><br/>
                          <Link to="/work" data-action="tap"><b className="underline"></b>Work</Link><br/>
                          <Link to="/careers" data-action="tap"><b className="underline"></b>Careers</Link><br/>
                          <Link to="/press" data-action="tap"><b className="underline"></b>Press</Link><br/>
                          <Link to="/awards" data-action="tap"><b className="underline"></b>Awards</Link><br/>
                          <Link to="/publications" data-action="tap"><b className="underline"></b>Publications</Link><br/>
                          <Link to="/events" data-action="tap"><b className="underline"></b>Events</Link><br/>
                          <Link to="/education" data-action="tap"><b className="underline"></b>Education</Link><br/>
                          <Link to="/contact" data-action="tap"><b className="underline"></b>Contact</Link><br/>
                          <Link to="/about" data-action="tap"><b className="underline"></b>About</Link><br/>
                          <Link to="/newsletters" data-action="tap"><b className="underline"></b>Newsletters</Link><br/>
                          <Link to="/emojinarium" data-action="tap"><b className="underline"></b>Emojinarium</Link>
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='internal'>Страницы внутреннего пользования</div>
                      <div>
                        <p className='pluto zero'>
                          <Link to="/hints" data-action="tap"><b className="underline"></b>Подсказки</Link><br/>
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='facades'>Facades</div>
                      <div>
                        <p className='pluto zero'>
                          <Link to="/facades" data-action="tap"><b className="underline"></b>Главная</Link><br/>
                          <Link to="/facades/1" data-action="tap"><b className="underline"></b>2016</Link><br/>
                          <Link to="/facades/2" data-action="tap"><b className="underline"></b>2017</Link><br/>
                          <Link to="/facades/unwrapping" data-action="tap"><b className="underline"></b>2018</Link><br/>
                          <Link to="/facades/red-edition" data-action="tap"><b className="underline"></b>2019</Link>
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='projects'>Кейсы</div>
                      <div>
                        <p className='pluto zero'>
                          <Link to="/abbyy" data-action="tap"><b className="underline"></b>Abbyy</Link><br/>
                          <Link to="/porter-and-sail" data-action="tap"><b className="underline"></b>Porter and Sail</Link><br/>
                          <Link to="/semrush" data-action="tap"><b className="underline"></b>Semrush</Link><br/>
                          <Link to="/merchlab" data-action="tap"><b className="underline"></b>Merchlab</Link><br/>
                          <Link to="/orbi" data-action="tap"><b className="underline"></b>Orbi</Link><br/>
                          <Link to="/skyeng" data-action="tap"><b className="underline"></b>Skyeng</Link><br/>
                          <Link to="/nike" data-action="tap"><b className="underline"></b>Nike</Link><br/>
                          <Link to="/flacon" data-action="tap"><b className="underline"></b>Flacon</Link><br/>
                          <Link to="/rossiyskoye" data-action="tap"><b className="underline"></b>Rossiyskoye</Link><br/>
                          <Link to="/lamoda" data-action="tap"><b className="underline"></b>Lamoda</Link><br/>
                          <Link to="/iitp-identity" data-action="tap"><b className="underline"></b>IITP</Link><br/>
                          <Link to="/fragrance-library" data-action="tap"><b className="underline"></b>Fragrance library</Link><br/>
                          <Link to="/mercuryo" data-action="tap"><b className="underline"></b>Mercuryo</Link><br/>
                          <Link to="/gagbot" data-action="tap"><b className="underline"></b>Gagbot</Link><br/>
                          <Link to="/aids" data-action="tap"><b className="underline"></b>AIDS</Link><br/>
                          <Link to="/gravity" data-action="tap"><b className="underline"></b>Gravity</Link><br/>
                          <Link to="/hyperverse" data-action="tap"><b className="underline"></b>Hyperverse</Link><br/>
                          <Link to="/brobrolab-identity" data-action="tap"><b className="underline"></b>Brobrolab</Link><br/>
                          <Link to="/flatplan" data-action="tap"><b className="underline"></b>Flatplan</Link><br/>
                          <Link to="/jederhaseeinkuenstler" data-action="tap"><b className="underline"></b>Jeder hase ein kuenstler</Link><br/>
                          <Link to="/osome" data-action="tap"><b className="underline"></b>Osome</Link><br/>
                          <Link to="/nachili" data-action="tap"><b className="underline"></b>Na chili</Link><br/>
                          <Link to="/british-art-school-posters" data-action="tap"><b className="underline"></b>British higher school of art and design</Link><br/>
                          <Link to="/karabas" data-action="tap"><b className="underline"></b>Karabas</Link><br/>
                          <Link to="/the-untitled" data-action="tap"><b className="underline"></b>The untitled</Link><br/>
                          <Link to="/park-remesel" data-action="tap"><b className="underline"></b>Park Remesel</Link><br/>
                          <Link to="/soul" data-action="tap"><b className="underline"></b>Soul</Link><br/><br/>
                        </p>
                        <p className='pluto'>Шахматы</p>
                        <p className='pluto zero'>
                          <Link to="/world-chess-london" data-action="tap"><b className="underline"></b>2018 world chess championship in london</Link><br/>
                          <Link to="/world-chess-championship" data-action="tap"><b className="underline"></b>2016 chess championship in nyc</Link><br/>
                          <Link to="/armageddon" data-action="tap"><b className="underline"></b>Armageddon</Link><br/>
                          <Link to="/chessclubmoscow" data-action="tap"><b className="underline"></b>world chess club moscow</Link><br/>
                        </p><br/>
                        <p className='pluto'>Pure</p>
                        <p className='pluto zero'>
                          <Link to="/pure-app" data-action="tap"><b className="underline"></b>Pure app</Link><br/>
                          <Link to="/sex-is-pure" data-action="tap"><b className="underline"></b>sex is pure art digest</Link><br/>
                          <Link to="/pure-dating" data-action="tap"><b className="underline"></b>pure hookup app</Link><br/>
                        </p><br/>
                        <p className='pluto'>prime magazine</p>
                        <p className='pluto zero'>
                          <Link to="/the-prime-russian-magazine-part-2" data-action="tap"><b className="underline"></b>the prime russian magazine 2016: redesign</Link><br/>
                          <Link to="/the-prime-russian-magazine-part-1" data-action="tap"><b className="underline"></b>the prime russian magazine 2012 — 2015</Link><br/>
                        </p><br/>
                        <p className='pluto'>heritage magazine</p>
                        <p className='pluto zero'>
                          <Link to="/moscow-heritage-2012" data-action="tap"><b className="underline"></b>moscow heritage magazine 2012</Link><br/>
                          <Link to="/moscow-heritage-2013" data-action="tap"><b className="underline"></b>moscow heritage magazine 2013 redesign</Link><br/>
                        </p>
                      </div>
                    </div>
                    <div className='hints-block--inner-item'>
                      <div className='mars zero hints-block--inner-item-headline' id='links'>Ссылки</div>
                      <div>
                        <p className='pluto zero'>
                          <a target='_blank' href='https://www.dropbox.com/s/cpjkbb0qm9khm4j/shuka_brand_bureau_n.pdf?dl=0' className="C(#000)"><b className="underline"></b>Щучья презентация (октябрь 2018)</a>
                          <br/><br/>
                          <a target='_blank' href='https://www.artlebedev.ru/kovodstvo/sections/167/' className="C(#000)"><b className="underline"></b>Метод прогрессивного джипега в 2010 году описал Артемий Лебедев.</a>
                          <br/><br/>
                          <a target='_blank' href='https://ksoftware.livejournal.com/397808.html' className="C(#000)"><b className="underline"></b>Оригинальный пост Товеровского о разнице между «делать» и «сделать».</a>
                        </p>
                        <br/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
