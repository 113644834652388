import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import './index.css'
import resized from './utils/resized'
import customHistory from './history'

import App from './App'
import { unregister } from './serviceWorker'

const supportsHistory = 'pushState' in window.history

ReactDOM.render(
  <Router history={customHistory} forceRefresh={!supportsHistory}>
    <App />
  </Router>,
  document.getElementById('root')
)

unregister()
resized()
