import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'

import Link from '../../helpers/Link'

import year02 from '../../assets/facades/2019/Round-Table-Branding-teaser.jpg'
import year03 from '../../assets/facades/2019/10K_big.png'
import year04 from '../../assets/facades/2019/chess.jpg'
import year05 from '../../assets/facades/2019/sneacker.png'

export default class extends Component {
  componentDidMount() {
    var config = {
      returnSpeed: 0.4
    }

    const r2d = (r) => {
      return 180 / Math.PI * r;
    }

    const random = (min, max) => {
      return min + (max - min) * Math.random();
    }

    function Eye (sel) {
      // dom
      this.eye = sel
      this.pupil = this.eye.children[0]

      // widths
      //this.ew = this.eye.getBoundingClientRect().width;
      this.ew = this.eye.offsetWidth;
      this.pw = this.pupil.offsetWidth;

      // centered position
      this.cx = this.eye.getBoundingClientRect().right - (this.ew / 2);
      this.cy = this.eye.getBoundingClientRect().bottom - (this.ew / 2);
      // state
      this.bLidUp = true;
    }

    Eye.prototype.movePupil = function(r, theta) {
      var x, y;

      if (r > 1) r = 1; // clamp
      r *= (this.ew/2 - this.pw/2); // restrict edge of pupil to edge of eye

      // convert polar to rectangular
      x = r * Math.cos(theta) + (this.ew - this.pw)/2;
      y = r * Math.sin(theta) + (this.ew - this.pw)/2;

      // console.log(this.pupil, x, y)
      this.pupil.style.transform = `translate(${x}px, ${y}px)`
    }

    const leye = new Eye(this.refs['eye-left'])
    const eyes = [leye]
    const eyeCount = eyes.length
    const wrapper = this.refs['mouse-container']
    let R = 0
    let THETA = 0
    const wrapperWidth = wrapper.offsetWidth
    const wrapperHeight = wrapper.offsetHeight
    let bMouseOver = false

    const updateEyes = (event) => {
      // if (this.eye === null) return
      var mx = event.pageX,
      my = event.pageY,
      width = window.innerWidth,
      height = window.innerHeight;

      var x, y, bboxWidth, bboxHeight, bbRadius;

      bMouseOver = true;

      // center x, y
      x = mx - width/2;
      y = my - height/2;

      // get bbox bounds
      bboxWidth = wrapperWidth;
      bboxHeight = wrapperHeight;
      bbRadius = Math.sqrt(Math.pow(bboxWidth,2) + Math.pow(bboxHeight, 2)) /2;

      // computer,  theta
      R = Math.sqrt(Math.pow(x,2) + Math.pow(y,2)) / bbRadius;
      THETA = Math.atan2(y,x);
    }

    const returnToNeutral = () => {
      bMouseOver = false;
    }

    const draw = () => {
      window.requestAnimationFrame(draw);

      let dr = config.returnSpeed

      if (!bMouseOver && R !== 0) {
        dr = (Math.abs(R) < 0.01) ? 0.01 : R * dr;
        R -= dr;
      }

      for(var e = 0; e < eyes.length; e++) {
        eyes[e].movePupil(R, THETA)
      }
    }

    draw()

    document.addEventListener('mousemove', updateEyes, false)
  }

  render () {
    return (
      <div className='main-wrap'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka ★ Design Bureau</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="Moscow-based design studio. Tailoring identities. Producing websites. Making books. Being cool." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="Tailoring identities. Producing  websites. Making books. Being cool." />
          <meta property="og:title" content="Shuka Design Bureau" />
          <meta property="og:type" content="website" />
        </Helmet>
        <TouchNav />
        <header className="head new">
          <div className="head-body">
            <div className="head-body--inner">
              <Link to="/" className="head-logo mars">
                <div className='head-logo-text'>
                  <div className="head-logo-text--main"><span>shuka</span><span className="umlaut">shüka</span></div>
                  <span className='head-logo-text--desc is-facades'>brand bureau</span>
                  <span className='head-logo-text--desc is-facades'>moscow</span>
                </div>
              </Link>
            </div>
          </div>
        </header>
        <div className="portfolio at-main is-hidden--mob">
          <div className="mouse-container" ref="mouse-container">
            <div className="calendar-item-red" style={{padding: '6.5px 0'}}>
              <span className="calendar-item-red--wide">
                <span style={{display: 'inline-block', width: '65%', verticalAlign: 'top', position: 'relative', top: '15px', color: '#ff0000'}} >
                  <svg className="W(100%)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2277.44 898.47">
                    <polygon fill="#fc0d1b" points="119.14 351.51 119.14 153.3 55.64 153.3 55.64 325.2 10.74 370.11 55.64 415.01 119.14 351.51"/>
                    <polygon fill="#fc0d1b" points="173.34 153.3 119.14 153.3 173.34 370.11 173.34 153.3"/>
                    <polygon fill="#fc0d1b" points="263.48 198.2 298.56 163.12 347.64 192.57 263.48 108.4 199.97 171.9 199.97 370.11 263.48 370.11 263.48 198.2"/>
                    <polygon fill="#fc0d1b" points="1204.03 153.3 1149.83 370.11 1204.03 370.11 1204.03 153.3"/>
                    <polygon fill="#fc0d1b" points="1086.33 198.2 1086.33 370.11 1149.83 370.11 1149.83 171.9 1107.37 129.44 1086.33 108.4 1002.16 192.57 1051.24 163.12 1086.33 198.2"/>
                    <polygon fill="#fc0d1b" points="1230.65 471.22 1194.39 507.48 1151.26 481.61 1230.66 561.01 1230.66 561.01 1230.66 561.01 1293.99 497.68 1294.15 497.68 1294.15 497.52 1294.16 497.51 1294.15 497.5 1294.16 153.3 1230.66 153.3 1230.65 471.22"/>
                    <polygon fill="#fc0d1b" points="411.14 415.01 411.14 415.01 574.24 251.91 426.5 340.56 411.14 325.2 411.14 153.3 347.64 153.3 347.64 351.51 411.14 415.01"/>
                    <polygon fill="#fc0d1b" points="413.52 283.52 477.44 219.6 522.34 174.7 495.13 147.49 456.04 108.4 411.14 153.3 461.46 203.62 413.52 283.52"/>
                    <polygon fill="#fc0d1b" points="1374.98 415.01 1488.75 301.18 1402.63 352.85 1374.98 325.2 1374.98 153.3 1311.48 153.3 1311.48 351.51 1374.98 415.01"/>
                    <polygon fill="#fc0d1b" points="1377.36 283.52 1441.28 219.6 1486.18 174.7 1458.97 147.49 1419.88 108.4 1374.98 153.3 1425.3 203.62 1377.36 283.52"/>
                    <polygon fill="#fc0d1b" points="599.7 108.4 515.53 192.57 564.61 163.12 599.7 198.2 599.7 370.11 663.2 370.11 663.2 171.9 599.7 108.4"/>
                    <polygon fill="#fc0d1b" points="915.93 153.3 852.43 153.3 852.43 370.11 915.93 306.61 915.93 153.3"/>
                    <polygon fill="#fc0d1b" points="807.53 153.41 744.03 153.41 744.03 351.51 807.53 415.01 852.43 370.11 807.53 325.2 807.53 153.41"/>
                    <polygon fill="#fc0d1b" points="689.83 153.3 689.83 370.11 744.03 153.3 689.83 153.3"/>
                    <polygon fill="#fc0d1b" points="1673.89 343.8 1673.89 171.9 1673.86 171.9 1610.38 108.4 1565.48 153.3 1501.99 153.3 1501.99 351.51 1565.49 415.01 1610.39 370.11 1565.49 325.2 1565.49 153.32 1610.39 198.23 1610.39 370.11 1655.29 415.05 1739.45 330.89 1690.4 360.32 1673.89 343.8"/>
                    <polygon fill="#fc0d1b" points="2069.11 369.5 2069.11 370.11 2132.61 370.11 2132.61 261.7 2069.11 261.7 2069.11 369.5"/>
                    <rect fill="#fc0d1b" x="2005.61" y="153.3" width="63.5" height="108.4"/>
                    <polygon fill="#fc0d1b" points="2125.59 209.8 2096.14 258.89 2180.31 174.72 2180.3 174.71 2180.31 174.7 2114.01 108.4 2069.11 153.3 2125.59 209.8"/>
                    <polygon fill="#fc0d1b" points="2012.63 313.02 2042.08 263.94 1957.92 348.11 2021.42 411.61 2024.21 414.4 2069.11 369.5 2012.63 313.02"/>
                    <polygon fill="#fc0d1b" points="1979.31 66.32 2014.4 101.4 1984.95 150.48 2024.21 111.22 2069.11 66.32 2069.1 66.31 2069.11 66.3 2002.82 0 1957.92 44.9 1979.32 66.3 1979.31 66.32"/>
                    <polygon fill="#fc0d1b" points="1810.72 808.62 1765.82 853.52 1810.72 898.43 1874.22 834.93 1874.22 636.72 1810.72 636.72 1810.72 808.62"/>
                    <polygon fill="#fc0d1b" points="1928.42 853.52 1928.42 636.72 1874.22 636.72 1928.42 853.52"/>
                    <polygon fill="#fc0d1b" points="2102.72 675.99 2018.55 591.82 1955.05 655.32 1955.05 853.52 2018.55 853.52 2018.55 681.62 2053.64 646.54 2102.72 675.99"/>
                    <polygon fill="#fc0d1b" points="445.06 784.6 358.94 836.27 331.29 808.62 331.29 636.72 267.79 636.72 267.79 834.93 331.26 898.39 331.29 898.43 445.06 784.6"/>
                    <polygon fill="#fc0d1b" points="333.67 766.94 442.49 658.12 376.19 591.82 331.29 636.72 381.61 687.04 333.67 766.94"/>
                    <polygon fill="#fc0d1b" points="1101.31 853.52 1101.31 636.72 1047.11 853.52 1101.31 853.52"/>
                    <polygon fill="#fc0d1b" points="1047.11 681.62 1047.11 655.32 983.61 591.82 899.45 675.99 948.53 646.54 983.61 681.62 983.61 853.52 1047.11 853.52 1047.11 681.62"/>
                    <polygon fill="#fc0d1b" points="1184.19 808.62 1184.19 636.72 1120.69 636.72 1120.69 834.93 1184.19 898.43 1268.36 814.26 1219.28 843.71 1184.19 808.62"/>
                    <polygon fill="#fc0d1b" points="702.27 636.72 638.77 636.72 638.77 834.93 702.27 898.43 747.18 853.52 702.27 808.62 702.27 636.72"/>
                    <polygon fill="#fc0d1b" points="702.27 636.72 747.18 681.62 747.18 853.52 810.68 853.52 810.68 655.32 747.18 591.82 702.27 636.72"/>
                    <polygon fill="#fc0d1b" points="898.41 827.22 898.41 445.82 834.91 509.47 834.91 853.52 879.81 898.47 963.98 814.31 914.92 843.74 898.41 827.22"/>
                    <polygon fill="#fc0d1b" points="572.48 591.82 527.58 636.72 584.06 693.22 554.61 742.3 638.77 658.14 638.76 658.13 638.77 658.12 572.48 591.82"/>
                    <polygon fill="#fc0d1b" points="527.58 852.92 527.58 853.52 591.08 853.52 591.08 745.12 527.58 745.12 527.58 852.92"/>
                    <rect fill="#fc0d1b" x="464.08" y="636.72" width="63.5" height="108.4"/>
                    <polygon fill="#fc0d1b" points="471.1 796.45 500.55 747.36 416.38 831.53 479.88 895.03 482.68 897.83 527.58 852.92 471.1 796.45"/>
                    <polygon fill="#fc0d1b" points="2277.44 658.12 2211.15 591.82 2166.24 636.72 2222.72 693.22 2193.28 742.3 2232.54 703.04 2277.44 658.14 2277.43 658.13 2277.44 658.12"/>
                    <polygon fill="#fc0d1b" points="2166.24 852.92 2166.24 853.52 2229.74 853.52 2229.74 745.12 2166.24 745.12 2166.24 852.92"/>
                    <rect fill="#fc0d1b" x="2102.75" y="636.72" width="63.5" height="108.4"/>
                    <polygon fill="#fc0d1b" points="2109.77 796.45 2139.22 747.36 2055.05 831.53 2118.55 895.03 2121.34 897.83 2166.24 852.92 2109.77 796.45"/>
                    <polygon fill="#fc0d1b" points="1440.26 853.52 1440.26 636.72 1386.06 636.72 1440.26 853.52"/>
                    <rect fill="#fc0d1b" x="1322.56" y="636.72" width="63.5" height="216.8"/>
                    <polygon fill="#fc0d1b" points="1268.36 853.52 1322.56 636.72 1268.36 636.72 1268.36 853.52"/>
                    <polygon fill="#fc0d1b" points="129.06 827.25 129.06 636.75 84.17 591.8 0 675.97 49.08 646.52 65.56 663 65.56 663.05 65.56 853.55 110.45 898.45 110.45 898.45 110.45 898.45 194.62 814.28 145.54 843.73 129.06 827.25"/>
                    <polygon fill="#fc0d1b" points="173.96 591.8 129.06 636.7 192.55 700.2 192.55 700.2 192.6 700.25 276.77 616.08 227.68 645.53 173.96 591.8"/>
                    <polygon fill="#fc0d1b" points="1657.42 636.72 1593.92 636.72 1593.92 834.93 1657.42 898.43 1702.32 853.52 1657.42 808.62 1657.42 636.72"/>
                    <polygon fill="#fc0d1b" points="1702.32 591.82 1657.42 636.72 1702.32 681.62 1702.32 853.52 1765.82 853.52 1765.82 655.32 1702.32 591.82"/>
                    <rect fill="#fc0d1b" x="1651.63" y="585.33" width="63.5" height="63.5" transform="translate(-136.64 1282.31) rotate(-45)"/>
                    <polygon fill="#fc0d1b" points="1556.87 843.71 1521.79 808.62 1521.79 636.72 1458.29 636.72 1458.29 834.93 1521.79 898.43 1605.95 814.26 1556.87 843.71"/>
                    <polygon fill="#fc0d1b" points="1822.27 343.85 1822.27 153.35 1777.38 108.4 1693.2 192.57 1742.29 163.12 1758.77 179.6 1758.77 179.65 1758.77 370.15 1803.65 415.05 1803.65 415.05 1803.65 415.05 1887.83 330.88 1838.74 360.33 1822.27 343.85"/>
                    <polygon fill="#fc0d1b" points="1867.16 108.4 1822.27 153.3 1885.76 216.8 1885.76 216.8 1885.81 216.85 1969.97 132.68 1920.88 162.13 1867.16 108.4"/>
                  </svg>
                </span>
                <svg style={{position: 'relative', width: '33.7%', color: '#29a349'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                  <g>
                  	<path d="M0,406.8V334h89.6V150.2H21.3v-12.3L154.6,8.5h10.6V334h89.6v72.8H0z"/>
                  </g>
                  <path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                </svg>
              </span>
              <span className="calendar-item-red--narrow">
                <span style={{position: 'relative', paddingLeft: '30px', display: 'inline-block', verticalAlign: 'top', width: '100%', color: '#ff0000', textAlign: 'center'}} className="mercury">
                  We resolve to update our <Link to="/work" style={{color: '#b4966e'}}>projects list</Link> more often, and start with a duck.
                </span>
              </span>
            </div>

            <div className="calendar-item-red">
              <div className="calendar-item-red--wide">
                <span style={{display: 'inline-block', width: '50%', paddingRight: '15px', color: '#29a349', transition: 'all 180ms'}} >
                  <svg style={{position: 'relative', width: '67.4%'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                    <g>
                  	   <path d="M96.9,267.1V336h169.3v71.1H25.8V259.8h71.1v-63.3c5,0.6,32.5,0.6,38.6,0.6c34.7,0,62.7-28,62.7-62.7s-28-62.7-62.7-62.7
                  		c-34.7,0-62.7,28-62.7,62.7H0C0,56.6,56.6,0,134.4,0S275,56.6,275,134.4s-62.7,135-140.6,135C128.2,269.4,102.5,268.2,96.9,267.1z"
                  		/>
                    </g>
                    <path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                  </svg>
                </span>
                <img src={year02} alt="" style={{position: 'absolute', display: 'block', width: '67%', top: '50%', transform: 'translateY(-50%)', right: '0'}} />
              </div>
              <span className="calendar-item-red--narrow">
                <span style={{position: 'relative', paddingLeft: '30px', display: 'inline-block', verticalAlign: 'top', width: '100%', color: '#ff0000', textAlign: 'center'}} className="mercury">
                  We will restart the <Link to="/" style={{color: '#b4966e'}}>mailing list</Link>. Long live Arthur, the king of branding.
                </span>
              </span>
            </div>

            <div className="calendar-item-red">
              <div className="calendar-item-red--wide">
                <span style={{position: 'relative', display: 'inline-block', width: '62.8%'}}>
                  <img src={year03} alt="" style={{display: 'block', width: '100%', margin: '0 auto'}}/>
                  <span ref="eye-left" className="eye-center-container" style={{position: 'absolute', top: '0', width: '18.8vw', height: '100%', right: '20%'}}>
                    <span className="eye-center" style={{position: 'absolute', transform: 'translate(25%,-25%)', width: '8.7vw'}}>
                      <svg style={{position: 'relative', display: 'block', width: '100%', height: '100%'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218.11 218.11">
                        <circle cx="109.06" cy="109.06" r="109.06" fill='#3aa74b'/>
                        <polygon points="109.06 8.59 114.31 59.1 129.94 10.79 124.58 61.28 149.92 17.28 134.17 65.55 168.11 27.78 142.67 71.73 183.71 41.83 149.69 79.53 196.06 58.82 154.94 88.62 204.6 78.01 158.19 98.61 208.97 98.55 159.29 109.06 208.97 119.56 158.19 119.5 204.6 140.1 154.94 129.49 196.06 159.29 149.69 138.58 183.71 176.28 142.67 146.38 168.11 190.33 134.17 152.56 149.92 200.83 124.58 156.83 129.94 207.32 114.31 159.01 109.06 209.52 103.81 159.01 88.17 207.32 93.53 156.83 68.19 200.83 83.94 152.56 50.01 190.33 75.44 146.38 34.4 176.28 68.42 138.58 22.05 159.29 63.17 129.49 13.51 140.1 59.92 119.5 9.14 119.56 58.82 109.06 9.14 98.55 59.92 98.61 13.51 78.01 63.17 88.62 22.05 58.82 68.42 79.53 34.4 41.83 75.44 71.73 50.01 27.78 83.94 65.55 68.19 17.28 93.53 61.28 88.17 10.79 103.81 59.1 109.06 8.59" fill='#ff558a'/>
                        <circle cx="109.06" cy="109.06" r="50" fill='#353130'/>
                        <circle cx="133.08" cy="86.03" r="16.86" fill='#fff'/>
                      </svg>
                    </span>
                  </span>
                </span>
                <span style={{position: 'relative', display: 'inline-block', width: '32.4%', color: '#29a349', transition: 'all 180ms'}}>
                  <svg style={{position: 'relative', width: '100%'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                  	<g>
                  		<path d="M70,218.5l70-138.9H29.7V8.5h219v12.9l-70.6,144.5c56,11.2,91.3,61.6,91.3,115.4c0,74.5-60.5,134.4-135,134.4
                  		C59.9,415.6,0,355.7,0,281.2h72.8c0,34.7,28,62.7,62.7,62.7s62.7-28,62.7-62.7s-28-62.7-62.7-62.7c-21.8,0-44.2,2.8-56.6,9
                  		L70,218.5z"/>
                  	</g>
                  	<path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                  </svg>
                </span>
              </div>
              <span className="calendar-item-red--narrow" style={{paddingLeft: '30px'}}>
                <span style={{position: 'relative', paddingLeft: '30px', display: 'inline-block', verticalAlign: 'top', width: '100%', color: '#ff0000', textAlign: 'center'}} className="mercury">
                  We resolve to become more popular and gain 10k <a href="https://www.instagram.com/shukagram/" style={{color: '#b4966e'}}>Instagram</a> followers.
                </span>
              </span>
            </div>

            <div className="calendar-item-red">
              <div className="calendar-item-red--wide">
                <span style={{display: 'inline-block', width: '34.2%', paddingRight: '15px', color: '#29a349', transition: 'all 180ms'}}>
                  <svg style={{position: 'relative', width: '100%'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                  	<g>
                  		<path d="M126.6,255.3h169.1v72.8h-68.9v78.4h-71.1v-78.4H0v-10.6L180.9,8.9h84.6L126.6,255.3z"/>
                  	</g>
                  	<path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                  </svg>
                </span>
                <span style={{position: 'relative', display: 'inline-block', width: '65.8%'}}>
                  <svg style={{position: 'relative', display: 'block', width: '100%'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 527.9 283.8">
                    <title>Artboard 1</title>
                    <rect width="491" height="246.9" fill='#b4966e'/>
                    <polygon points="491 0 527.9 36.9 527.9 283.8 491 246.9 491 0" fill='#3aa74b'/>
                    <polygon points="0 246.9 491 246.9 527.9 283.8 36.9 283.8 0 246.9" fill='#ff558a'/>
                    <ellipse cx="307.14" cy="123.43" rx="62.65" ry="49.93" fill='#353130'/>
                    <path d="M299.53,100h-7.94a6.82,6.82,0,0,1-6.86-6.78V80.36a6.83,6.83,0,0,1,6.8-6.86h8a6.83,6.83,0,0,1,6.85,6.81V93.19a6.82,6.82,0,0,1-6.83,6.81Z" fill='#fff'/>
                    <path d="M322.17,100h-7.94a6.82,6.82,0,0,1-6.85-6.79V80.36a6.82,6.82,0,0,1,6.78-6.86h8A6.82,6.82,0,0,1,329,80.31V93.19A6.82,6.82,0,0,1,322.17,100Z" fill='#fff'/>
                    <path d="M343.15,98.55h-7a6.06,6.06,0,0,1-6.15-6V81.08A6.06,6.06,0,0,1,336,75h7.06a6.05,6.05,0,0,1,6.08,6V92.47a6.05,6.05,0,0,1-6,6.08Z" fill='#fff'/>
                    <path d="M277.65,98.55h-7a6.05,6.05,0,0,1-6.08-6V81.08a6.05,6.05,0,0,1,6-6.08h7a6.05,6.05,0,0,1,6.08,6V92.47a6.05,6.05,0,0,1-6,6.08Z" fill='#fff'/>
                    <path d="M439.73,56.32H398.51l-.11,130h22v-44h19.91c23.05,0,41.22-17.52,41.22-43.29C481.61,74.7,463.44,56.43,439.73,56.32Zm-2.5,65.37H420.37V78.29h16.86c13.7,0,21.54,6.64,21.54,21.1C458.88,114.08,450.93,121.69,437.23,121.69Z" fill='#f51027'/>
                    <path d="M307.9,53.38a67.82,67.82,0,1,0,67.66,68v-.21A67.93,67.93,0,0,0,307.9,53.38ZM307.79,162c-38.28,0-56.56-14.9-56.56-41s18.38-40.79,56.56-40.79,56.35,14.9,56.35,40.9S345.86,162,307.79,162Z" fill='#f51027'/>
                    <polygon points="113.53 56.1 113.53 186.08 135.39 186.08 135.5 130.39 196.09 130.5 195.98 186.08 217.95 186.08 218.06 56.21 196.09 56.21 196.09 108.86 135.5 108.86 135.5 56.1 113.53 56.1" fill='#f51027'/>
                    <path d="M51.64,188.91c23.82,0,40.13-17.51,40.13-37.85,0-22.74-19.68-33.5-30.67-40.46l-4.57-2.94C46.09,101,37.28,95.15,37.28,87.86c0-6.74,5.11-13.7,15.23-13.7,11.2,0,16.42,10.77,17.73,15.77l19.25-8.05C85.14,67.74,72.63,53.16,52.18,53.16c-20.88,0-38.39,14.8-38.5,34.16,0,19.9,15.88,29.15,28,36.76l6,3.81c13.16,8.38,20.89,14.79,20.89,23.28,0,9.13-5,16.86-16.32,16.86S31,158.67,31,144H9C9,171.29,27.49,188.91,51.64,188.91Z" fill='#f51027'/>
                    <path d="M227.75,183c0,21.88,9.25,52.68,29.46,52.68,22.54,0,31-38.63,59.79-55.93,52.79-31.75,47.14-56.32,47.14-56.32-7.76-10.05-27.59-16-57-16C239.92,107.4,227.7,154.87,227.75,183Z" fill='#ff558a'/>
                    <path d="M255,221c0-61.5,29.75-91,51-109.25" fill='none' stroke='#f51027' strokeMiterlimit='10'/>
                    <path d="M242.28,186.88a1.62,1.62,0,0,0-1.13-2h0l.46-1.75a1.62,1.62,0,1,0,.83-3.14h0l.46-1.75a1.63,1.63,0,0,0,.82-3.15l.46-1.74a1.63,1.63,0,1,0,.95-3.12l-.12,0,.46-1.75a1.62,1.62,0,0,0,.82-3.14l.46-1.75a1.62,1.62,0,1,0,.83-3.14l.46-1.75a1.62,1.62,0,0,0,.82-3.14l.46-1.75a1.62,1.62,0,1,0,.83-3.14l.46-1.75a1.62,1.62,0,0,0,2-1.11v-.05a1.63,1.63,0,0,0-1.15-2h0l.56-2.13,1.79.47a1.62,1.62,0,0,0,3.14.82l1.76.47a1.62,1.62,0,1,0,3.14.82l1.76.47a1.63,1.63,0,0,0,3.15.82l1.76.46a1.62,1.62,0,1,0,3.14.83l1.76.46a1.62,1.62,0,1,0,3.14.83h0l1.76.46a1.63,1.63,0,0,0,3.15.83l1.76.46a1.62,1.62,0,1,0,3.14.83h0l1.79.47-.56,2.13a1.63,1.63,0,1,0-.95,3.12l.12,0-.46,1.74a1.63,1.63,0,0,0-.82,3.15L285,165a1.62,1.62,0,0,0-.83,3.14h0l-.46,1.75a1.62,1.62,0,0,0-.83,3.14l-.46,1.75a1.62,1.62,0,1,0-.82,3.14l-.46,1.75a1.62,1.62,0,0,0-.83,3.14l-.46,1.75a1.63,1.63,0,0,0-.82,3.15l-.46,1.74a1.63,1.63,0,1,0-.95,3.12l.12,0-.46,1.75a1.62,1.62,0,0,0-.82,3.14l-.51,1.92-1.79-.48a1.62,1.62,0,0,0-3.14-.82l-1.76-.46a1.62,1.62,0,0,0-3.14-.83l-1.76-.46a1.63,1.63,0,0,0-3.15-.83l-1.76-.46a1.62,1.62,0,0,0-3.14-.83l-1.76-.46a1.62,1.62,0,0,0-3.14-.83l-1.76-.46a1.63,1.63,0,0,0-3.15-.82l-1.76-.47a1.62,1.62,0,1,0-3.14-.82l-1.79-.47.51-1.92A1.62,1.62,0,0,0,242.28,186.88Z" fill='#3aa74b'/>
                  </svg>
                </span>
              </div>
              <span className="calendar-item-red--narrow">
                <span style={{position: 'relative', paddingLeft: '30px', display: 'inline-block', verticalAlign: 'top', width: '100%', color: '#ff0000', textAlign: 'center'}} className="mercury">
                  We resolve to open a store of our own with T-shirts, hats and knick-knacks.
                </span>
              </span>
            </div>

            <div className="calendar-item-red" style={{padding: '0'}}>
              <div className="calendar-item-red--wide">
                <span style={{position: 'relative', width: '65.8%', display: 'inline-block'}}>
                  <svg style={{position: 'relative', display: 'block'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 520 290.6" xmlSpace="preserve">
                  	<g>
                  		<path fill="#EBEBE1" d="M166.2,232.5c-0.4-1-3.4-11.5-3.3-26c0.1-13.1,2.7-32.4,15-49.7l1.2-1.7l10.1,2.5l2.5,14.8l17.2,4.5l3.6-3
                  		c0-0.1-0.1-0.2-0.1-0.3c-1.8-4.1-1.4-9.3,1-13.4c1.7-2.9,4.1-4.9,7.1-5.8c1.1-6.1,5.8-11.8,12.1-14.5c9.6-4.1,20.7-1.2,26.8,6.8
                  		c48.8-16,66.7,8.6,76.7,31.9c4.7,10.8,9.6,25.2,5.9,39.9c-3.7,14.7-15.1,27.1-34.8,37.9c1.6,8.1-3.1,19-13,23.2
                  		c-5.9,2.6-14,2-19.7-1c-2.5,1.8-5.7,2.6-9,2.2c-4.7-0.6-8.6-3.6-10.6-8c-14.2,0.8-27.5-1.3-38.5-6.2c-12.3-5.5-21.3-14.1-26-25
                  		c-2.5-5.9-3.7-12.1-3.6-18.4l-2.1-0.3l-8.4,13.1l-9.5-1.7L166.2,232.5z"/>
                  		<g>
                  			<path fill="#FF0000" d="M274.2,274.6c-4.5,5.5-14.8,3.6-17.1-5.2c-29.2,2.4-54.7-8.1-63.8-29.2c-2.7-6.3-3.8-12.9-3.2-19.8l-6.9-1.1
                  			l-8.4,13.1l-5.8-1.1c-0.6-1.3-11.4-40.6,11.3-72.7l6,1.5l2.5,14.8l20.7,5.4l7-5.8c-4.5-5.2-1.8-16.6,6.8-17.5
                  			c0.1-6.2,4.9-11.8,10.5-14.2c9.3-4,19.6-0.3,24.3,7.7c47.6-16.5,64.8,5.5,75.1,29.4c10.5,24.4,14.7,51.7-29.8,75
                  			c2.6,6-0.6,17.6-10.5,21.9C287.2,279.2,278.9,278.3,274.2,274.6z M231.9,162.5c-1.2-4.6,1.4-9.3,5.5-11.1c4.5-2,8.5-0.4,10.9,2.7
                  			l3.4-1.5c-3.2-4.5-9.5-6.6-15.3-4.1c-4.3,1.9-8,6.3-7.9,11.9l0.1,2.5c-1.5-0.6-3-1-4.9-0.2c-2.2,0.9-4,4.3-2.4,8L231.9,162.5z
                  			 M222.5,188.5l-2.6,1.1l-42.5-10.8c-4,11.4-5,22.9-4.5,32.1l38.7,6.1l-3.4-8l4.7-2c7.5,17.5,24.5,29.6,37,24.2l1.9-0.8l-2.9-6.7
                  			l-9.3-3.1l1.2-4.4l10.5,2.9c8-3.4,13.3-1.9,15.3,2.9l-4.1,1.8c-0.8-1.9-4.2-2.5-8-0.9l1.1,2.6c1.5,1.1,3.7,2,7.3,0.4l4.7-2
                  			c4.3-1.9,6.6-5.4,4.7-9.9c-2.1-5-6.8-5.7-11.1-3.9l-1.8-4.1c7.5-4.5,13.1,1.3,15.4,0.2c1.5-0.7,2.2-1.5,1.3-3.4
                  			c-1.5-3.4-7.5-7.3-13.1-4.8l-2.1-5c4.3-1.9,8.3-1,11.8,0.8c0.9-0.9,1-1.7,0.6-2.8c-0.8-1.9-7.5-6.2-14.7-3.1l-2.1-5
                  			c6.5-2.8,8.2-4.1,7.4-6C261.7,171.6,236.1,182.7,222.5,188.5z M243.7,156.6c-0.7-1.7-2.9-2.1-4.7-1.3c-1.7,0.7-3.2,2.7-2.5,4.4
                  			L243.7,156.6z M244.7,172.5c0.5-5.3,3.7-11.6,9.3-14l-11,4.7l2.1,5c-5,2.1-5.7,4.5-5.6,6L244.7,172.5z M296.6,254.2
                  			c-0.7-1.7-1.9-3.3-3.4-4.4c-0.3,1.1,0,2.3,0.2,2.7l-4.3,1.9c0.9,4.5-1.9,8.5-5.6,10.1c-4.5,2-9.8-0.1-11.7-4.7l5-2.1
                  			c0.9,2.2,2.9,3.1,4.8,2.3c2.2-0.9,3.4-2.8,2.3-5.3l4.3-1.9c-0.8-8.9,10-15.8,22.5-21.2c6.3-2.7,11.7-3.8,17-4.5l1.5-2.4
                  			c-18.2-1.9-24-13.4-24.2-14.1l4.7-2c0.2,0.4,5.1,10.6,21.6,11.6l0.9-2.2c-20.5-6.5-29.2-26.8-35.2-40.6l5-2.1
                  			c5.8,13.4,13.6,31.5,31.3,37.6l0.1-2.6c-8.9-3.3-18.7-14.2-24.5-27.8c-14.6-33.8-24.1-35.1-51.2-24.9l2,4.5
                  			c-5.2,2.2-9.1,6.2-9.5,8.7l6-1.6c1.7-3.8,6.9-7.8,16.3-11.9l1.9,4.3c-6.3,2.7-10,5.3-11.7,7.3c2,0.4,4.3,1,5.7,2.6l8.3-4.4l2,4.5
                  			l-8,4c0.9,2.2-0.1,5.1-1.3,6.4l3.1,1.2c1.8-1.3,4.8-3.1,9.1-5l1.9,4.3c-3,1.3-5.3,2.5-7.1,3.6c1.7,1.6,2.8,3.6,2.7,5.7
                  			c2.1-0.4,4.8-1.3,7.4-2.4l2,4.5c-3.4,1.5-7,2.8-9.5,3.4c1.7,1.6,2.4,3.1,2.7,5l9.2,0.1l-0.1,5.1l-10,0l-2.9,2.8l1.6,3.7
                  			c3.3,0.6,8,1.4,11.7,3.4l-2.6,4.7c-2.7-1.4-6.3-2.1-9.1-2.7l-1.6,3.5l8.6,4.5l-2.4,4.6l-9.8-5l-2.6,1.6c2,1.7,4.1,4.1,5.6,7
                  			l-4.8,2.6c-1.7-3.4-4.4-6-6.5-7.4c-1.9,0.8-3.8,1.4-5.5,1.4c1.2,2.3,3.2,5,5.8,7.5l-3.4,3.3c-2.8-2.4-5.2-5.4-6.6-8.2l-4.3,1.9
                  			c2.4,2.6,4.3,7.1,4.9,10.7l-4.7,2c-0.2-4-2.5-8.1-5-10.9l-2.7,0.2l-1.7,12l-4.8-0.5l1.5-11.9l-3.1-0.7l-4.2,11.5l-4.7-1.3
                  			l3.9-11.7l-3.2-1.4l-6.8,9.8l-3.9-2.4l6-9.8l-0.9-0.4l-14.2,6.1l-2.1-5l11.4-4.9l-1.6-1.4l-18.7,3c0.6,2.6,1.3,5.3,2.3,7.7
                  			c8.1,18.8,31.2,27.7,57.7,25.2c1.2-3.1,3.8-6,6.2-7c1.7-0.7,3.4-1,5.3-0.8l-0.3,6.5c-0.6-0.2-1.6-0.1-2.2,0.2
                  			c-3,1.3-3.5,4.3-2.4,6.9c1.4,3.2,7.5,4.4,9.8-1.7c7.4,6,12.5,5.3,16.8,3.5C296.2,268,299.5,260.9,296.6,254.2z M234.3,176.3
                  			c-0.5-3.6,0.5-7.1,3.7-9.8l-7.3,4.4l-1.2,7.4L234.3,176.3z M219,182.9l4.3-1.9l0.6-5.1l-7.5,6.3L219,182.9z M294.2,151.4
                  			c3.7,2.7,6.6,6.4,9.3,10.3l1.9-3.4c9.2,4.7,13.9,12.7,17.9,21.9c2.3,5.4,5.3,10,8.1,12.9c-1.2-3.3-2.5-6.9-4.1-10.7
                  			C320.1,165.8,310.9,153.9,294.2,151.4z M333,201.7c-5.4-3-11.1-11.6-14.5-19.3c-3.5-8.2-6.5-15.1-13.8-18.9c3.5,5.1,6.5,11,9,16.8
                  			c5.4,12.5,13.1,20.9,19.7,24.2L333,201.7z M196.7,224.7l10.5-1.7l-10.4-1.6L196.7,224.7z M301,250c10.1-5.1,17.2-11.8,22.2-17.3
                  			c-8.2,2.3-20,6.3-26,13.3C298.3,246.8,299.9,248.2,301,250z"/>
                  		</g>
                  	</g>
                  	<g>
                  		<g>
                  			<path fill="#EBEBE1" d="M-213.5-55.5c-18.1-0.9-30.9-9.5-37.7-18.4C-262.4-57.9-282-58.8-294-59.4c-15.6-0.7-27.4-5.1-35.3-13.1
                  			c-7.9-8-11.6-19.4-10.9-33.9l1.3-27.3c1.2-24.9,3.9-41.7,8.7-52.8c5-11.9,12.6-18.2,23.7-20l3.4-0.5l-0.3,6.4l5.3,0.3l0.1,2.5
                  			c0.1,2.8-0.1,5.3-0.8,7.7c6.8-4.5,14.2-6.5,22.4-6.1c2.7,0.1,5.6,0.5,8.7,1.2l11.7-1.5l5.8,3.4l0.3,1.1c0.6,2.4,0.6,5.6-0.6,8.8
                  			l7.8,0l0.6,8c0.3,4.4-1.1,8.4-3.8,11.2c-2.9,2.9-7,4.5-12,4.5c7.1,4.1,13.1,10.7,16.2,14.5c8.7-8.7,27.9-30.3,28.9-50.8l0.1-2.8
                  			l11.8,0.6l-0.1,2.8c-0.6,13.6-7.5,35.5-36.6,62.7l-1.3,5.5c7.8,2.7,13.2,11.7,12.7,21.9c-0.6,11.6-7.9,20.5-17.1,21.2
                  			c4.4,8.1,15.4,15.8,30.6,16.5c8.5,0.4,15.9-1.3,22-5.1c4.1-2.6,6.4-5.4,7.2-6.5l0.1-0.1l1.6-2.2l8.5,6.2l-0.5,1.8
                  			c-0.4,1.5-3.7,6.3-9.7,10.4C-189.3-59.3-199.1-54.9-213.5-55.5z M-274.1-91.5c3.4-5.6,6.1-9.8,10.4-12c-0.7-3.3-0.4-6.6,0.9-9.7
                  			c-3.1-4.1-3.8-10.5-3.9-14.3l-6.6-0.3c-4.9,10.7-12.7,19.2-17.9,24l5.8,11.9L-274.1-91.5z"/>
                  			<path fill="#EBEBE1" d="M-216.4-70.1l0.5-11.3l2.8,0.1c8,0.4,15.7-2.9,21.7-9.3c0.5-0.5,1.2-1.3,2.5-1.2l0.8,0l7,5.2l-1.2,2.1
                  			c-0.6,1-3.6,4.8-8.6,8.1c-6.8,4.5-14.6,6.7-22.7,6.3L-216.4-70.1z"/>
                  		</g>
                  		<g>
                  			<polygon fill="#FF0000" points="-287.3,-136.7 -308.4,-114.3 -304.7,-110.6 -283.6,-133.1 		"/>
                  			<path fill="#FF0000" d="M-213.5-72.7c18.3,0.9,28.3-12.2,28.8-13l-4.4-3.2c-0.2,0-8.9,11.2-24.2,10.5L-213.5-72.7z"/>
                  			<path fill="#FF0000" d="M-181.4-77.7c-0.5,0.6-9.3,14-31.6,12.9c-19-0.9-31.7-11.9-34.7-22.1l2.3,0.1c8.4,0.4,15.6-7.6,16.2-18.6
                  				c0.5-10.1-5.4-18.7-13.3-19.7l2.1-9c6.3-5.8,35-32.3,36.3-61.4l-6.2-0.3c-1.1,23.2-23.7,46.9-31.9,54.8
                  				c-4.1-5.5-15.9-19-27.2-19.5c-4.3-0.2-8.8,0.9-13.2,3l3.2-5.8l20.2,1c9.4,0.5,14.7-5.1,14.2-12.7l-0.4-5.4l-10.1,0
                  				c3.4-3.8,3.5-8.3,2.8-10.9l-4-2.3l-11.3,1.4c-11.4-2.6-26.9-3.2-41.9,16.6l-0.1-5.3c7.3-3.5,9.5-10.3,9.2-16.8l-5.6-0.3l0.3-5.8
                  				c-19.7,3.1-27.7,22.3-30,70.2l-1.3,27.3c-1.3,27.5,13.7,42.6,43.5,44c12.9,0.6,32.8,1.6,42.5-16.9c6.1,10.4,19.5,19.9,38,20.8
                  				c26.2,1.2,36.4-14.8,36.7-15.9L-181.4-77.7z M-243.8-119.1c4.5,0.2,8.5,6.4,8.2,13.5c-0.3,6.9-4.9,12.9-9.4,12.7l-1.7-0.1
                  				c0.2-1.2,0.4-2.5,0.6-3.8c3.2,0.2,6.2-4,6.4-7.7l0.1-3l-16.5-0.8c0.2-1.1,0.6-2.3,1.3-3.4l9.4-0.4L-243.8-119.1L-243.8-119.1z
                  				 M-272.6-88.7l-14.6-0.7l-7.5-15.2c5.9-5.2,14.7-14.6,19.5-26.2l11.2,0.5c-0.3,5.6,0.8,13.3,4.4,16.5c-2,3.6-2.3,7.7-0.9,11.8
                  				C-265.6-100.4-268.3-95.8-272.6-88.7z M-293.5-68.6c-26.4-1.3-38.5-13.5-37.4-37.3l1.3-27.3c1.9-39.7,8.9-61.3,22.4-63.7
                  				c1.5,5.4-1.3,9.2-5.5,10.7l-3.1,1.1l0,14.1l-3.4-1.1l-1.3,4.7l4.4,1.4c-3,5.3-6,11.5-8.8,18.9l4.4,2.8
                  				c16.9-39.2,32.8-47.9,51.5-42.1l10.2-1.5c1,1.8-0.7,6-4.7,7.3l-16.7-0.8l-3.9-3.8c-12.2,8.5-20.4,20.8-32.6,43.2l4.4,3
                  				c13.5-22,20.1-36.9,34-36.9l27.3,1.3l0,0.9c-0.2,4.1-3.7,5.4-7.8,5.2l-2.3-0.1l-4.8-3.9l-2.3,3.5l-14.6-0.7l-10.6,19.1
                  				c-5.2,4.1-10.2,9.1-14.8,14.3l4.4,3.4c11.2-13.5,24.7-21.8,34.4-21.4c7.9,0.4,18.6,11.9,23.5,17.9l-3.5,18.6l-3.9,0.2
                  				c-3.6,0.3-4.4-10.7-4.2-15.5l4.1-4.9l-3.7-3.4l-4,4.8l-11.6-0.6c0.6-2.5,1-5.1,1.2-7.7l-5.6-0.3c-0.7,14.6-9.7,26.9-20.9,36.2
                  				l-3.1,2.6l10.6,22.2l21.3,1c1.6,0.6,3.1,2,2.9,5.1l4.9,0.2c0.2-4.7-1.9-7.9-4.9-9.6c4.1-8.1,6.5-11.5,11.9-10.3l2.3,0.5
                  				c-0.5,3.7-1.2,7-2.2,9.8l0,0c0,0,0,0,0,0C-260.8-68.5-276.2-67.8-293.5-68.6z"/>
                  		</g>
                  	</g>
                  	<g>
                  		<path fill="#EBEBE1" d="M508.4,245.5l-8.4-10.9l-2.6,18.7l-14.2-2.2l3.1-17.6l-13.9,10.7l-8.6-11.2l20.3-15.3l-16-11
                  			c-4.1,4.5-8.8,8.7-14.3,12.9c-4.8,3.7-9.7,6.8-14.7,9.2l11,14.3l4.8-3.3l8.6,11.2l-13.3,10.2l-1.6,20.6l-13.4-1.4l0.9-12.2
                  			l-12.5,13l-10.2-8.8l10-10.1l-8,2.6l-5.4-12.4l24.1-8.1l-8.1-10.6c-27.5,7.2-55-5-77.6-34.4c-4.9-6.3-15-21.7-16.9-27.7
                  			c-3.8-11.9,5.6-29.8,22.3-42.7c16.9-13,36.2-17.3,47-10.6c5.5,3.5,16.3,15.9,22.2,23.6c2.1,2.7,3.9,5.1,5.6,7.6
                  			c1.6-0.9,3.9-2.3,7.2-4.8c4.4-3.4,13.5-10.4,23.6-51.6c7.7-31.5,20.5-46.1,43.2-63.5c4.5-3.4,10.3-2.6,13.8,2
                  			c3.6,4.7,3.1,9.9-1.6,13.4c-13,10-16,17.8-18,46.4l-2.4,34.8c-1.9,27.9-4.3,49.4-17.6,69.4l24.9,16.9l0.2,1l17.9,23.3L508.4,245.5z
                  			"/>
                  		<g>
                  			<path fill="#FF0000" d="M374.8,202.1c8.3-1.1,16.4-6,22.6-10.3l-3.9-5.1c-6.1,4.4-13.9,9.3-22.6,10.3L374.8,202.1z"/>
                  			<path fill="#FF0000" d="M504.4,32.4c-22.7,17.4-34.6,31.5-42,61.7c-9.3,38-17.8,48-24.8,53.4c-5.1,3.9-8,5.5-10.5,6.3
                  				c-1.9-3.4-4.1-6.3-6.8-9.8c-6.3-8.2-16.6-19.8-21.4-22.8c-9.5-6-27.5-1.6-43.2,10.4c-16.1,12.4-24.4,28.9-21.2,39
                  				c1.7,5.4,11.2,20.1,16.3,26.7c22.3,29,49.3,40.6,76.2,32.6l12.3,16.1l-25.1,8.4l2.7,6.3l18-5.8l-16.9,17.2l5.2,4.4l16.7-17.4
                  				l-1.4,18.2l6.8,0.7l1.5-18.7l11.9-9.1l-4.6-5.9l-9.3,7.1l-4.2-5.5l4.6-3.6l-11.5-15c6-2.5,11.9-6,17.8-10.6
                  				c6.2-4.8,11.4-9.7,15.8-14.7l22.2,15.3l-21.3,16l4.6,5.9l18.1-13.9l-4,22.7l7.6,1.2l3.2-23.2l11.2,14.5l5.9-4.6l-13.9-18.1
                  				l-5.9,4.6l-4.6-5.9l5.3-4.1L472,196.6c14.7-20.2,17.1-42.7,19-70.4l2.4-34.8c1.9-28.5,4.9-37.8,19.3-48.8c2.6-2,3.8-5,1-8.8
                  				C511,30.5,507.3,30.2,504.4,32.4z M388,134.9c3.9-2.3,7.7-4.1,9.5-4.5c1.6,2.6,0.8,7.4-1.8,12.2
                  				C394.9,139.7,391.8,135.8,388,134.9z M360.1,137.4c11.9-9.1,24.5-12.8,32.1-10.7c-7.9,2.9-18.2,9.1-27.7,16.4
                  				c-9.3,7.1-18.1,15.7-22.9,22.2C342,157.2,349.3,145.7,360.1,137.4z M382.3,137.5l4.1,5.3l-5.3,4.1l-4.1-5.3L382.3,137.5z
                  				 M367.3,149c-0.7,6.8,4.8,13.1,11.4,14c-11.2,8.2-29.6,14.3-35.2,8.9C345.7,167,361.1,153.8,367.3,149z M484.5,125.5
                  				c-0.6,27.7-5,48.9-18.6,66.9l-6-4.1c10.3-12.8,17.9-29.8,19.8-54.6l-5.4-1.1c-4.4,36.7-17.1,53-35.6,67.3l3.9,5.1
                  				c4.4-3.4,8.7-7.1,12.6-11.3l6.1,4.2c-4,4.5-8.7,8.8-14.2,13c-31.5,24.2-63.6,16.6-90.3-18.2c-2.9-3.7-5.8-7.5-8.2-11.6
                  				c10.7,1.2,25-4.8,35.4-12.8c10.6-8.1,19.7-20.4,21.3-30.7c8.2,8.4,19.8,21.2,23.3,37.1l7.2-2c-0.9-3.9-2.3-8-4.7-12.5
                  				c3.8-1.5,6.8-3.5,11.2-6.9c11.5-8.8,18-23.6,20.8-34.9C464.3,113.4,484.6,120.2,484.5,125.5z M486,114.2l-0.4,3.1
                  				c-3.4-6.2-16.9-8.7-20.3-6.8l0.8-3.1C467.5,102.5,486.9,106.8,486,114.2z M487.4,59.1c-4.1,7.4-8.2,30.8-9.8,41.5
                  				c-1.8-1.4-6.7-1.9-9-0.8l1-4.6c3.2-14,8.1-27.3,17.1-37L487.4,59.1z"/>
                  			<polygon fill="#FF0000" points="401.8,218.5 408.6,214.7 404.7,209.6 397.9,213.5 		"/>
                  			<path fill="#FF0000" d="M385,211.8c6.5-1.5,12.6-5.1,17.7-8.4l-3.7-4.8c-5,3.1-11.1,6.5-18.1,7.9L385,211.8z"/>
                  			<path fill="#FF0000" d="M364,192.2c9.9,0.1,20.3-6.5,27.1-11.7l-3.9-5.1c-6.8,5.2-16.1,11.4-27.1,11.7L364,192.2z"/>
                  		</g>
                  	</g>
                  	<g>
                  		<path fill="#EBEBE1" d="M155.7,244.7l-22.1-66.2c-1.9,27.5-19.7,42.5-32.2,53.1c-0.1,0.1-0.3,0.2-0.4,0.3l3.3,9.8l13.6,15.6
                  			l-75.3,25.1l-6-17.9l5.1-1.7l-1.5-4.5c-5.6,0.6-10.6-2.2-14.9-8.4c-3.8-5.5-7.3-13.5-12-27.7c-6.9-20.7-7.4-37.6-1.5-54.4
                  			c-2.3-4.9-6.9-15-10.7-26.5c-8.5-25.6,10-56,15.9-64.7l1.5-2.3L21,75c1.2,0.4,12.3,3.9,24,10.9c14.8,8.9,24.4,19.4,28.3,31.3
                  			c3.1,9.4,6,24.4,7.6,33.2l39-13l-9.5-28.5l-6.8,2.3l-1.9-50l28.1,41.3l-6.5,2.2l8,24c7.8-0.4,14.7,4.4,17.4,12.5
                  			c2.7,8.2,0,16.1-6.6,20.1l26.4,79.2L155.7,244.7z M78.1,266.8l-4.8-14.5l5.1-1.7l-1.4-4.2l-8.5,2.8l1.3,4L78.1,266.8z"/>
                  		<path fill="#FF0000" d="M129,132.3l-10-29.9l5.5-1.8l-19-27.9l1.3,33.8l5.7-1.9l11.7,34.9l-46,15.3c-1.3-7.7-4.6-26-8.1-36.6
                  			C61.3,91.8,24,79.5,20,78.3c-2.2,3.3-24.4,35.5-15.6,61.9c3.4,10.3,7.9,20.6,11.2,27.4c-6.6,17.8-5.4,34.2,1,53.6
                  			c7.8,23.4,14.4,37.1,25.9,33.2L46,265l-5.1,1.7l3.8,11.5l30.1-10l-8.3-13.3l-2.5-7.6l14.9-5l3.5,10.6l-5.1,1.7l3.8,11.5l30.8-10.3
                  			l-10.7-12.3l-4.2-12.6c14.7-12.6,39-30.9,32-70.9l1.6-0.5l27.1,81.1l6.4-2.1L138,159.4c7-2.6,10-10,7.5-17.3
                  			C143,134.5,136.3,130.6,129,132.3z M40.9,248c-3.4,1.1-7.7-1.5-9.7-7.7l6.4-2.1L40.9,248z M48.6,269.5l-1.2-3.7l5.3-1.8l-3.9-11.7
                  			l9-3l2.6,7.8l4.6,6.9L48.6,269.5z M84.9,257.4l-1.2-3.7l5.7-1.9l-4.5-13.6l6.5-3.4l3.9,11.7l5,5.7L84.9,257.4z M57.7,243.4
                  			c-0.2,0.1-11.3,3.8-11.3,3.8l-17.6-52.9l-5.3,1.8l12.3,37l-6.7,2.2c-7.1-16-15-38.2-9.2-59.8l5,6.5l4.4-3.8
                  			c-0.2-0.5-7.3-9.6-11.6-19.6c3.4,4.7,11.4,13.6,17.9,17l27.8-9.3c3.3-11.6,4.4-26.7,0.4-38.7c-6-17.9-19.8-25.8-35.5-20.6
                  			c-11.5,3.8-18.1,13.2-19,24.2c-2.2-18.9,9.2-39.1,13.2-45.3c7.9,3,35.3,14,42,34c4.2,12.6,6.6,29,8,43.8l39.3-13.1
                  			C123.7,224.5,77.5,236.8,57.7,243.4z M55.6,120.5c0.5,0.8,1.1,1.9,1.7,2.8c-19.8,1.8-25.4-8.7-26.4-11.1l3.2-0.3
                  			C35.1,114.3,40.3,122.1,55.6,120.5z M40,111.4c3.9,0.2,7.1,1.5,10.5,3.9C44.6,115.3,41.6,113,40,111.4z M41.3,127.4
                  			c-3.1,2.3-3.7,5.8-3.8,6.4l4.4,1.6c0.5-1.5,2.1-4.5,4.8-5.4l1.8-0.6l2.4,7.1l-1.8,0.6c-2.8,0.9-6-1.1-7.2-1.7l-2.5,3.9
                  			c0.5,0.6,6,4.1,11.3,2.4c5.3-1.8,7.6-7.9,7.8-8.7l-4.5-1.3l1.5-2.5l4.1-0.6c0.1,0.2,0.2,0.7,0.3,0.9c3.4,10.1,1.3,24.6-0.9,33.3
                  			L36.8,170c-4-2.8-13.3-10.6-17.4-19.2c1.4,0.3,2.7,0.4,4.5-0.2c5.5-1.8,7.8-8,8.3-8.9l-5.3-1.3l3.4-4.2c-0.6-0.3-4.3-2.9-8.2-3.1
                  			c5-6.5,4.8-13.1,4.4-16.5C28.6,119.3,33.1,124.5,41.3,127.4z M14.5,133.7c-0.4-6.5,2.2-12.5,7-16.4
                  			C21.8,120.3,21.6,127.3,14.5,133.7z M20,138.8l1.1-0.4l2.4,7.1l-1.1,0.4c-2.8,0.9-6.7-0.1-7.8-1.2C14.8,143.1,17.3,139.7,20,138.8z
                  			 M117.1,200.1l-1-0.7c3.1-10.7,5.4-24.5,3.7-36.5l4.4-1.5C126,177,123.2,189.2,117.1,200.1z M127.2,148.5l-4.6,1.5l1.5,4.6
                  			l-4.8,1.6l-2-8.3l8.7-2.9L127.2,148.5z M111,89l6.9,11.5l-5.5,1.8L111,89z M136,154c-4.8,1.6-6.8-1.3-8-5l4.8-1.6l-2.8-8.5
                  			c3.4-1.1,7.7,0,9.4,5.3C140.9,149,140.1,152.6,136,154z"/>
                  		<path fill="#FF0000" d="M37.9,159.5c0,0,3.2,2.2,6.4,1.1c2.7-0.9,4.2-4.6,4.2-4.6s-3.5-2.3-6.2-1.4C39.1,155.7,37.9,159.5,37.9,159.5z
                  			"/>
                  		<path fill="#FF0000" d="M58.4,206c-3.6,1.2-5.9,5.5-4.5,9.7c1.3,3.8,5.6,5.6,9.2,4.4c4-1.3,6.2-5.3,4.9-9.1
                  			C66.6,206.8,62.4,204.7,58.4,206z M61.7,216.1c-1.6,0.5-3.1-0.4-3.6-1.8c-0.5-1.6,0.1-3.3,1.7-3.8c1.8-0.6,3.6,0.3,4.1,1.9
                  			C64.4,213.7,63.5,215.5,61.7,216.1z"/>
                  		<polygon fill="#FF0000" points="32.3,136.7 33.5,153.3 44.7,149.5 43.2,145 37.3,147.3 37.3,136.1 	"/>
                  		<path fill="#FF0000" d="M50.8,183.4c-3.6,1.2-6.1,4.8-4.6,9.3c1.2,3.6,5.7,5.8,9.3,4.6c4-1.3,6.1-5.8,4.9-9.3
                  			C58.8,183.5,54.8,182,50.8,183.4z M54,193c-1.6,0.5-3.1-0.4-3.6-1.8c-0.6-1.8,0.1-3.3,1.7-3.8c1.8-0.6,3.5,0.1,4.1,1.9
                  			C56.7,190.6,55.8,192.4,54,193z"/>
                  	</g>
                  	<g>
                  		<path fill="#EBEBE1" d="M153,162.7c-2.6-4.9-0.6-9.8,3.2-15.6c1.1-1.6,3.2-5,3.3-6.1c-0.5-0.8-2.2-2.1-7.6-3.7
                  			c-7.2-2.3-15.1-5.5-18.7-12.4c-4.2-8.1,0.2-14.2,3.4-18.7c3.1-4.3,4.2-6.3,2.9-8.8c-2.5-4.8-9.5-8.9-16.3-13
                  			c-7.8-4.6-15.9-9.4-19.5-16.2c-3.6-6.9-0.7-15.6,6.4-19.3c5.6-2.9,10.6-2.1,14.9-1.4c1.3,0.2,2.5,0.4,3.7,0.5
                  			c0.8,0.1,1.6,0.1,2.3,0.2c5.8,0.5,11.8,1.1,22.6-1.5c0-7.2,2.2-14.3,6.6-20.6c4.4-6.3,10.7-11.7,18.3-15.7
                  			c12.7-6.6,23-5.4,27.6,3.3c3.1,6,0.2,10.9-3.4,15.3c9.1-1.2,17.9,0.1,25.7,3.7c9.1,4.2,16.7,11.6,21.6,20.8
                  			c5.1,9.7,6.6,20.2,4.3,30.4c-1.8,8.2-6.1,15.8-12.5,22.4c5-0.5,10.4,0,13.3,5.6c2.8,5.3,1.9,10.6-2.5,15.3
                  			c-3.2,3.4-7.6,5.7-10.3,7.1l-0.3,0.2c-14.6,7.6-31,8-42.5,1.2c-6.9,5.7-13.1,11.7-19.2,17.5c-3.5,3.3-7.1,6.8-10.7,10.1
                  			c-3.4,3.1-7.3,4.5-10.8,3.9C156.3,166.6,154.2,165.1,153,162.7z"/>
                  		<path fill="#FF0000" d="M154.8,114.3c0.4-0.5,0.7-0.9,1.1-1.3c0.7-0.9,1.5-1.7,2.3-2.5c3.3-3.3,7.2-6.2,12.4-8.9l0,0l-2.6-5l0,0
                  			c-5.5,2.9-9.6,5.9-13.1,9.3c-0.5,0.5-0.9,0.9-1.3,1.4c-3.3,3.5-6,7.3-8.6,11.5l0,0c0,0,0,0,0,0l4.8,3c0.6-1.1,1.3-2.1,1.9-3.1
                  			C152.7,117.1,153.7,115.6,154.8,114.3z"/>
                  		<path fill="#FF0000" d="M241.3,132.6c4.8-2.5,17-8.6,11.8-18.5c-4-7.6-15.3-2.4-20.4-2.4C254.8,95.4,257,73,248,55.7
                  			c-8.7-16.7-27.6-28.3-52.3-21.3c4.8-6.4,12.1-11.6,8.5-18.5c-3.9-7.6-13.2-7.4-23.6-2c-13.7,7.2-24.4,20.2-23.2,35.9
                  			c-14.3,3.7-21.1,2.5-28.3,1.9c-5.5-0.5-10.7-2.7-17,0.6c-5.8,3-8,9.9-5.2,15.3c6.2,11.9,29.9,17.9,35.8,29.2
                  			c4.8,9.3-12.6,15.4-6.3,27.5c3.2,6.1,10.7,9,17,10.9c5.6,1.8,8.3,3.3,9.4,5.3c0.9,1.7-0.4,4-3.6,9c-3.8,5.8-4.7,9.3-3.1,12.6
                  			c2,3.9,7.4,4.1,12.2-0.3c10.3-9.4,19.9-19.7,31.6-29.1C211.4,140.8,228,139.5,241.3,132.6z M162.9,158.3l-0.8-1.5
                  			c3.7-7.7,13.8-21.5,23.2-27.2l-2.8-4.9c-0.3,0.4-6.8,4.4-14,12.8c-2.1-4.1-5.5-5.9-13.1-8.3c-5.3-1.7-11.1-4.4-13-8.1
                  			c-2.9-5.6,12.4-14.7,5.9-27.2c-6.8-13-30.4-19.9-35.4-29.4c-1.4-2.6-0.1-5.4,2.1-6.6c2.6-1.4,5.7-0.7,12.1,0
                  			c8.3,1.1,16.1,2.2,31.3-2.2c0.4,1.4,0.8,2.7,1.3,4.1c-12.6,3.5-26.1,3.2-42-0.3l-1,5.5c22.8,5.2,41.5,3.7,59.2-5.6l-2.6-5
                  			c-2.7,1.4-5.4,2.6-8.1,3.6c-6.5-16.7,4.3-31,18.3-38.3c8.4-4.4,12.9-3.2,14.3-0.6c2.7,5.2-18.8,15.6-9.5,33.5l5.2-2.7
                  			c-1.4-2.6-1.8-4.9-1.7-7.1c21.1-8.4,40.5-2.4,50,15.9c10.5,20.1,2,41.3-22,53.8c-21,10.9-40.3,30.3-50.1,39.3L162.9,158.3z
                  			 M205.7,128.4c5.1-3.7,10.7-7.3,16.9-10.5c0.8-0.4,1.5-0.8,2.2-1.2c9.9,3.3,20.4-3.8,22.6,0.4c1.8,3.5-2.7,6.4-9.2,9.7
                  			C228.5,131.8,215.2,134.2,205.7,128.4z"/>
                  		<path fill="#FF0000" d="M209.3,66.9c-0.1,0.7-0.2,1.5-0.2,2.2c0,1.7,0.5,3.4,1.3,5l0,0l0,0c0.4,0.7,0.8,1.3,1.3,1.9
                  			c0.2,0.3,0.5,0.6,0.8,0.8c3.4,3.4,9,4.5,13.2,2.3c4.7-2.5,6.8-7.6,6-12.3c-0.1-0.4-0.2-0.7-0.3-1.1c-0.2-0.7-0.5-1.4-0.8-2.1l0,0
                  			l0,0c-0.9-1.6-2-3-3.3-4c-0.6-0.5-1.2-0.8-1.9-1.2c-3.1-1.5-6.8-1.5-10.3,0.3C212,60.4,209.9,63.5,209.3,66.9z M224.1,70.3
                  			l-4.5,2.4l-2.4-4.5l4.5-2.4L224.1,70.3z"/>
                  		<polygon fill="#FF0000" points="225.6,100.6 225.6,100.6 233.8,96.3 233.8,96.3 229.6,88.1 229.6,88.1 221.3,92.4 221.3,92.4 	"/>
                  		<path fill="#FF0000" d="M200.2,63L200.2,63l-4.5,2.4c-0.9,2.6-1.4,7.8-1.1,12.6c0,0.6,0.1,1.3,0.1,1.9c0.1,1.4,0.3,2.8,0.6,3.9
                  			l-2.8,2.6l-2.8,2.6c-4.5,4.1-9.4,8.5-11.9,13.2c-1.3,2.4-1.9,4.8-1.7,7.3c0.1,1.4,0.5,2.8,1.3,4.3l0,0l0,0c1.1,2,2.5,3.5,4.3,4.6
                  			c1.3,0.7,2.7,1.2,4.2,1.5c8.5,1.5,20.2-4,26.9-11.6l2.7,0.5l2.7,0.5l4.8-2.5l0,0L200.2,63z M182.3,105.8c0.2-0.7,0.5-1.4,0.9-2.1
                  			c0.2-0.4,0.4-0.7,0.6-1.1c0.2-0.4,0.5-0.7,0.7-1.1c0.1-0.2,0.3-0.4,0.4-0.5c1.6-2.1,3.7-4.2,5.8-6.2c1.5-1.3,3.2-2.7,4.8-4.2
                  			c0.5-0.5,1-1,1.5-1.5c3.3-3.3,5.9-7.1,5.4-11.4l4.3,8.2c1.5,2.8-5.5,11.6-17.1,17.7l0,0l0,0l2.6,5l0,0l0,0
                  			c11.7-6.1,17.2-12.8,17.7-16.6l2.7,5.2c0.3,1.3,0.1,2.7-0.6,4.1c-0.2,0.3-0.3,0.6-0.5,1c-0.2,0.3-0.4,0.6-0.6,1
                  			c-0.1,0.2-0.2,0.3-0.4,0.5c-5.7,7.2-20.4,13.9-26.3,9.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.5-0.7-0.7
                  			c-0.2-0.3-0.4-0.6-0.6-0.9c-0.7-1.4-0.9-2.9-0.5-4.4C182.1,106.5,182.2,106.1,182.3,105.8z"/>
                  	</g>
                  	<g>
                  		<path fill="#EBEBE1" d="M458.8,49.5l-12.2,0.2l0.2,15.7l12.1-3.1l0.6,41.1l-20.2,0.3c0,0-42.7-10.2-74.3-17.7c2.7,1.6,4.8,3.6,6.2,5
                  			l0,0l2.7,2.6l-1.4,1.1c3,1.5,5.8,3.7,8.3,6.6l1.9,2.2l-2,2.3c-3.2,3.8-8,9.5-16.7,9.1c-3,3.4-7.3,5.2-12.5,5.3l-3.3,0l-0.1-3.6
                  			l-73.4,18.9c-2,6.6-8,11-15.5,11.1l-3.3,0l-0.2-12.2l3.3,0c0.4,0,4.3-0.2,4.3-4.1c0-1.1,0-3-4.4-2.9l-3.3,0l-0.2-12.2l3.3,0
                  			c6.9-0.1,12.5,3,15.2,8.1l66.7-17.4l-29.3-28.9c-5.3-5.3-7.1-10.2-8.4-13.5c-0.2-0.6-0.5-1.3-0.7-1.9c-1.3,1.2-2.8,2.4-4.6,3.5
                  			c0.3,7.7,0.7,10,4.9,23.8l2.1,6.9l-6.6-2.8c-4.9-2.1-12.4-6.2-17.5-13.4l-0.4,2.6l-9,0.1l-0.9-2c-1.8-3.9-3.4-7.5-4.4-11.9
                  			l-5.9,0.1c-2.3,0-5.5-1.2-7.5-7.4c-1-3.3-1.6-7.7-1.7-12.4c-0.1-5.1,0.5-9.8,1.5-13.5c2-6.7,5.3-8.2,7.8-8.2l6.7-0.1
                  			c1.3-4.7,3.3-9.4,6.1-14.1l1-1.6l9.1-0.1l7.1,12.2c2.3-1.9,3.6-4,5-6.3c3.1-5,6.7-10.7,19.4-10.9l104.6-1.5
                  			c9.6-0.1,16.6,3.5,18.9,9.5l20.2-5.8L458.8,49.5z"/>
                  		<path fill="#FF0000" d="M428.8,22.6l0,1.6l-64.5,1l0.1,5.5l65-1l0.1,4c5-0.1,7.2,1.5,7.2,6l-66.8,1l-0.1-9
                  			c-11.9,1.5-24.4,12.5-29,20.8l3.2,3.9l4.2-1.1c8.1,8.9,19.2,10.3,25.8,10.2l-0.1-5c-6.3,0.1-17.2-1.3-23.7-11l1.6-1.6
                  			c5.1,6.8,12,9.9,22.1,9.7l-0.1-5c-8.5,0.1-14.6-3-18.6-8.4l1.8-1.6c4.9,6.5,12.5,7.5,16.8,7.4l-0.1-3.7l62.6-0.9l0.7,47.5
                  			c-11.4-2.7-86.7-20.7-104.5-24.9c-8.8-2.2-10.6-4.3-13-6.9c-2.7-3.1-5.6-6.3-15.2-9.8c0.2-1.1,0.5-1.9,0.5-2.9
                  			c-0.1-6.9-4.7-12.6-12.6-15.7c11-3.6,10.1-17.1,19.6-17.2c-3.9,2.7-7.5,8-7.5,12.5c0.1,10,11.3,16.5,28,16.2l2.6,0l0-2.6
                  			c0-1.1,0.6-10.3,9.6-10.4l-0.1-5.5c-10,0.1-13.9,7.6-14.9,13.2c-9.8-0.4-18.8-3.7-18.9-10.8c-0.1-6.1,8.8-11.5,19.1-11.6l89.5-1.3
                  			C427.7,14.7,428.8,18.9,428.8,22.6z M448.4,39.2l-4.8,0.1c-0.3-5.8-2.8-12.1-13.1-12.5l17.6-5L448.4,39.2z M360.7,40.2l5.2-3.2
                  			l0.1,6.1L360.7,40.2z M438.4,44.4l-0.1-4l4.5-0.1l0.1,4L438.4,44.4z M448.9,75.7l0.3,17.4l-5.3,0.1l-0.2-16.4L448.9,75.7z
                  			 M337.2,81.5l3.2,4.4c4.7-3.5,10-3.1,13.2,0.9c-2.6,0.3-5,1.4-7.3,3.3l3.5,4.4c4.4-4,9.5-2.8,13.5,1.1c-5.3-0.2-10.3,1.2-14.5,5
                  			c-6.7-6.5-34.8-33-36.2-35.4l1.6-0.8c3,3.1,5.9,7.6,16.5,10.1c3.4,0.7,8.7,2,15.1,3.5C343,78.3,340.1,79.4,337.2,81.5z
                  			 M371.4,103.8c-1.6,1.9-5,3.2-7.6,2.8l-0.1-5C365.8,101.5,369.8,102.5,371.4,103.8z M349,107c1.3-1.6,5.5-4.8,10.2-5.4
                  			c0.1,6.9-2.5,9-5.4,9.9L349,107z M291.8,79.6c-5.9-5.2-9.5-14.9-9.6-21.3l-5.5,0.1l0.1,5.5l-1.8,0c-1.9-5.8-1.8-14.2-1.1-21.4
                  			c8.5,0.4,17.2,4,17.3,9.5c0,2.1-1.3,4.2-2.8,4.3C288.4,64.8,289.6,73.8,291.8,79.6z M262.7,58.3c-1.6-3.7-3.9-12.4,0.5-21.1
                  			l5.5-0.1c-1.2,7.7-1.1,15.1-0.2,21.1L262.7,58.3z M282.3,30.3l-5.8,0.1l2.3-7.4L282.3,30.3z M294.3,56.8c1.3-1.3,2.3-2.7,2.3-4.8
                  			c-0.1-8.2-8.7-13.3-21.9-14.2l0.5-3.2c13.5-0.7,23.9,4.9,24,13.6C299.3,52.5,297.5,56,294.3,56.8z M314.5,9.5
                  			c-18.2,0.3-13.6,12.1-25.6,18.6L281,14.5l-5.3,0.1c-3.1,5.3-5.1,10.4-6.4,15.7l-9.2,0.1c-4.2,0.1-6.2,9.3-6.1,18.3
                  			c0.1,8.5,2.1,16.6,5.8,16.6l8.7-0.1c0.9,5.3,2.5,9.2,4.7,13.9l4-0.1l1.7-10.9c2.5,11.3,11.9,18.3,20.4,21.9
                  			c-4.7-15.3-4.7-17.1-5.1-26.6c2.9-1.6,5.5-3.5,7.3-5.9c5.5-0.1,2.8,7.9,12.1,17.3l33.5,33.1l-75.3,19.6c-1.4-5.5-6.5-8.9-13.1-8.8
                  			l0.1,5.5c2.4,0,7.7,0.4,7.8,6.2c0.1,5-3.9,7.5-7.6,7.5l0.1,5.5c6.9-0.1,11.8-4.4,12.8-10.5l79.5-20.5l0.1,4.5c5-0.1,8.7-2,11-5.4
                  			c8.2,1.2,12.4-3.9,15.7-7.9c-4-4.7-8.6-7-12.8-7.5l3.4-2.7c-0.3-0.3-5.1-6-11.7-6.7l2.4-1.9c0,0-2.2-2.9-5.6-4.9
                  			c32.6,7.7,83,19.6,83,19.6l1.3-4.8l4.5-0.1l0.1,5.5l13.5-0.2l-0.5-33.5l-12.1,3.1l-0.3-23.2l12.2-0.2l-0.5-33.5l-19.5,5.6
                  			c-0.1-6.9-7.6-10.5-16.3-10.3L314.5,9.5z M276.5,47.6l0.1,5.8l5.8-0.1l-0.1-5.8L276.5,47.6z M336.2,46.4l4.3,2.6
                  			c5.6-12,16.9-18.5,17.2-18.7l-3-4.7C354.1,25.8,342.6,33.1,336.2,46.4z"/>
                  	</g>
                  </svg>
                </span>
                <span style={{display: 'inline-block', width: '34.2%', padding: '0 7px', color: '#29a349', transition: 'all 180ms'}}>
                  <svg style={{position: 'relative', width: '100%'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                  	<g>
                  		<path d="M115.4,79.6V148c6.2-1.1,12.3-1.7,19-1.7c77.8,0,140.6,57.1,140.6,135s-62.7,134.4-140.6,134.4S0,359.1,0,281.2h72.8
                  		c0,34.7,28,62.7,62.7,62.7s62.7-28,62.7-62.7s-28-62.7-62.7-62.7c-6.2,0-20.7,0-25.8,0.6v-67.2H44.2V8.5H243v71.1H115.4z"/>
                  	</g>
                  	<path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                  </svg>
                </span>
              </div>
              <span className="calendar-item-red--narrow" style={{paddingLeft: '30px'}}>
                <span style={{position: 'relative', paddingLeft: '30px', display: 'inline-block', verticalAlign: 'top', width: '100%', color: '#ff0000', textAlign: 'center'}} className="mercury">
                  We resolve to add ten more emojis to our <a href="https://telegram.me/addstickers/libertevisualite" style={{color: '#b4966e'}}>Telegram sticker</a> set.
                </span>
              </span>
            </div>
          </div>

          <div className="calendar-item-red">
            <div className="calendar-item-red--wide">
              <span style={{display: 'inline-block', width: '34.2%', paddingRight: '15px', color: '#29a349', transition: 'all 180ms'}} >
                <svg style={{position: 'relative', width: '100%'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                  <g>
                  	<path d="M95.8,8.5h90.2L98.6,151.3c10.6-3.4,23-5,35.8-5c77.8,0,140.6,57.1,140.6,135s-62.7,134.4-140.6,134.4S0,359.1,0,281.2
                  		c0-58.8,35.3-111.4,86.8-127.1H8.4L95.8,8.5z M134.4,343.9c33.6,0,63.8-24.1,63.8-62.7s-30.2-62.7-63.8-62.7
                  		c-34.2,0-61.6,24.1-61.6,62.7S100.2,343.9,134.4,343.9z"/>
                  </g>
                  <path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                </svg>
              </span>
              <img src={year04} alt="" style={{position: 'absolute', display: 'block', width: '65.8%', top: '50%', transform: 'translateY(-50%)', right: '0'}} />
            </div>
            <span className="calendar-item-red--narrow">
              <span style={{position: 'relative', paddingLeft: '30px', display: 'inline-block', verticalAlign: 'top', width: '100%', color: '#ff0000', textAlign: 'center'}} className="mercury">
                We resolve to finally learn how to play chess
              </span>
            </span>
          </div>

          <div className="calendar-item-red" style={{padding: '0'}}>
            <div className="calendar-item-red--wide">
              <span style={{position: 'relative', width: '65.8%', display: 'inline-block'}}>
                <img src={year05} alt="" style={{display: 'block', width: '100%', position: 'relative'}}/>
              </span>
              <span style={{display: 'inline-block', width: '34.2%', padding: '0 7px', color: '#29a349', transition: 'all 180ms'}}>
                <svg style={{position: 'relative', width: '100%'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                  <g>
                		<path d="M118.4,170.3h93L245,79.6H110V8.5h239.7v8.4L208,406.1h-81.8l58.8-163h-66.6V170.3z"/>
                	</g>
                	<path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                </svg>
              </span>
            </div>
            <span className="calendar-item-red--narrow" style={{paddingLeft: '30px'}}>
              <span style={{position: 'relative', paddingLeft: '30px', display: 'inline-block', verticalAlign: 'top', width: '100%', color: '#ff0000', textAlign: 'center'}} className="mercury">
                We resolve to work with a great sports brand and become regular sneakerheads.
              </span>
            </span>
          </div>
        </div>

        <div className="portfolio at-main is-hidden--desk Fz(0) Mx(a)">
          <div className="calendar-item-mob-red">
            <div style={{position: 'relative', transform: 'translateX(-30px)', overflow: 'hidden', width: 'calc(100% + 58px)'}}>
              <div style={{display: 'flex', overflow: 'scroll', height: '100%', marginBottom: '-30px'}} className="calendar-item-mob-red--scroll">
                <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignContent: 'space-between', justifyContent: 'space-between', paddingBottom: '30px'}}>
                  <div style={{paddingLeft: '30px', display: 'inline-block', position: 'relative'}}>
                    <div style={{color: '#29a349', height: '100%', position: 'relative', display: 'block', width: '44.5vw'}}>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                      	<g>
                      		<path d="M0,406.8V334h89.6V150.2H21.3v-12.3L154.6,8.5h10.6V334h89.6v72.8H0z"/>
                      	</g>
                      	<path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                      </svg>
                    </div>
                  </div>
                  <div style={{color: '#ff0000', paddingLeft: '20px', paddingRight: '20px', display: 'inline-block', position: 'relative'}}>
                    <svg style={{position: 'relative', height: '46.2vw', width: 'auto'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2277.44 898.47">
                      <polygon fill="#fc0d1b" points="119.14 351.51 119.14 153.3 55.64 153.3 55.64 325.2 10.74 370.11 55.64 415.01 119.14 351.51"/>
                      <polygon fill="#fc0d1b" points="173.34 153.3 119.14 153.3 173.34 370.11 173.34 153.3"/>
                      <polygon fill="#fc0d1b" points="263.48 198.2 298.56 163.12 347.64 192.57 263.48 108.4 199.97 171.9 199.97 370.11 263.48 370.11 263.48 198.2"/>
                      <polygon fill="#fc0d1b" points="1204.03 153.3 1149.83 370.11 1204.03 370.11 1204.03 153.3"/>
                      <polygon fill="#fc0d1b" points="1086.33 198.2 1086.33 370.11 1149.83 370.11 1149.83 171.9 1107.37 129.44 1086.33 108.4 1002.16 192.57 1051.24 163.12 1086.33 198.2"/>
                      <polygon fill="#fc0d1b" points="1230.65 471.22 1194.39 507.48 1151.26 481.61 1230.66 561.01 1230.66 561.01 1230.66 561.01 1293.99 497.68 1294.15 497.68 1294.15 497.52 1294.16 497.51 1294.15 497.5 1294.16 153.3 1230.66 153.3 1230.65 471.22"/>
                      <polygon fill="#fc0d1b" points="411.14 415.01 411.14 415.01 574.24 251.91 426.5 340.56 411.14 325.2 411.14 153.3 347.64 153.3 347.64 351.51 411.14 415.01"/>
                      <polygon fill="#fc0d1b" points="413.52 283.52 477.44 219.6 522.34 174.7 495.13 147.49 456.04 108.4 411.14 153.3 461.46 203.62 413.52 283.52"/>
                      <polygon fill="#fc0d1b" points="1374.98 415.01 1488.75 301.18 1402.63 352.85 1374.98 325.2 1374.98 153.3 1311.48 153.3 1311.48 351.51 1374.98 415.01"/>
                      <polygon fill="#fc0d1b" points="1377.36 283.52 1441.28 219.6 1486.18 174.7 1458.97 147.49 1419.88 108.4 1374.98 153.3 1425.3 203.62 1377.36 283.52"/>
                      <polygon fill="#fc0d1b" points="599.7 108.4 515.53 192.57 564.61 163.12 599.7 198.2 599.7 370.11 663.2 370.11 663.2 171.9 599.7 108.4"/>
                      <polygon fill="#fc0d1b" points="915.93 153.3 852.43 153.3 852.43 370.11 915.93 306.61 915.93 153.3"/>
                      <polygon fill="#fc0d1b" points="807.53 153.41 744.03 153.41 744.03 351.51 807.53 415.01 852.43 370.11 807.53 325.2 807.53 153.41"/>
                      <polygon fill="#fc0d1b" points="689.83 153.3 689.83 370.11 744.03 153.3 689.83 153.3"/>
                      <polygon fill="#fc0d1b" points="1673.89 343.8 1673.89 171.9 1673.86 171.9 1610.38 108.4 1565.48 153.3 1501.99 153.3 1501.99 351.51 1565.49 415.01 1610.39 370.11 1565.49 325.2 1565.49 153.32 1610.39 198.23 1610.39 370.11 1655.29 415.05 1739.45 330.89 1690.4 360.32 1673.89 343.8"/>
                      <polygon fill="#fc0d1b" points="2069.11 369.5 2069.11 370.11 2132.61 370.11 2132.61 261.7 2069.11 261.7 2069.11 369.5"/>
                      <rect fill="#fc0d1b" x="2005.61" y="153.3" width="63.5" height="108.4"/>
                      <polygon fill="#fc0d1b" points="2125.59 209.8 2096.14 258.89 2180.31 174.72 2180.3 174.71 2180.31 174.7 2114.01 108.4 2069.11 153.3 2125.59 209.8"/>
                      <polygon fill="#fc0d1b" points="2012.63 313.02 2042.08 263.94 1957.92 348.11 2021.42 411.61 2024.21 414.4 2069.11 369.5 2012.63 313.02"/>
                      <polygon fill="#fc0d1b" points="1979.31 66.32 2014.4 101.4 1984.95 150.48 2024.21 111.22 2069.11 66.32 2069.1 66.31 2069.11 66.3 2002.82 0 1957.92 44.9 1979.32 66.3 1979.31 66.32"/>
                      <polygon fill="#fc0d1b" points="1810.72 808.62 1765.82 853.52 1810.72 898.43 1874.22 834.93 1874.22 636.72 1810.72 636.72 1810.72 808.62"/>
                      <polygon fill="#fc0d1b" points="1928.42 853.52 1928.42 636.72 1874.22 636.72 1928.42 853.52"/>
                      <polygon fill="#fc0d1b" points="2102.72 675.99 2018.55 591.82 1955.05 655.32 1955.05 853.52 2018.55 853.52 2018.55 681.62 2053.64 646.54 2102.72 675.99"/>
                      <polygon fill="#fc0d1b" points="445.06 784.6 358.94 836.27 331.29 808.62 331.29 636.72 267.79 636.72 267.79 834.93 331.26 898.39 331.29 898.43 445.06 784.6"/>
                      <polygon fill="#fc0d1b" points="333.67 766.94 442.49 658.12 376.19 591.82 331.29 636.72 381.61 687.04 333.67 766.94"/>
                      <polygon fill="#fc0d1b" points="1101.31 853.52 1101.31 636.72 1047.11 853.52 1101.31 853.52"/>
                      <polygon fill="#fc0d1b" points="1047.11 681.62 1047.11 655.32 983.61 591.82 899.45 675.99 948.53 646.54 983.61 681.62 983.61 853.52 1047.11 853.52 1047.11 681.62"/>
                      <polygon fill="#fc0d1b" points="1184.19 808.62 1184.19 636.72 1120.69 636.72 1120.69 834.93 1184.19 898.43 1268.36 814.26 1219.28 843.71 1184.19 808.62"/>
                      <polygon fill="#fc0d1b" points="702.27 636.72 638.77 636.72 638.77 834.93 702.27 898.43 747.18 853.52 702.27 808.62 702.27 636.72"/>
                      <polygon fill="#fc0d1b" points="702.27 636.72 747.18 681.62 747.18 853.52 810.68 853.52 810.68 655.32 747.18 591.82 702.27 636.72"/>
                      <polygon fill="#fc0d1b" points="898.41 827.22 898.41 445.82 834.91 509.47 834.91 853.52 879.81 898.47 963.98 814.31 914.92 843.74 898.41 827.22"/>
                      <polygon fill="#fc0d1b" points="572.48 591.82 527.58 636.72 584.06 693.22 554.61 742.3 638.77 658.14 638.76 658.13 638.77 658.12 572.48 591.82"/>
                      <polygon fill="#fc0d1b" points="527.58 852.92 527.58 853.52 591.08 853.52 591.08 745.12 527.58 745.12 527.58 852.92"/>
                      <rect fill="#fc0d1b" x="464.08" y="636.72" width="63.5" height="108.4"/>
                      <polygon fill="#fc0d1b" points="471.1 796.45 500.55 747.36 416.38 831.53 479.88 895.03 482.68 897.83 527.58 852.92 471.1 796.45"/>
                      <polygon fill="#fc0d1b" points="2277.44 658.12 2211.15 591.82 2166.24 636.72 2222.72 693.22 2193.28 742.3 2232.54 703.04 2277.44 658.14 2277.43 658.13 2277.44 658.12"/>
                      <polygon fill="#fc0d1b" points="2166.24 852.92 2166.24 853.52 2229.74 853.52 2229.74 745.12 2166.24 745.12 2166.24 852.92"/>
                      <rect fill="#fc0d1b" x="2102.75" y="636.72" width="63.5" height="108.4"/>
                      <polygon fill="#fc0d1b" points="2109.77 796.45 2139.22 747.36 2055.05 831.53 2118.55 895.03 2121.34 897.83 2166.24 852.92 2109.77 796.45"/>
                      <polygon fill="#fc0d1b" points="1440.26 853.52 1440.26 636.72 1386.06 636.72 1440.26 853.52"/>
                      <rect fill="#fc0d1b" x="1322.56" y="636.72" width="63.5" height="216.8"/>
                      <polygon fill="#fc0d1b" points="1268.36 853.52 1322.56 636.72 1268.36 636.72 1268.36 853.52"/>
                      <polygon fill="#fc0d1b" points="129.06 827.25 129.06 636.75 84.17 591.8 0 675.97 49.08 646.52 65.56 663 65.56 663.05 65.56 853.55 110.45 898.45 110.45 898.45 110.45 898.45 194.62 814.28 145.54 843.73 129.06 827.25"/>
                      <polygon fill="#fc0d1b" points="173.96 591.8 129.06 636.7 192.55 700.2 192.55 700.2 192.6 700.25 276.77 616.08 227.68 645.53 173.96 591.8"/>
                      <polygon fill="#fc0d1b" points="1657.42 636.72 1593.92 636.72 1593.92 834.93 1657.42 898.43 1702.32 853.52 1657.42 808.62 1657.42 636.72"/>
                      <polygon fill="#fc0d1b" points="1702.32 591.82 1657.42 636.72 1702.32 681.62 1702.32 853.52 1765.82 853.52 1765.82 655.32 1702.32 591.82"/>
                      <rect fill="#fc0d1b" x="1651.63" y="585.33" width="63.5" height="63.5" transform="translate(-136.64 1282.31) rotate(-45)"/>
                      <polygon fill="#fc0d1b" points="1556.87 843.71 1521.79 808.62 1521.79 636.72 1458.29 636.72 1458.29 834.93 1521.79 898.43 1605.95 814.26 1556.87 843.71"/>
                      <polygon fill="#fc0d1b" points="1822.27 343.85 1822.27 153.35 1777.38 108.4 1693.2 192.57 1742.29 163.12 1758.77 179.6 1758.77 179.65 1758.77 370.15 1803.65 415.05 1803.65 415.05 1803.65 415.05 1887.83 330.88 1838.74 360.33 1822.27 343.85"/>
                      <polygon fill="#fc0d1b" points="1867.16 108.4 1822.27 153.3 1885.76 216.8 1885.76 216.8 1885.81 216.85 1969.97 132.68 1920.88 162.13 1867.16 108.4"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <span style={{display: 'block', color: '#ff0000', paddingTop: '10px'}} className="mercury">
              We resolve to update our <Link to="/work" style={{color: '#b4966e'}}>projects list</Link> more often, and start with a duck.
            </span>
          </div>

          <div className="calendar-item-mob-red">
            <div style={{position: 'relative', transform: 'translateX(-30px)', overflow: 'hidden', width: 'calc(100% + 58px)'}}>
              <div className="red-edition-scroll calendar-item-mob-red--scroll" style={{display: 'flex', overflow: 'scroll', height: '100%', marginBottom: '-30px'}}>
                <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignContent: 'space-between', justifyContent: 'space-between', paddingBottom: '30px'}}>
                  <div style={{paddingLeft: '30px', paddingRight: '20px', display: 'inline-block', position: 'relative'}}>
                    <img src={year02} alt="" style={{position: 'relative', height: '46.2vw', width: 'auto'}} />
                  </div>
                  <div style={{paddingRight: '20px', display: 'inline-block', position: 'relative'}}>
                    <div style={{color: '#29a349', height: '100%', position: 'relative', display: 'block', width: '44.5vw'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                        <g>
                        	<path d="M96.9,267.1V336h169.3v71.1H25.8V259.8h71.1v-63.3c5,0.6,32.5,0.6,38.6,0.6c34.7,0,62.7-28,62.7-62.7s-28-62.7-62.7-62.7
                        		c-34.7,0-62.7,28-62.7,62.7H0C0,56.6,56.6,0,134.4,0S275,56.6,275,134.4s-62.7,135-140.6,135C128.2,269.4,102.5,268.2,96.9,267.1z"
                        		/>
                        </g>
                        <path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span style={{display: 'block', color: '#ff0000', paddingTop: '10px'}} className="mercury">
              We will restart the <Link to="/" style={{color: '#b4966e'}}>mailing list</Link>. Long live Arthur, the king of branding.
            </span>
          </div>

          <div className="calendar-item-mob-red">
            <div style={{position: 'relative', transform: 'translateX(-30px)', overflow: 'hidden', width: 'calc(100% + 58px)'}}>
              <div style={{display: 'flex', overflow: 'scroll', height: '100%', marginBottom: '-30px'}} className="calendar-item-mob-red--scroll">
                <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignContent: 'space-between', justifyContent: 'space-between', paddingBottom: '30px'}}>
                  <div style={{paddingLeft: '30px', display: 'inline-block', position: 'relative'}}>
                    <div style={{color: '#29a349', height: '100%', position: 'relative', display: 'block', width: '44.5vw'}}>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                      	<g>
                      		<path d="M70,218.5l70-138.9H29.7V8.5h219v12.9l-70.6,144.5c56,11.2,91.3,61.6,91.3,115.4c0,74.5-60.5,134.4-135,134.4
                      		C59.9,415.6,0,355.7,0,281.2h72.8c0,34.7,28,62.7,62.7,62.7s62.7-28,62.7-62.7s-28-62.7-62.7-62.7c-21.8,0-44.2,2.8-56.6,9
                      		L70,218.5z"/>
                      	</g>
                      	<path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                      </svg>
                    </div>
                  </div>
                  <div style={{paddingLeft: '20px', paddingRight: '20px', display: 'inline-block', position: 'relative'}}>
                    <img src={year03} alt="" style={{position: 'relative', height: '46.2vw', width: 'auto'}} />
                    <svg style={{position: 'absolute', display: 'block', top: '20%', width: '25%', right: '31%'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218.11 218.11">
                      <title>Artboard 29</title>
                      <circle cx="109.06" cy="109.06" r="109.06" fill="#3aa74b"/>
                      <polygon points="109.06 8.59 114.31 59.1 129.94 10.79 124.58 61.28 149.92 17.28 134.17 65.55 168.11 27.78 142.67 71.73 183.71 41.83 149.69 79.53 196.06 58.82 154.94 88.62 204.6 78.01 158.19 98.61 208.97 98.55 159.29 109.06 208.97 119.56 158.19 119.5 204.6 140.1 154.94 129.49 196.06 159.29 149.69 138.58 183.71 176.28 142.67 146.38 168.11 190.33 134.17 152.56 149.92 200.83 124.58 156.83 129.94 207.32 114.31 159.01 109.06 209.52 103.81 159.01 88.17 207.32 93.53 156.83 68.19 200.83 83.94 152.56 50.01 190.33 75.44 146.38 34.4 176.28 68.42 138.58 22.05 159.29 63.17 129.49 13.51 140.1 59.92 119.5 9.14 119.56 58.82 109.06 9.14 98.55 59.92 98.61 13.51 78.01 63.17 88.62 22.05 58.82 68.42 79.53 34.4 41.83 75.44 71.73 50.01 27.78 83.94 65.55 68.19 17.28 93.53 61.28 88.17 10.79 103.81 59.1 109.06 8.59" fill="#ff558a"/>
                      <circle cx="109.06" cy="109.06" r="50" fill="#353130"/>
                      <circle cx="133.08" cy="86.03" r="16.86" fill="#fff"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <span style={{display: 'block', color: '#ff0000', paddingTop: '10px'}} className="mercury">
              We resolve to become more popular and gain 10k <a href="https://www.instagram.com/shukagram/" style={{color: '#b4966e'}}>Instagram</a> followers.
            </span>
          </div>

          <div className="calendar-item-mob-red">
            <div style={{position: 'relative', transform: 'translateX(-30px)', overflow: 'hidden', width: 'calc(100% + 58px)'}}>
              <div className="red-edition-scroll calendar-item-mob-red--scroll" style={{display: 'flex', overflow: 'scroll', height: '100%', marginBottom: '-30px'}}>
                <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignContent: 'space-between', justifyContent: 'space-between', paddingBottom: '30px'}}>
                  <div style={{paddingLeft: '30px', paddingRight: '30px', display: 'inline-block', position: 'relative'}}>
                    <svg style={{position: 'relative', height: '46.2vw', width: 'auto'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 527.9 283.8">
                      <title>Artboard 1</title>
                      <rect width="491" height="246.9" fill="#b4966e"/>
                      <polygon points="491 0 527.9 36.9 527.9 283.8 491 246.9 491 0" fill="#3aa74b"/>
                      <polygon points="0 246.9 491 246.9 527.9 283.8 36.9 283.8 0 246.9" fill="#ff558a"/>
                      <ellipse cx="307.14" cy="123.43" rx="62.65" ry="49.93" fill="#353130"/>
                      <path d="M299.53,100h-7.94a6.82,6.82,0,0,1-6.86-6.78V80.36a6.83,6.83,0,0,1,6.8-6.86h8a6.83,6.83,0,0,1,6.85,6.81V93.19a6.82,6.82,0,0,1-6.83,6.81Z" fill="#fff"/>
                      <path d="M322.17,100h-7.94a6.82,6.82,0,0,1-6.85-6.79V80.36a6.82,6.82,0,0,1,6.78-6.86h8A6.82,6.82,0,0,1,329,80.31V93.19A6.82,6.82,0,0,1,322.17,100Z" fill="#fff"/>
                      <path d="M343.15,98.55h-7a6.06,6.06,0,0,1-6.15-6V81.08A6.06,6.06,0,0,1,336,75h7.06a6.05,6.05,0,0,1,6.08,6V92.47a6.05,6.05,0,0,1-6,6.08Z" fill="#fff"/>
                      <path d="M277.65,98.55h-7a6.05,6.05,0,0,1-6.08-6V81.08a6.05,6.05,0,0,1,6-6.08h7a6.05,6.05,0,0,1,6.08,6V92.47a6.05,6.05,0,0,1-6,6.08Z" fill="#fff"/>
                      <path d="M439.73,56.32H398.51l-.11,130h22v-44h19.91c23.05,0,41.22-17.52,41.22-43.29C481.61,74.7,463.44,56.43,439.73,56.32Zm-2.5,65.37H420.37V78.29h16.86c13.7,0,21.54,6.64,21.54,21.1C458.88,114.08,450.93,121.69,437.23,121.69Z" fill="#f51027"/>
                      <path d="M307.9,53.38a67.82,67.82,0,1,0,67.66,68v-.21A67.93,67.93,0,0,0,307.9,53.38ZM307.79,162c-38.28,0-56.56-14.9-56.56-41s18.38-40.79,56.56-40.79,56.35,14.9,56.35,40.9S345.86,162,307.79,162Z" fill="#f51027"/>
                      <polygon points="113.53 56.1 113.53 186.08 135.39 186.08 135.5 130.39 196.09 130.5 195.98 186.08 217.95 186.08 218.06 56.21 196.09 56.21 196.09 108.86 135.5 108.86 135.5 56.1 113.53 56.1" fill="#f51027"/>
                      <path d="M51.64,188.91c23.82,0,40.13-17.51,40.13-37.85,0-22.74-19.68-33.5-30.67-40.46l-4.57-2.94C46.09,101,37.28,95.15,37.28,87.86c0-6.74,5.11-13.7,15.23-13.7,11.2,0,16.42,10.77,17.73,15.77l19.25-8.05C85.14,67.74,72.63,53.16,52.18,53.16c-20.88,0-38.39,14.8-38.5,34.16,0,19.9,15.88,29.15,28,36.76l6,3.81c13.16,8.38,20.89,14.79,20.89,23.28,0,9.13-5,16.86-16.32,16.86S31,158.67,31,144H9C9,171.29,27.49,188.91,51.64,188.91Z" fill="#f51027"/>
                      <path d="M227.75,183c0,21.88,9.25,52.68,29.46,52.68,22.54,0,31-38.63,59.79-55.93,52.79-31.75,47.14-56.32,47.14-56.32-7.76-10.05-27.59-16-57-16C239.92,107.4,227.7,154.87,227.75,183Z" fill="#ff558a"/>
                      <path d="M255,221c0-61.5,29.75-91,51-109.25" fill="none" stroke="#f51027" strokeMiterlimit="10"/>
                      <path d="M242.28,186.88a1.62,1.62,0,0,0-1.13-2h0l.46-1.75a1.62,1.62,0,1,0,.83-3.14h0l.46-1.75a1.63,1.63,0,0,0,.82-3.15l.46-1.74a1.63,1.63,0,1,0,.95-3.12l-.12,0,.46-1.75a1.62,1.62,0,0,0,.82-3.14l.46-1.75a1.62,1.62,0,1,0,.83-3.14l.46-1.75a1.62,1.62,0,0,0,.82-3.14l.46-1.75a1.62,1.62,0,1,0,.83-3.14l.46-1.75a1.62,1.62,0,0,0,2-1.11v-.05a1.63,1.63,0,0,0-1.15-2h0l.56-2.13,1.79.47a1.62,1.62,0,0,0,3.14.82l1.76.47a1.62,1.62,0,1,0,3.14.82l1.76.47a1.63,1.63,0,0,0,3.15.82l1.76.46a1.62,1.62,0,1,0,3.14.83l1.76.46a1.62,1.62,0,1,0,3.14.83h0l1.76.46a1.63,1.63,0,0,0,3.15.83l1.76.46a1.62,1.62,0,1,0,3.14.83h0l1.79.47-.56,2.13a1.63,1.63,0,1,0-.95,3.12l.12,0-.46,1.74a1.63,1.63,0,0,0-.82,3.15L285,165a1.62,1.62,0,0,0-.83,3.14h0l-.46,1.75a1.62,1.62,0,0,0-.83,3.14l-.46,1.75a1.62,1.62,0,1,0-.82,3.14l-.46,1.75a1.62,1.62,0,0,0-.83,3.14l-.46,1.75a1.63,1.63,0,0,0-.82,3.15l-.46,1.74a1.63,1.63,0,1,0-.95,3.12l.12,0-.46,1.75a1.62,1.62,0,0,0-.82,3.14l-.51,1.92-1.79-.48a1.62,1.62,0,0,0-3.14-.82l-1.76-.46a1.62,1.62,0,0,0-3.14-.83l-1.76-.46a1.63,1.63,0,0,0-3.15-.83l-1.76-.46a1.62,1.62,0,0,0-3.14-.83l-1.76-.46a1.62,1.62,0,0,0-3.14-.83l-1.76-.46a1.63,1.63,0,0,0-3.15-.82l-1.76-.47a1.62,1.62,0,1,0-3.14-.82l-1.79-.47.51-1.92A1.62,1.62,0,0,0,242.28,186.88Z" fill="#3aa74b"/>
                    </svg>
                  </div>
                  <div style={{paddingRight: '20px', display: 'inline-block', position: 'relative'}}>
                    <div style={{color: '#29a349', height: '100%', position: 'relative', display: 'block', width: '44.5vw'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                      	<g>
                      		<path d="M126.6,255.3h169.1v72.8h-68.9v78.4h-71.1v-78.4H0v-10.6L180.9,8.9h84.6L126.6,255.3z"/>
                      	</g>
                      	<path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span style={{display: 'block', color: '#ff0000', paddingTop: '10px'}} className="mercury">
              We resolve to open a store of our own with T-shirts, hats and knick-knacks.
            </span>
          </div>

          <div className="calendar-item-mob-red">
            <div style={{position: 'relative', transform: 'translateX(-30px)', overflow: 'hidden', width: 'calc(100% + 58px)'}}>
              <div style={{display: 'flex', overflow: 'scroll', height: '100%', marginBottom: '-30px'}} className="calendar-item-mob-red--scroll">
                <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignContent: 'space-between', justifyContent: 'space-between', paddingBottom: '30px'}}>
                  <div style={{paddingLeft: '30px', display: 'inline-block', position: 'relative'}}>
                    <div style={{color: '#29a349', height: '100%', position: 'relative', display: 'block', width: '44.5vw'}}>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                      	<g>
                      		<path d="M115.4,79.6V148c6.2-1.1,12.3-1.7,19-1.7c77.8,0,140.6,57.1,140.6,135s-62.7,134.4-140.6,134.4S0,359.1,0,281.2h72.8
                      		c0,34.7,28,62.7,62.7,62.7s62.7-28,62.7-62.7s-28-62.7-62.7-62.7c-6.2,0-20.7,0-25.8,0.6v-67.2H44.2V8.5H243v71.1H115.4z"/>
                      	</g>
                      	<path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                      </svg>
                    </div>
                  </div>
                  <div style={{paddingLeft: '20px', paddingRight: '30px', display: 'inline-block', position: 'relative'}}>
                    <svg style={{position: 'relative', height: '46.2vw', width: 'auto'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 520 290.6" xmlSpace="preserve">
                      <g>
                        <path fill="#EBEBE1" d="M166.2,232.5c-0.4-1-3.4-11.5-3.3-26c0.1-13.1,2.7-32.4,15-49.7l1.2-1.7l10.1,2.5l2.5,14.8l17.2,4.5l3.6-3
                        c0-0.1-0.1-0.2-0.1-0.3c-1.8-4.1-1.4-9.3,1-13.4c1.7-2.9,4.1-4.9,7.1-5.8c1.1-6.1,5.8-11.8,12.1-14.5c9.6-4.1,20.7-1.2,26.8,6.8
                        c48.8-16,66.7,8.6,76.7,31.9c4.7,10.8,9.6,25.2,5.9,39.9c-3.7,14.7-15.1,27.1-34.8,37.9c1.6,8.1-3.1,19-13,23.2
                        c-5.9,2.6-14,2-19.7-1c-2.5,1.8-5.7,2.6-9,2.2c-4.7-0.6-8.6-3.6-10.6-8c-14.2,0.8-27.5-1.3-38.5-6.2c-12.3-5.5-21.3-14.1-26-25
                        c-2.5-5.9-3.7-12.1-3.6-18.4l-2.1-0.3l-8.4,13.1l-9.5-1.7L166.2,232.5z"/>
                        <g>
                          <path fill="#FF0000" d="M274.2,274.6c-4.5,5.5-14.8,3.6-17.1-5.2c-29.2,2.4-54.7-8.1-63.8-29.2c-2.7-6.3-3.8-12.9-3.2-19.8l-6.9-1.1
                          l-8.4,13.1l-5.8-1.1c-0.6-1.3-11.4-40.6,11.3-72.7l6,1.5l2.5,14.8l20.7,5.4l7-5.8c-4.5-5.2-1.8-16.6,6.8-17.5
                          c0.1-6.2,4.9-11.8,10.5-14.2c9.3-4,19.6-0.3,24.3,7.7c47.6-16.5,64.8,5.5,75.1,29.4c10.5,24.4,14.7,51.7-29.8,75
                          c2.6,6-0.6,17.6-10.5,21.9C287.2,279.2,278.9,278.3,274.2,274.6z M231.9,162.5c-1.2-4.6,1.4-9.3,5.5-11.1c4.5-2,8.5-0.4,10.9,2.7
                          l3.4-1.5c-3.2-4.5-9.5-6.6-15.3-4.1c-4.3,1.9-8,6.3-7.9,11.9l0.1,2.5c-1.5-0.6-3-1-4.9-0.2c-2.2,0.9-4,4.3-2.4,8L231.9,162.5z
                           M222.5,188.5l-2.6,1.1l-42.5-10.8c-4,11.4-5,22.9-4.5,32.1l38.7,6.1l-3.4-8l4.7-2c7.5,17.5,24.5,29.6,37,24.2l1.9-0.8l-2.9-6.7
                          l-9.3-3.1l1.2-4.4l10.5,2.9c8-3.4,13.3-1.9,15.3,2.9l-4.1,1.8c-0.8-1.9-4.2-2.5-8-0.9l1.1,2.6c1.5,1.1,3.7,2,7.3,0.4l4.7-2
                          c4.3-1.9,6.6-5.4,4.7-9.9c-2.1-5-6.8-5.7-11.1-3.9l-1.8-4.1c7.5-4.5,13.1,1.3,15.4,0.2c1.5-0.7,2.2-1.5,1.3-3.4
                          c-1.5-3.4-7.5-7.3-13.1-4.8l-2.1-5c4.3-1.9,8.3-1,11.8,0.8c0.9-0.9,1-1.7,0.6-2.8c-0.8-1.9-7.5-6.2-14.7-3.1l-2.1-5
                          c6.5-2.8,8.2-4.1,7.4-6C261.7,171.6,236.1,182.7,222.5,188.5z M243.7,156.6c-0.7-1.7-2.9-2.1-4.7-1.3c-1.7,0.7-3.2,2.7-2.5,4.4
                          L243.7,156.6z M244.7,172.5c0.5-5.3,3.7-11.6,9.3-14l-11,4.7l2.1,5c-5,2.1-5.7,4.5-5.6,6L244.7,172.5z M296.6,254.2
                          c-0.7-1.7-1.9-3.3-3.4-4.4c-0.3,1.1,0,2.3,0.2,2.7l-4.3,1.9c0.9,4.5-1.9,8.5-5.6,10.1c-4.5,2-9.8-0.1-11.7-4.7l5-2.1
                          c0.9,2.2,2.9,3.1,4.8,2.3c2.2-0.9,3.4-2.8,2.3-5.3l4.3-1.9c-0.8-8.9,10-15.8,22.5-21.2c6.3-2.7,11.7-3.8,17-4.5l1.5-2.4
                          c-18.2-1.9-24-13.4-24.2-14.1l4.7-2c0.2,0.4,5.1,10.6,21.6,11.6l0.9-2.2c-20.5-6.5-29.2-26.8-35.2-40.6l5-2.1
                          c5.8,13.4,13.6,31.5,31.3,37.6l0.1-2.6c-8.9-3.3-18.7-14.2-24.5-27.8c-14.6-33.8-24.1-35.1-51.2-24.9l2,4.5
                          c-5.2,2.2-9.1,6.2-9.5,8.7l6-1.6c1.7-3.8,6.9-7.8,16.3-11.9l1.9,4.3c-6.3,2.7-10,5.3-11.7,7.3c2,0.4,4.3,1,5.7,2.6l8.3-4.4l2,4.5
                          l-8,4c0.9,2.2-0.1,5.1-1.3,6.4l3.1,1.2c1.8-1.3,4.8-3.1,9.1-5l1.9,4.3c-3,1.3-5.3,2.5-7.1,3.6c1.7,1.6,2.8,3.6,2.7,5.7
                          c2.1-0.4,4.8-1.3,7.4-2.4l2,4.5c-3.4,1.5-7,2.8-9.5,3.4c1.7,1.6,2.4,3.1,2.7,5l9.2,0.1l-0.1,5.1l-10,0l-2.9,2.8l1.6,3.7
                          c3.3,0.6,8,1.4,11.7,3.4l-2.6,4.7c-2.7-1.4-6.3-2.1-9.1-2.7l-1.6,3.5l8.6,4.5l-2.4,4.6l-9.8-5l-2.6,1.6c2,1.7,4.1,4.1,5.6,7
                          l-4.8,2.6c-1.7-3.4-4.4-6-6.5-7.4c-1.9,0.8-3.8,1.4-5.5,1.4c1.2,2.3,3.2,5,5.8,7.5l-3.4,3.3c-2.8-2.4-5.2-5.4-6.6-8.2l-4.3,1.9
                          c2.4,2.6,4.3,7.1,4.9,10.7l-4.7,2c-0.2-4-2.5-8.1-5-10.9l-2.7,0.2l-1.7,12l-4.8-0.5l1.5-11.9l-3.1-0.7l-4.2,11.5l-4.7-1.3
                          l3.9-11.7l-3.2-1.4l-6.8,9.8l-3.9-2.4l6-9.8l-0.9-0.4l-14.2,6.1l-2.1-5l11.4-4.9l-1.6-1.4l-18.7,3c0.6,2.6,1.3,5.3,2.3,7.7
                          c8.1,18.8,31.2,27.7,57.7,25.2c1.2-3.1,3.8-6,6.2-7c1.7-0.7,3.4-1,5.3-0.8l-0.3,6.5c-0.6-0.2-1.6-0.1-2.2,0.2
                          c-3,1.3-3.5,4.3-2.4,6.9c1.4,3.2,7.5,4.4,9.8-1.7c7.4,6,12.5,5.3,16.8,3.5C296.2,268,299.5,260.9,296.6,254.2z M234.3,176.3
                          c-0.5-3.6,0.5-7.1,3.7-9.8l-7.3,4.4l-1.2,7.4L234.3,176.3z M219,182.9l4.3-1.9l0.6-5.1l-7.5,6.3L219,182.9z M294.2,151.4
                          c3.7,2.7,6.6,6.4,9.3,10.3l1.9-3.4c9.2,4.7,13.9,12.7,17.9,21.9c2.3,5.4,5.3,10,8.1,12.9c-1.2-3.3-2.5-6.9-4.1-10.7
                          C320.1,165.8,310.9,153.9,294.2,151.4z M333,201.7c-5.4-3-11.1-11.6-14.5-19.3c-3.5-8.2-6.5-15.1-13.8-18.9c3.5,5.1,6.5,11,9,16.8
                          c5.4,12.5,13.1,20.9,19.7,24.2L333,201.7z M196.7,224.7l10.5-1.7l-10.4-1.6L196.7,224.7z M301,250c10.1-5.1,17.2-11.8,22.2-17.3
                          c-8.2,2.3-20,6.3-26,13.3C298.3,246.8,299.9,248.2,301,250z"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path fill="#EBEBE1" d="M-213.5-55.5c-18.1-0.9-30.9-9.5-37.7-18.4C-262.4-57.9-282-58.8-294-59.4c-15.6-0.7-27.4-5.1-35.3-13.1
                          c-7.9-8-11.6-19.4-10.9-33.9l1.3-27.3c1.2-24.9,3.9-41.7,8.7-52.8c5-11.9,12.6-18.2,23.7-20l3.4-0.5l-0.3,6.4l5.3,0.3l0.1,2.5
                          c0.1,2.8-0.1,5.3-0.8,7.7c6.8-4.5,14.2-6.5,22.4-6.1c2.7,0.1,5.6,0.5,8.7,1.2l11.7-1.5l5.8,3.4l0.3,1.1c0.6,2.4,0.6,5.6-0.6,8.8
                          l7.8,0l0.6,8c0.3,4.4-1.1,8.4-3.8,11.2c-2.9,2.9-7,4.5-12,4.5c7.1,4.1,13.1,10.7,16.2,14.5c8.7-8.7,27.9-30.3,28.9-50.8l0.1-2.8
                          l11.8,0.6l-0.1,2.8c-0.6,13.6-7.5,35.5-36.6,62.7l-1.3,5.5c7.8,2.7,13.2,11.7,12.7,21.9c-0.6,11.6-7.9,20.5-17.1,21.2
                          c4.4,8.1,15.4,15.8,30.6,16.5c8.5,0.4,15.9-1.3,22-5.1c4.1-2.6,6.4-5.4,7.2-6.5l0.1-0.1l1.6-2.2l8.5,6.2l-0.5,1.8
                          c-0.4,1.5-3.7,6.3-9.7,10.4C-189.3-59.3-199.1-54.9-213.5-55.5z M-274.1-91.5c3.4-5.6,6.1-9.8,10.4-12c-0.7-3.3-0.4-6.6,0.9-9.7
                          c-3.1-4.1-3.8-10.5-3.9-14.3l-6.6-0.3c-4.9,10.7-12.7,19.2-17.9,24l5.8,11.9L-274.1-91.5z"/>
                          <path fill="#EBEBE1" d="M-216.4-70.1l0.5-11.3l2.8,0.1c8,0.4,15.7-2.9,21.7-9.3c0.5-0.5,1.2-1.3,2.5-1.2l0.8,0l7,5.2l-1.2,2.1
                          c-0.6,1-3.6,4.8-8.6,8.1c-6.8,4.5-14.6,6.7-22.7,6.3L-216.4-70.1z"/>
                        </g>
                        <g>
                          <polygon fill="#FF0000" points="-287.3,-136.7 -308.4,-114.3 -304.7,-110.6 -283.6,-133.1 		"/>
                          <path fill="#FF0000" d="M-213.5-72.7c18.3,0.9,28.3-12.2,28.8-13l-4.4-3.2c-0.2,0-8.9,11.2-24.2,10.5L-213.5-72.7z"/>
                          <path fill="#FF0000" d="M-181.4-77.7c-0.5,0.6-9.3,14-31.6,12.9c-19-0.9-31.7-11.9-34.7-22.1l2.3,0.1c8.4,0.4,15.6-7.6,16.2-18.6
                            c0.5-10.1-5.4-18.7-13.3-19.7l2.1-9c6.3-5.8,35-32.3,36.3-61.4l-6.2-0.3c-1.1,23.2-23.7,46.9-31.9,54.8
                            c-4.1-5.5-15.9-19-27.2-19.5c-4.3-0.2-8.8,0.9-13.2,3l3.2-5.8l20.2,1c9.4,0.5,14.7-5.1,14.2-12.7l-0.4-5.4l-10.1,0
                            c3.4-3.8,3.5-8.3,2.8-10.9l-4-2.3l-11.3,1.4c-11.4-2.6-26.9-3.2-41.9,16.6l-0.1-5.3c7.3-3.5,9.5-10.3,9.2-16.8l-5.6-0.3l0.3-5.8
                            c-19.7,3.1-27.7,22.3-30,70.2l-1.3,27.3c-1.3,27.5,13.7,42.6,43.5,44c12.9,0.6,32.8,1.6,42.5-16.9c6.1,10.4,19.5,19.9,38,20.8
                            c26.2,1.2,36.4-14.8,36.7-15.9L-181.4-77.7z M-243.8-119.1c4.5,0.2,8.5,6.4,8.2,13.5c-0.3,6.9-4.9,12.9-9.4,12.7l-1.7-0.1
                            c0.2-1.2,0.4-2.5,0.6-3.8c3.2,0.2,6.2-4,6.4-7.7l0.1-3l-16.5-0.8c0.2-1.1,0.6-2.3,1.3-3.4l9.4-0.4L-243.8-119.1L-243.8-119.1z
                             M-272.6-88.7l-14.6-0.7l-7.5-15.2c5.9-5.2,14.7-14.6,19.5-26.2l11.2,0.5c-0.3,5.6,0.8,13.3,4.4,16.5c-2,3.6-2.3,7.7-0.9,11.8
                            C-265.6-100.4-268.3-95.8-272.6-88.7z M-293.5-68.6c-26.4-1.3-38.5-13.5-37.4-37.3l1.3-27.3c1.9-39.7,8.9-61.3,22.4-63.7
                            c1.5,5.4-1.3,9.2-5.5,10.7l-3.1,1.1l0,14.1l-3.4-1.1l-1.3,4.7l4.4,1.4c-3,5.3-6,11.5-8.8,18.9l4.4,2.8
                            c16.9-39.2,32.8-47.9,51.5-42.1l10.2-1.5c1,1.8-0.7,6-4.7,7.3l-16.7-0.8l-3.9-3.8c-12.2,8.5-20.4,20.8-32.6,43.2l4.4,3
                            c13.5-22,20.1-36.9,34-36.9l27.3,1.3l0,0.9c-0.2,4.1-3.7,5.4-7.8,5.2l-2.3-0.1l-4.8-3.9l-2.3,3.5l-14.6-0.7l-10.6,19.1
                            c-5.2,4.1-10.2,9.1-14.8,14.3l4.4,3.4c11.2-13.5,24.7-21.8,34.4-21.4c7.9,0.4,18.6,11.9,23.5,17.9l-3.5,18.6l-3.9,0.2
                            c-3.6,0.3-4.4-10.7-4.2-15.5l4.1-4.9l-3.7-3.4l-4,4.8l-11.6-0.6c0.6-2.5,1-5.1,1.2-7.7l-5.6-0.3c-0.7,14.6-9.7,26.9-20.9,36.2
                            l-3.1,2.6l10.6,22.2l21.3,1c1.6,0.6,3.1,2,2.9,5.1l4.9,0.2c0.2-4.7-1.9-7.9-4.9-9.6c4.1-8.1,6.5-11.5,11.9-10.3l2.3,0.5
                            c-0.5,3.7-1.2,7-2.2,9.8l0,0c0,0,0,0,0,0C-260.8-68.5-276.2-67.8-293.5-68.6z"/>
                        </g>
                      </g>
                      <g>
                        <path fill="#EBEBE1" d="M508.4,245.5l-8.4-10.9l-2.6,18.7l-14.2-2.2l3.1-17.6l-13.9,10.7l-8.6-11.2l20.3-15.3l-16-11
                          c-4.1,4.5-8.8,8.7-14.3,12.9c-4.8,3.7-9.7,6.8-14.7,9.2l11,14.3l4.8-3.3l8.6,11.2l-13.3,10.2l-1.6,20.6l-13.4-1.4l0.9-12.2
                          l-12.5,13l-10.2-8.8l10-10.1l-8,2.6l-5.4-12.4l24.1-8.1l-8.1-10.6c-27.5,7.2-55-5-77.6-34.4c-4.9-6.3-15-21.7-16.9-27.7
                          c-3.8-11.9,5.6-29.8,22.3-42.7c16.9-13,36.2-17.3,47-10.6c5.5,3.5,16.3,15.9,22.2,23.6c2.1,2.7,3.9,5.1,5.6,7.6
                          c1.6-0.9,3.9-2.3,7.2-4.8c4.4-3.4,13.5-10.4,23.6-51.6c7.7-31.5,20.5-46.1,43.2-63.5c4.5-3.4,10.3-2.6,13.8,2
                          c3.6,4.7,3.1,9.9-1.6,13.4c-13,10-16,17.8-18,46.4l-2.4,34.8c-1.9,27.9-4.3,49.4-17.6,69.4l24.9,16.9l0.2,1l17.9,23.3L508.4,245.5z
                          "/>
                        <g>
                          <path fill="#FF0000" d="M374.8,202.1c8.3-1.1,16.4-6,22.6-10.3l-3.9-5.1c-6.1,4.4-13.9,9.3-22.6,10.3L374.8,202.1z"/>
                          <path fill="#FF0000" d="M504.4,32.4c-22.7,17.4-34.6,31.5-42,61.7c-9.3,38-17.8,48-24.8,53.4c-5.1,3.9-8,5.5-10.5,6.3
                            c-1.9-3.4-4.1-6.3-6.8-9.8c-6.3-8.2-16.6-19.8-21.4-22.8c-9.5-6-27.5-1.6-43.2,10.4c-16.1,12.4-24.4,28.9-21.2,39
                            c1.7,5.4,11.2,20.1,16.3,26.7c22.3,29,49.3,40.6,76.2,32.6l12.3,16.1l-25.1,8.4l2.7,6.3l18-5.8l-16.9,17.2l5.2,4.4l16.7-17.4
                            l-1.4,18.2l6.8,0.7l1.5-18.7l11.9-9.1l-4.6-5.9l-9.3,7.1l-4.2-5.5l4.6-3.6l-11.5-15c6-2.5,11.9-6,17.8-10.6
                            c6.2-4.8,11.4-9.7,15.8-14.7l22.2,15.3l-21.3,16l4.6,5.9l18.1-13.9l-4,22.7l7.6,1.2l3.2-23.2l11.2,14.5l5.9-4.6l-13.9-18.1
                            l-5.9,4.6l-4.6-5.9l5.3-4.1L472,196.6c14.7-20.2,17.1-42.7,19-70.4l2.4-34.8c1.9-28.5,4.9-37.8,19.3-48.8c2.6-2,3.8-5,1-8.8
                            C511,30.5,507.3,30.2,504.4,32.4z M388,134.9c3.9-2.3,7.7-4.1,9.5-4.5c1.6,2.6,0.8,7.4-1.8,12.2
                            C394.9,139.7,391.8,135.8,388,134.9z M360.1,137.4c11.9-9.1,24.5-12.8,32.1-10.7c-7.9,2.9-18.2,9.1-27.7,16.4
                            c-9.3,7.1-18.1,15.7-22.9,22.2C342,157.2,349.3,145.7,360.1,137.4z M382.3,137.5l4.1,5.3l-5.3,4.1l-4.1-5.3L382.3,137.5z
                             M367.3,149c-0.7,6.8,4.8,13.1,11.4,14c-11.2,8.2-29.6,14.3-35.2,8.9C345.7,167,361.1,153.8,367.3,149z M484.5,125.5
                            c-0.6,27.7-5,48.9-18.6,66.9l-6-4.1c10.3-12.8,17.9-29.8,19.8-54.6l-5.4-1.1c-4.4,36.7-17.1,53-35.6,67.3l3.9,5.1
                            c4.4-3.4,8.7-7.1,12.6-11.3l6.1,4.2c-4,4.5-8.7,8.8-14.2,13c-31.5,24.2-63.6,16.6-90.3-18.2c-2.9-3.7-5.8-7.5-8.2-11.6
                            c10.7,1.2,25-4.8,35.4-12.8c10.6-8.1,19.7-20.4,21.3-30.7c8.2,8.4,19.8,21.2,23.3,37.1l7.2-2c-0.9-3.9-2.3-8-4.7-12.5
                            c3.8-1.5,6.8-3.5,11.2-6.9c11.5-8.8,18-23.6,20.8-34.9C464.3,113.4,484.6,120.2,484.5,125.5z M486,114.2l-0.4,3.1
                            c-3.4-6.2-16.9-8.7-20.3-6.8l0.8-3.1C467.5,102.5,486.9,106.8,486,114.2z M487.4,59.1c-4.1,7.4-8.2,30.8-9.8,41.5
                            c-1.8-1.4-6.7-1.9-9-0.8l1-4.6c3.2-14,8.1-27.3,17.1-37L487.4,59.1z"/>
                          <polygon fill="#FF0000" points="401.8,218.5 408.6,214.7 404.7,209.6 397.9,213.5 		"/>
                          <path fill="#FF0000" d="M385,211.8c6.5-1.5,12.6-5.1,17.7-8.4l-3.7-4.8c-5,3.1-11.1,6.5-18.1,7.9L385,211.8z"/>
                          <path fill="#FF0000" d="M364,192.2c9.9,0.1,20.3-6.5,27.1-11.7l-3.9-5.1c-6.8,5.2-16.1,11.4-27.1,11.7L364,192.2z"/>
                        </g>
                      </g>
                      <g>
                        <path fill="#EBEBE1" d="M155.7,244.7l-22.1-66.2c-1.9,27.5-19.7,42.5-32.2,53.1c-0.1,0.1-0.3,0.2-0.4,0.3l3.3,9.8l13.6,15.6
                          l-75.3,25.1l-6-17.9l5.1-1.7l-1.5-4.5c-5.6,0.6-10.6-2.2-14.9-8.4c-3.8-5.5-7.3-13.5-12-27.7c-6.9-20.7-7.4-37.6-1.5-54.4
                          c-2.3-4.9-6.9-15-10.7-26.5c-8.5-25.6,10-56,15.9-64.7l1.5-2.3L21,75c1.2,0.4,12.3,3.9,24,10.9c14.8,8.9,24.4,19.4,28.3,31.3
                          c3.1,9.4,6,24.4,7.6,33.2l39-13l-9.5-28.5l-6.8,2.3l-1.9-50l28.1,41.3l-6.5,2.2l8,24c7.8-0.4,14.7,4.4,17.4,12.5
                          c2.7,8.2,0,16.1-6.6,20.1l26.4,79.2L155.7,244.7z M78.1,266.8l-4.8-14.5l5.1-1.7l-1.4-4.2l-8.5,2.8l1.3,4L78.1,266.8z"/>
                        <path fill="#FF0000" d="M129,132.3l-10-29.9l5.5-1.8l-19-27.9l1.3,33.8l5.7-1.9l11.7,34.9l-46,15.3c-1.3-7.7-4.6-26-8.1-36.6
                          C61.3,91.8,24,79.5,20,78.3c-2.2,3.3-24.4,35.5-15.6,61.9c3.4,10.3,7.9,20.6,11.2,27.4c-6.6,17.8-5.4,34.2,1,53.6
                          c7.8,23.4,14.4,37.1,25.9,33.2L46,265l-5.1,1.7l3.8,11.5l30.1-10l-8.3-13.3l-2.5-7.6l14.9-5l3.5,10.6l-5.1,1.7l3.8,11.5l30.8-10.3
                          l-10.7-12.3l-4.2-12.6c14.7-12.6,39-30.9,32-70.9l1.6-0.5l27.1,81.1l6.4-2.1L138,159.4c7-2.6,10-10,7.5-17.3
                          C143,134.5,136.3,130.6,129,132.3z M40.9,248c-3.4,1.1-7.7-1.5-9.7-7.7l6.4-2.1L40.9,248z M48.6,269.5l-1.2-3.7l5.3-1.8l-3.9-11.7
                          l9-3l2.6,7.8l4.6,6.9L48.6,269.5z M84.9,257.4l-1.2-3.7l5.7-1.9l-4.5-13.6l6.5-3.4l3.9,11.7l5,5.7L84.9,257.4z M57.7,243.4
                          c-0.2,0.1-11.3,3.8-11.3,3.8l-17.6-52.9l-5.3,1.8l12.3,37l-6.7,2.2c-7.1-16-15-38.2-9.2-59.8l5,6.5l4.4-3.8
                          c-0.2-0.5-7.3-9.6-11.6-19.6c3.4,4.7,11.4,13.6,17.9,17l27.8-9.3c3.3-11.6,4.4-26.7,0.4-38.7c-6-17.9-19.8-25.8-35.5-20.6
                          c-11.5,3.8-18.1,13.2-19,24.2c-2.2-18.9,9.2-39.1,13.2-45.3c7.9,3,35.3,14,42,34c4.2,12.6,6.6,29,8,43.8l39.3-13.1
                          C123.7,224.5,77.5,236.8,57.7,243.4z M55.6,120.5c0.5,0.8,1.1,1.9,1.7,2.8c-19.8,1.8-25.4-8.7-26.4-11.1l3.2-0.3
                          C35.1,114.3,40.3,122.1,55.6,120.5z M40,111.4c3.9,0.2,7.1,1.5,10.5,3.9C44.6,115.3,41.6,113,40,111.4z M41.3,127.4
                          c-3.1,2.3-3.7,5.8-3.8,6.4l4.4,1.6c0.5-1.5,2.1-4.5,4.8-5.4l1.8-0.6l2.4,7.1l-1.8,0.6c-2.8,0.9-6-1.1-7.2-1.7l-2.5,3.9
                          c0.5,0.6,6,4.1,11.3,2.4c5.3-1.8,7.6-7.9,7.8-8.7l-4.5-1.3l1.5-2.5l4.1-0.6c0.1,0.2,0.2,0.7,0.3,0.9c3.4,10.1,1.3,24.6-0.9,33.3
                          L36.8,170c-4-2.8-13.3-10.6-17.4-19.2c1.4,0.3,2.7,0.4,4.5-0.2c5.5-1.8,7.8-8,8.3-8.9l-5.3-1.3l3.4-4.2c-0.6-0.3-4.3-2.9-8.2-3.1
                          c5-6.5,4.8-13.1,4.4-16.5C28.6,119.3,33.1,124.5,41.3,127.4z M14.5,133.7c-0.4-6.5,2.2-12.5,7-16.4
                          C21.8,120.3,21.6,127.3,14.5,133.7z M20,138.8l1.1-0.4l2.4,7.1l-1.1,0.4c-2.8,0.9-6.7-0.1-7.8-1.2C14.8,143.1,17.3,139.7,20,138.8z
                           M117.1,200.1l-1-0.7c3.1-10.7,5.4-24.5,3.7-36.5l4.4-1.5C126,177,123.2,189.2,117.1,200.1z M127.2,148.5l-4.6,1.5l1.5,4.6
                          l-4.8,1.6l-2-8.3l8.7-2.9L127.2,148.5z M111,89l6.9,11.5l-5.5,1.8L111,89z M136,154c-4.8,1.6-6.8-1.3-8-5l4.8-1.6l-2.8-8.5
                          c3.4-1.1,7.7,0,9.4,5.3C140.9,149,140.1,152.6,136,154z"/>
                        <path fill="#FF0000" d="M37.9,159.5c0,0,3.2,2.2,6.4,1.1c2.7-0.9,4.2-4.6,4.2-4.6s-3.5-2.3-6.2-1.4C39.1,155.7,37.9,159.5,37.9,159.5z
                          "/>
                        <path fill="#FF0000" d="M58.4,206c-3.6,1.2-5.9,5.5-4.5,9.7c1.3,3.8,5.6,5.6,9.2,4.4c4-1.3,6.2-5.3,4.9-9.1
                          C66.6,206.8,62.4,204.7,58.4,206z M61.7,216.1c-1.6,0.5-3.1-0.4-3.6-1.8c-0.5-1.6,0.1-3.3,1.7-3.8c1.8-0.6,3.6,0.3,4.1,1.9
                          C64.4,213.7,63.5,215.5,61.7,216.1z"/>
                        <polygon fill="#FF0000" points="32.3,136.7 33.5,153.3 44.7,149.5 43.2,145 37.3,147.3 37.3,136.1 	"/>
                        <path fill="#FF0000" d="M50.8,183.4c-3.6,1.2-6.1,4.8-4.6,9.3c1.2,3.6,5.7,5.8,9.3,4.6c4-1.3,6.1-5.8,4.9-9.3
                          C58.8,183.5,54.8,182,50.8,183.4z M54,193c-1.6,0.5-3.1-0.4-3.6-1.8c-0.6-1.8,0.1-3.3,1.7-3.8c1.8-0.6,3.5,0.1,4.1,1.9
                          C56.7,190.6,55.8,192.4,54,193z"/>
                      </g>
                      <g>
                        <path fill="#EBEBE1" d="M153,162.7c-2.6-4.9-0.6-9.8,3.2-15.6c1.1-1.6,3.2-5,3.3-6.1c-0.5-0.8-2.2-2.1-7.6-3.7
                          c-7.2-2.3-15.1-5.5-18.7-12.4c-4.2-8.1,0.2-14.2,3.4-18.7c3.1-4.3,4.2-6.3,2.9-8.8c-2.5-4.8-9.5-8.9-16.3-13
                          c-7.8-4.6-15.9-9.4-19.5-16.2c-3.6-6.9-0.7-15.6,6.4-19.3c5.6-2.9,10.6-2.1,14.9-1.4c1.3,0.2,2.5,0.4,3.7,0.5
                          c0.8,0.1,1.6,0.1,2.3,0.2c5.8,0.5,11.8,1.1,22.6-1.5c0-7.2,2.2-14.3,6.6-20.6c4.4-6.3,10.7-11.7,18.3-15.7
                          c12.7-6.6,23-5.4,27.6,3.3c3.1,6,0.2,10.9-3.4,15.3c9.1-1.2,17.9,0.1,25.7,3.7c9.1,4.2,16.7,11.6,21.6,20.8
                          c5.1,9.7,6.6,20.2,4.3,30.4c-1.8,8.2-6.1,15.8-12.5,22.4c5-0.5,10.4,0,13.3,5.6c2.8,5.3,1.9,10.6-2.5,15.3
                          c-3.2,3.4-7.6,5.7-10.3,7.1l-0.3,0.2c-14.6,7.6-31,8-42.5,1.2c-6.9,5.7-13.1,11.7-19.2,17.5c-3.5,3.3-7.1,6.8-10.7,10.1
                          c-3.4,3.1-7.3,4.5-10.8,3.9C156.3,166.6,154.2,165.1,153,162.7z"/>
                        <path fill="#FF0000" d="M154.8,114.3c0.4-0.5,0.7-0.9,1.1-1.3c0.7-0.9,1.5-1.7,2.3-2.5c3.3-3.3,7.2-6.2,12.4-8.9l0,0l-2.6-5l0,0
                          c-5.5,2.9-9.6,5.9-13.1,9.3c-0.5,0.5-0.9,0.9-1.3,1.4c-3.3,3.5-6,7.3-8.6,11.5l0,0c0,0,0,0,0,0l4.8,3c0.6-1.1,1.3-2.1,1.9-3.1
                          C152.7,117.1,153.7,115.6,154.8,114.3z"/>
                        <path fill="#FF0000" d="M241.3,132.6c4.8-2.5,17-8.6,11.8-18.5c-4-7.6-15.3-2.4-20.4-2.4C254.8,95.4,257,73,248,55.7
                          c-8.7-16.7-27.6-28.3-52.3-21.3c4.8-6.4,12.1-11.6,8.5-18.5c-3.9-7.6-13.2-7.4-23.6-2c-13.7,7.2-24.4,20.2-23.2,35.9
                          c-14.3,3.7-21.1,2.5-28.3,1.9c-5.5-0.5-10.7-2.7-17,0.6c-5.8,3-8,9.9-5.2,15.3c6.2,11.9,29.9,17.9,35.8,29.2
                          c4.8,9.3-12.6,15.4-6.3,27.5c3.2,6.1,10.7,9,17,10.9c5.6,1.8,8.3,3.3,9.4,5.3c0.9,1.7-0.4,4-3.6,9c-3.8,5.8-4.7,9.3-3.1,12.6
                          c2,3.9,7.4,4.1,12.2-0.3c10.3-9.4,19.9-19.7,31.6-29.1C211.4,140.8,228,139.5,241.3,132.6z M162.9,158.3l-0.8-1.5
                          c3.7-7.7,13.8-21.5,23.2-27.2l-2.8-4.9c-0.3,0.4-6.8,4.4-14,12.8c-2.1-4.1-5.5-5.9-13.1-8.3c-5.3-1.7-11.1-4.4-13-8.1
                          c-2.9-5.6,12.4-14.7,5.9-27.2c-6.8-13-30.4-19.9-35.4-29.4c-1.4-2.6-0.1-5.4,2.1-6.6c2.6-1.4,5.7-0.7,12.1,0
                          c8.3,1.1,16.1,2.2,31.3-2.2c0.4,1.4,0.8,2.7,1.3,4.1c-12.6,3.5-26.1,3.2-42-0.3l-1,5.5c22.8,5.2,41.5,3.7,59.2-5.6l-2.6-5
                          c-2.7,1.4-5.4,2.6-8.1,3.6c-6.5-16.7,4.3-31,18.3-38.3c8.4-4.4,12.9-3.2,14.3-0.6c2.7,5.2-18.8,15.6-9.5,33.5l5.2-2.7
                          c-1.4-2.6-1.8-4.9-1.7-7.1c21.1-8.4,40.5-2.4,50,15.9c10.5,20.1,2,41.3-22,53.8c-21,10.9-40.3,30.3-50.1,39.3L162.9,158.3z
                           M205.7,128.4c5.1-3.7,10.7-7.3,16.9-10.5c0.8-0.4,1.5-0.8,2.2-1.2c9.9,3.3,20.4-3.8,22.6,0.4c1.8,3.5-2.7,6.4-9.2,9.7
                          C228.5,131.8,215.2,134.2,205.7,128.4z"/>
                        <path fill="#FF0000" d="M209.3,66.9c-0.1,0.7-0.2,1.5-0.2,2.2c0,1.7,0.5,3.4,1.3,5l0,0l0,0c0.4,0.7,0.8,1.3,1.3,1.9
                          c0.2,0.3,0.5,0.6,0.8,0.8c3.4,3.4,9,4.5,13.2,2.3c4.7-2.5,6.8-7.6,6-12.3c-0.1-0.4-0.2-0.7-0.3-1.1c-0.2-0.7-0.5-1.4-0.8-2.1l0,0
                          l0,0c-0.9-1.6-2-3-3.3-4c-0.6-0.5-1.2-0.8-1.9-1.2c-3.1-1.5-6.8-1.5-10.3,0.3C212,60.4,209.9,63.5,209.3,66.9z M224.1,70.3
                          l-4.5,2.4l-2.4-4.5l4.5-2.4L224.1,70.3z"/>
                        <polygon fill="#FF0000" points="225.6,100.6 225.6,100.6 233.8,96.3 233.8,96.3 229.6,88.1 229.6,88.1 221.3,92.4 221.3,92.4 	"/>
                        <path fill="#FF0000" d="M200.2,63L200.2,63l-4.5,2.4c-0.9,2.6-1.4,7.8-1.1,12.6c0,0.6,0.1,1.3,0.1,1.9c0.1,1.4,0.3,2.8,0.6,3.9
                          l-2.8,2.6l-2.8,2.6c-4.5,4.1-9.4,8.5-11.9,13.2c-1.3,2.4-1.9,4.8-1.7,7.3c0.1,1.4,0.5,2.8,1.3,4.3l0,0l0,0c1.1,2,2.5,3.5,4.3,4.6
                          c1.3,0.7,2.7,1.2,4.2,1.5c8.5,1.5,20.2-4,26.9-11.6l2.7,0.5l2.7,0.5l4.8-2.5l0,0L200.2,63z M182.3,105.8c0.2-0.7,0.5-1.4,0.9-2.1
                          c0.2-0.4,0.4-0.7,0.6-1.1c0.2-0.4,0.5-0.7,0.7-1.1c0.1-0.2,0.3-0.4,0.4-0.5c1.6-2.1,3.7-4.2,5.8-6.2c1.5-1.3,3.2-2.7,4.8-4.2
                          c0.5-0.5,1-1,1.5-1.5c3.3-3.3,5.9-7.1,5.4-11.4l4.3,8.2c1.5,2.8-5.5,11.6-17.1,17.7l0,0l0,0l2.6,5l0,0l0,0
                          c11.7-6.1,17.2-12.8,17.7-16.6l2.7,5.2c0.3,1.3,0.1,2.7-0.6,4.1c-0.2,0.3-0.3,0.6-0.5,1c-0.2,0.3-0.4,0.6-0.6,1
                          c-0.1,0.2-0.2,0.3-0.4,0.5c-5.7,7.2-20.4,13.9-26.3,9.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.5-0.7-0.7
                          c-0.2-0.3-0.4-0.6-0.6-0.9c-0.7-1.4-0.9-2.9-0.5-4.4C182.1,106.5,182.2,106.1,182.3,105.8z"/>
                      </g>
                      <g>
                        <path fill="#EBEBE1" d="M458.8,49.5l-12.2,0.2l0.2,15.7l12.1-3.1l0.6,41.1l-20.2,0.3c0,0-42.7-10.2-74.3-17.7c2.7,1.6,4.8,3.6,6.2,5
                          l0,0l2.7,2.6l-1.4,1.1c3,1.5,5.8,3.7,8.3,6.6l1.9,2.2l-2,2.3c-3.2,3.8-8,9.5-16.7,9.1c-3,3.4-7.3,5.2-12.5,5.3l-3.3,0l-0.1-3.6
                          l-73.4,18.9c-2,6.6-8,11-15.5,11.1l-3.3,0l-0.2-12.2l3.3,0c0.4,0,4.3-0.2,4.3-4.1c0-1.1,0-3-4.4-2.9l-3.3,0l-0.2-12.2l3.3,0
                          c6.9-0.1,12.5,3,15.2,8.1l66.7-17.4l-29.3-28.9c-5.3-5.3-7.1-10.2-8.4-13.5c-0.2-0.6-0.5-1.3-0.7-1.9c-1.3,1.2-2.8,2.4-4.6,3.5
                          c0.3,7.7,0.7,10,4.9,23.8l2.1,6.9l-6.6-2.8c-4.9-2.1-12.4-6.2-17.5-13.4l-0.4,2.6l-9,0.1l-0.9-2c-1.8-3.9-3.4-7.5-4.4-11.9
                          l-5.9,0.1c-2.3,0-5.5-1.2-7.5-7.4c-1-3.3-1.6-7.7-1.7-12.4c-0.1-5.1,0.5-9.8,1.5-13.5c2-6.7,5.3-8.2,7.8-8.2l6.7-0.1
                          c1.3-4.7,3.3-9.4,6.1-14.1l1-1.6l9.1-0.1l7.1,12.2c2.3-1.9,3.6-4,5-6.3c3.1-5,6.7-10.7,19.4-10.9l104.6-1.5
                          c9.6-0.1,16.6,3.5,18.9,9.5l20.2-5.8L458.8,49.5z"/>
                        <path fill="#FF0000" d="M428.8,22.6l0,1.6l-64.5,1l0.1,5.5l65-1l0.1,4c5-0.1,7.2,1.5,7.2,6l-66.8,1l-0.1-9
                          c-11.9,1.5-24.4,12.5-29,20.8l3.2,3.9l4.2-1.1c8.1,8.9,19.2,10.3,25.8,10.2l-0.1-5c-6.3,0.1-17.2-1.3-23.7-11l1.6-1.6
                          c5.1,6.8,12,9.9,22.1,9.7l-0.1-5c-8.5,0.1-14.6-3-18.6-8.4l1.8-1.6c4.9,6.5,12.5,7.5,16.8,7.4l-0.1-3.7l62.6-0.9l0.7,47.5
                          c-11.4-2.7-86.7-20.7-104.5-24.9c-8.8-2.2-10.6-4.3-13-6.9c-2.7-3.1-5.6-6.3-15.2-9.8c0.2-1.1,0.5-1.9,0.5-2.9
                          c-0.1-6.9-4.7-12.6-12.6-15.7c11-3.6,10.1-17.1,19.6-17.2c-3.9,2.7-7.5,8-7.5,12.5c0.1,10,11.3,16.5,28,16.2l2.6,0l0-2.6
                          c0-1.1,0.6-10.3,9.6-10.4l-0.1-5.5c-10,0.1-13.9,7.6-14.9,13.2c-9.8-0.4-18.8-3.7-18.9-10.8c-0.1-6.1,8.8-11.5,19.1-11.6l89.5-1.3
                          C427.7,14.7,428.8,18.9,428.8,22.6z M448.4,39.2l-4.8,0.1c-0.3-5.8-2.8-12.1-13.1-12.5l17.6-5L448.4,39.2z M360.7,40.2l5.2-3.2
                          l0.1,6.1L360.7,40.2z M438.4,44.4l-0.1-4l4.5-0.1l0.1,4L438.4,44.4z M448.9,75.7l0.3,17.4l-5.3,0.1l-0.2-16.4L448.9,75.7z
                           M337.2,81.5l3.2,4.4c4.7-3.5,10-3.1,13.2,0.9c-2.6,0.3-5,1.4-7.3,3.3l3.5,4.4c4.4-4,9.5-2.8,13.5,1.1c-5.3-0.2-10.3,1.2-14.5,5
                          c-6.7-6.5-34.8-33-36.2-35.4l1.6-0.8c3,3.1,5.9,7.6,16.5,10.1c3.4,0.7,8.7,2,15.1,3.5C343,78.3,340.1,79.4,337.2,81.5z
                           M371.4,103.8c-1.6,1.9-5,3.2-7.6,2.8l-0.1-5C365.8,101.5,369.8,102.5,371.4,103.8z M349,107c1.3-1.6,5.5-4.8,10.2-5.4
                          c0.1,6.9-2.5,9-5.4,9.9L349,107z M291.8,79.6c-5.9-5.2-9.5-14.9-9.6-21.3l-5.5,0.1l0.1,5.5l-1.8,0c-1.9-5.8-1.8-14.2-1.1-21.4
                          c8.5,0.4,17.2,4,17.3,9.5c0,2.1-1.3,4.2-2.8,4.3C288.4,64.8,289.6,73.8,291.8,79.6z M262.7,58.3c-1.6-3.7-3.9-12.4,0.5-21.1
                          l5.5-0.1c-1.2,7.7-1.1,15.1-0.2,21.1L262.7,58.3z M282.3,30.3l-5.8,0.1l2.3-7.4L282.3,30.3z M294.3,56.8c1.3-1.3,2.3-2.7,2.3-4.8
                          c-0.1-8.2-8.7-13.3-21.9-14.2l0.5-3.2c13.5-0.7,23.9,4.9,24,13.6C299.3,52.5,297.5,56,294.3,56.8z M314.5,9.5
                          c-18.2,0.3-13.6,12.1-25.6,18.6L281,14.5l-5.3,0.1c-3.1,5.3-5.1,10.4-6.4,15.7l-9.2,0.1c-4.2,0.1-6.2,9.3-6.1,18.3
                          c0.1,8.5,2.1,16.6,5.8,16.6l8.7-0.1c0.9,5.3,2.5,9.2,4.7,13.9l4-0.1l1.7-10.9c2.5,11.3,11.9,18.3,20.4,21.9
                          c-4.7-15.3-4.7-17.1-5.1-26.6c2.9-1.6,5.5-3.5,7.3-5.9c5.5-0.1,2.8,7.9,12.1,17.3l33.5,33.1l-75.3,19.6c-1.4-5.5-6.5-8.9-13.1-8.8
                          l0.1,5.5c2.4,0,7.7,0.4,7.8,6.2c0.1,5-3.9,7.5-7.6,7.5l0.1,5.5c6.9-0.1,11.8-4.4,12.8-10.5l79.5-20.5l0.1,4.5c5-0.1,8.7-2,11-5.4
                          c8.2,1.2,12.4-3.9,15.7-7.9c-4-4.7-8.6-7-12.8-7.5l3.4-2.7c-0.3-0.3-5.1-6-11.7-6.7l2.4-1.9c0,0-2.2-2.9-5.6-4.9
                          c32.6,7.7,83,19.6,83,19.6l1.3-4.8l4.5-0.1l0.1,5.5l13.5-0.2l-0.5-33.5l-12.1,3.1l-0.3-23.2l12.2-0.2l-0.5-33.5l-19.5,5.6
                          c-0.1-6.9-7.6-10.5-16.3-10.3L314.5,9.5z M276.5,47.6l0.1,5.8l5.8-0.1l-0.1-5.8L276.5,47.6z M336.2,46.4l4.3,2.6
                          c5.6-12,16.9-18.5,17.2-18.7l-3-4.7C354.1,25.8,342.6,33.1,336.2,46.4z"/>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <span style={{display: 'block', color: '#ff0000', paddingTop: '10px'}} className="mercury">
              We resolve to add ten more emojis to our <a href="https://telegram.me/addstickers/libertevisualite" style={{color: '#b4966e'}}>Telegram sticker</a> set.
            </span>
          </div>

          <div className="calendar-item-mob-red">
            <div style={{position: 'relative', transform: 'translateX(-30px)', overflow: 'hidden', width: 'calc(100% + 58px)'}}>
              <div className="red-edition-scroll calendar-item-mob-red--scroll" style={{display: 'flex', overflow: 'scroll', height: '100%', marginBottom: '-30px'}}>
                <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignContent: 'space-between', justifyContent: 'space-between', paddingBottom: '30px'}}>
                  <div style={{paddingLeft: '30px', paddingRight: '30px', display: 'inline-block', position: 'relative'}}>
                    <img src={year04} alt="" style={{position: 'relative', height: '46.2vw', width: 'auto'}} />
                  </div>
                  <div style={{paddingRight: '20px', display: 'inline-block', position: 'relative'}}>
                    <div style={{color: '#29a349', height: '100%', position: 'relative', display: 'block', width: '44.5vw'}}>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                      <g>
                      	<path d="M95.8,8.5h90.2L98.6,151.3c10.6-3.4,23-5,35.8-5c77.8,0,140.6,57.1,140.6,135s-62.7,134.4-140.6,134.4S0,359.1,0,281.2
                      		c0-58.8,35.3-111.4,86.8-127.1H8.4L95.8,8.5z M134.4,343.9c33.6,0,63.8-24.1,63.8-62.7s-30.2-62.7-63.8-62.7
                      		c-34.2,0-61.6,24.1-61.6,62.7S100.2,343.9,134.4,343.9z"/>
                      </g>
                      <path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span style={{display: 'block', color: '#ff0000', paddingTop: '10px'}} className="mercury">
              We resolve to finally learn how to play chess
            </span>
          </div>

          <div className="calendar-item-mob-red">
            <div style={{position: 'relative', transform: 'translateX(-30px)', overflow: 'hidden', width: 'calc(100% + 58px)'}}>
              <div style={{display: 'flex', overflow: 'scroll', height: '100%', marginBottom: '-30px'}} className="calendar-item-mob-red--scroll">
                <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignContent: 'space-between', justifyContent: 'space-between', paddingBottom: '30px'}}>
                  <div style={{paddingLeft: '30px', display: 'inline-block', position: 'relative'}}>
                    <div style={{color: '#29a349', height: '100%', position: 'relative', display: 'block', width: '44.5vw'}}>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 416.1" xmlSpace="preserve">
                      	<g>
                      		<path d="M118.4,170.3h93L245,79.6H110V8.5h239.7v8.4L208,406.1h-81.8l58.8-163h-66.6V170.3z"/>
                      	</g>
                      	<path d="M342.5,411.2c30.2,0,57.5-21.7,57.5-56.5s-27.2-56.5-57.5-56.5c-30.7,0-55.4,21.7-55.4,56.5S311.8,411.2,342.5,411.2z"/>
                      </svg>
                    </div>
                  </div>
                  <div style={{paddingLeft: '20px', paddingRight: '30px', display: 'inline-block', position: 'relative'}}>
                    <img src={year05} alt="" style={{position: 'relative', height: '46.2vw', width: 'auto'}}/>
                  </div>
                </div>
              </div>
            </div>
            <span style={{display: 'block', color: '#ff0000', paddingTop: '10px'}} className="mercury">
              We resolve to work with a great sports brand and become regular sneakerheads.
            </span>
          </div>

        </div>
      </div>
    )
  }
}
