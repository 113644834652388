import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import og from '../../assets/press/og_v2.png'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'

// todo remove img
// import presentation from '../../assets/press/01.png'
// import logotype from '../../assets/press/02.png'
// import pdf from '../../assets/press/hello_shuka.pdf'
// import zip from '../../assets/press/shuka_logotype.zip'

export default class extends Component {
  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    document.documentElement.style.overflow = ''
  }

  render () {
    return (
      <div className='case-root'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka 🗞 press 🐋 kit</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="We do not know which of our logos is the current one, so go ahead and grab them all." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="We do not know which of our logos is the current one, so go ahead and grab them all." />
          <meta property="og:title" content="Shuka 🗞 press 🐋 kit" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <TouchNav navBg='transparent' color='dark' />
          <CaseHead leftTitle='press kit:' rightTitle="studio presentation and logotypes" menuColor='dark' isTech='true' />
        </div>
        <div className='head-line'>
          <div className="head-line-start icon zero">b b b</div>
          <p className="head-line-end mercury">Since we ourselves often forget which Shuka logotype is the current one and where to get it, here's a dedicated page for it and our presentation as well.</p>
        </div>
        <div className='portfolio at-career'>
          <div className="portfolio-cell slide-in">
            <div className='press-cell--img'>
              <img alt='shuka presentation' src={`${process.env.REACT_APP_MEDIA}/media/press/01.png`} />
            </div>
            <p className='press-cell--text mercury'>
              <span><a href={`${process.env.REACT_APP_MEDIA}/media/press/hello_shuka.pdf`} download target='_blank' rel="noopener noreferrer"><b className="underline"></b>studio presentation</a> ↓ .pdf</span>
              <span>march 2019 version</span>
            </p>
          </div>
          <div className="portfolio-cell slide-in">
            <div className='press-cell--img'>
              <img alt='logotype' src={`${process.env.REACT_APP_MEDIA}/media/press/02.png`} />
            </div>
            <p className='press-cell--text mercury'>
              <span><a href={`${process.env.REACT_APP_MEDIA}/media/press/shuka_logotype.zip`} download target='_blank' rel="noopener noreferrer"><b className="underline"></b>logotype</a> ↓ .zip with vector formats</span>
              <span>for all adobe illustrator lovers</span>
            </p>
          </div>
        </div>
      </div>
    )
  }
}
