import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'

import img01 from '../../assets/contact/01.jpg'
import og from '../../assets/contact/og.png'

export default class extends Component {
  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    document.documentElement.style.overflow = ''
  }

  render () {
    return (
      <div className='case-root case-root--contacts'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact 📠 Shuka</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="There is no place like home. Except for the office." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="There is no place like home. Except for the office." />
          <meta property="og:title" content="Contact 📠 Shuka" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <TouchNav navBg='transparent' color='dark' />
          <CaseHead leftTitle='contact:' menuColor='dark' isTech='true' />
          {/*<CaseHead leftTitle='contact:' rightTitle="mail, phone and<br/><nobr>post <span class='icon'>r</span>address</nobr>" menuColor='dark' isTech='true' />*/}
        </div>
        <div className='summary'>
          <div className='summary-body'>
            <div className='summary-body--inner'>
              <div className='summary-table at-contact Cf'>
                <ul className='summary-table-list'>
                  <li className='summary-table-i mercury'>Drop a line to make an order</li>
                  <li className='summary-table-i mercury'><a href="mailto:hello@shuka.design" className="summary-a" data-action="tap" data-touch-style="grey"><b className="underline"></b>hello@shuka.design</a></li>
                  {/*<li className='summary-table-i mercury'>If you feel it's necessary call us: </li>*/}
                  {/*<li className='summary-table-i mercury'><a href="tel:+74997533253" className="summary-a" data-action="tap" data-touch-style="grey"><b className="underline"></b>+7 916 397 11 80</a></li>*/}
                  {/*<li className='summary-table-i mercury'>mon — fri, 11:00 — 19:00 (GMT +03) </li>*/}
                </ul>
              </div>

              {/*<div className='summary-table at-contact Cf'>*/}
              {/*  <ul className='summary-table-list'>*/}
              {/*    <li className='summary-table-i mercury'>This is where we reside:</li>*/}
              {/*    <li className='summary-table-i mercury'>Temporary on lockdown</li>*/}
              {/*    <li className='summary-table-i mercury'>The mailing address: </li>*/}
              {/*    <li className='summary-table-i mercury'>Magadanskaya 13 flat 76, Moscow, Russia, 129281 </li>*/}
              {/*    <li className='summary-table-i mercury'><span className="summary-table-i-icon zero">→</span><a href="https://goo.gl/maps/x6jRvj7wDYDWdvYM6" className="summary-a" data-action="tap" data-touch-style="grey" target="_blank" rel="noopener noreferrer"><b className="underline"></b> view on map</a></li>*/}
              {/*  </ul>*/}
              {/*</div>*/}

            </div>
          </div>
        </div>

        {/*<div className='container Cf'>*/}
        {/*  <article className='contact--inner'>*/}
        {/*    <div className='contact-block'>*/}
        {/*      <img src={img01} alt='street view'/>*/}
        {/*      <p className="mercury">The typography scene from Tarkovsky’s “Mirror” was shot on the very same spot as our studio. Terekhova runs into the same door that our security guards occupy today. The concrete fence was recently renewed and adorned with unbearable lanterns.</p>*/}
        {/*    </div>*/}
        {/*  </article>*/}
        {/*</div>*/}

      </div>
    )
  }
}
