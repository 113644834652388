export default (url, callback, opts) => {
  // Default options are marked with *
  return fetch(url, opts)
    .then((response) => {
      if (response.status >= 400) {
        throw new Error("Bad response from server")
      }
      return response.json()
     })
    .then(callback)
}
