import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import Link from '../helpers/Link.js'

export default class extends Component {
  componentDidMount () {
    document.documentElement.style.overflow = 'hidden'
  }

  componentWillUnmount () {
    document.documentElement.style.overflow = ''
  }

  render () {
    return (
      <div className='error--wrap'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Oh, snap! It's 404 time again!</title>
          <meta name="keywords" content="Brand Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="Moscow-based design studio just got another 404 error page! Damn!" />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="Moscow-based design studio just got another 404 error page! Damn!" />
          <meta property="og:title" content="Shuka Design Bureau 404 Error Page" />
          <meta property="og:type" content="website" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className='error--body'>
          <div className='error--message-wrap'>
            <div className='error--message-pic mars zero'>4<span className='icon'>p</span>4</div>
            <div className='error--message'>restart your computer<br/>and try again or<br/><Link to='/'><b className="underline"></b>get back to shuka</Link></div>
          </div>
        </div>
      </div>
    )
  }
}
