import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'

import og from '../../assets/team/og.png'

export default class extends Component {
  state = {
    team_members : []
  }

  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    document.removeEventListener('scrolled', this.onScroll)
  }

  componentDidMount () {
    document.documentElement.style.overflow = ''

    document.addEventListener('scrolled', this.onScroll)

	  /*
    const test = {
      api: '42Rf0Ds3p27b84F',
      link: 'https://test.shuka.design/api/team_members/list'
    }

    const production = {
      api: '42Rf0Ds3.p27b84F',
      link: '/api/team_members/list'
    }
    */

    const data = new FormData()
    data.append('_api_key', process.env.REACT_APP_API_KEY)

    fetch(process.env.REACT_APP_NEWS_TEAM_MEMBERS_URL, {
      method: 'POST',
      body: data
    })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad response from server")
        }
        return response.json()
      })
      .then((data) => {
        const { team_members, team_description, team_heading } = data._data

        this.setState({
          team_members,
          team_description,
          team_heading,
        })
      })
  }

  onScroll = () => {
    Object.values(this.refs).map((teamMemeber, idx) => {
      if (teamMemeber.getBoundingClientRect().top <= (window.innerHeight * .8)) {
        if (!teamMemeber.classList.contains('slide-in')) {
          teamMemeber.classList.add('slide-in')
        }
      }
    })
  }

  render () {
    const { team_members } = this.state

    return (
      <div className='case-root'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Team 👩‍💻 Contributors</title>
          <meta name="keywords" content="shuka, design portfolio, branding, identity, books, mazagines" />
          <meta name="description" content="Everything that is great is comprised of smaller things, by definition. Ditto for Shuka." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="Everything that is great is comprised of smaller things, by definition. Ditto for Shuka." />
          <meta property="og:title" content="Team 👩‍💻 Contributors" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <TouchNav navBg='transparent' color='dark' />
          <CaseHead leftTitle='about:' rightTitle={this.state.team_heading} menuColor='dark' isTech='true' />
        </div>
        <div className='head-line Cf' style={{marginBottom: '100px'}}>
          <div className="head-line-start icon zero">e i e</div>
          <p className="head-line-end mercury" dangerouslySetInnerHTML={{ __html: this.state.team_description }}></p>
        </div>
        <div className='portfolio at-main at-about'>
          { team_members.map((team_member, idx) => {
              return (
                <div className="portfolio-cell" ref={`teamMemeber${idx + 1}`} key={idx}>
                  <span className="team-href Cf">
                    { team_member.name_hyphen
                      ? <span className="team-title mars">
                          <span dangerouslySetInnerHTML={{ __html: team_member.name.split(' ').join('<br/>') }}></span>
                          { team_member.image_url !== null && <sup className="icon team-icon"> {team_member.icon}</sup> }
                          <sup className="portfolio-title-index mercury nobr" style={{display: 'inline-block'}}>{team_member.position}</sup>
                        </span>
                      : <span className="team-title mars">
                          { team_member.icon_in_the_middle
                            ? <span dangerouslySetInnerHTML={{ __html: team_member.name.split(' ').join(`<sup class="icon team-icon"> ${team_member.icon}</sup><br/>`) }}></span>
                            : <span>{ team_member.name }{ team_member.image_url !== null && <sup className="icon team-icon"> {team_member.icon}</sup> }<br/></span>
                          }
                          { team_member.icon_in_the_middle
                            ? <sup className="portfolio-title-index mercury nobr" style={{display: 'inline-block'}}> { team_member.position }</sup>
                            : <span className="portfolio-title-index mercury nobr">{team_member.position}</span>
                          }
                        </span>
                    }
                    <div className="team-src">
                      <div>
                        { team_member.image_url === null
                          ? <sup className="icon team-avatar">{team_member.icon}</sup>
                          : <img src={team_member.image_url} alt='team member' />
                        }
                      </div>
                    </div>
                  </span>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}
