import React, { Component } from 'react'
import * as css from 'classnames'
import CircleType from 'circletype'

import Link from '../helpers/Link.js'

export default class extends Component {
  state = {
    rotated: false
  }

  checkState = (ev) => {
    this.setState(prev => {
      return {
        rotated: !prev.rotated
      }
    })
  }

  render () {
    return (
      <div className={`nav-body nav-body--r no-touch pluto ${this.props.color}`}>
        <div className="nav-list nav-list--r">
          <Link setPreset={this.props.setPreset} setExitAnimation={this.props.setExitAnimation} to="/contact" className={css('nav-a', 'nav-a--magin-l-r', 'caps', {'is-active': this.props.page.includes('/contact')})} data-action="tap"><b className="underline"></b>Contact</Link>
          <Link setPreset={this.props.setPreset} setExitAnimation={this.props.setExitAnimation} to="/publications" className={css('nav-a', 'nav-a--magin-l-r', 'caps', {'is-active': this.props.page.includes('/publications')})} data-action="tap"><b className="underline"></b>Publications</Link>
        </div>
        {/*<div className={css("emblem", "zero", "caps", "pluto", `${this.props.color}`, {'rotated': this.state.rotated})} onMouseLeave={this.checkState}>
          <span style={{transform: 'rotateZ(0deg) translate3d(0px, 0px, 0px)'}}>O</span>
          <span style={{transform: 'rotateZ(10.909090909090908deg) translate3d(0px, 0px, 0px)'}}>r</span>
          <span style={{transform: 'rotateZ(21.818181818181817deg) translate3d(0px, 0px, 0px)'}}>d</span>
          <span style={{transform: 'rotateZ(32.72727272727273deg) translate3d(0px, 0px, 0px)'}}>e</span>
          <span style={{transform: 'rotateZ(43.63636363636363deg) translate3d(0px, 0px, 0px)'}}>r</span>
          <span style={{transform: 'rotateZ(54.54545454545454deg) translate3d(0px, 0px, 0px)'}}> </span>
          <span style={{transform: 'rotateZ(65.45454545454545deg) translate3d(0px, 0px, 0px)'}}>b</span>
          <span style={{transform: 'rotateZ(76.36363636363636deg) translate3d(0px, 0px, 0px)'}}>r</span>
          <span style={{transform: 'rotateZ(87.27272727272727deg) translate3d(0px, 0px, 0px)'}}>a</span>
          <span style={{transform: 'rotateZ(98.18181818181817deg) translate3d(0px, 0px, 0px)'}}>n</span>
          <span style={{transform: 'rotateZ(109.09090909090908deg) translate3d(0px, 0px, 0px)'}}>d</span>
          <span style={{transform: 'rotateZ(117deg) translate3d(0px, 0px, 0px)'}}>i</span>
          <span style={{transform: 'rotateZ(125.91deg) translate3d(0px, 0px, 0px)'}}>n</span>
          <span style={{transform: 'rotateZ(137.2deg) translate3d(0px, 0px, 0px)'}}>g</span>
          <span style={{transform: 'rotateZ(152.72727272727272deg) translate3d(0px, 0px, 0px)'}}> </span>
          <span style={{transform: 'rotateZ(163.63636363636363deg) translate3d(0px, 0px, 0px)'}}>З</span>
          <span style={{transform: 'rotateZ(174.54545454545453deg) translate3d(0px, 0px, 0px)'}}>а</span>
          <span style={{transform: 'rotateZ(185.45454545454544deg) translate3d(0px, 0px, 0px)'}}>к</span>
          <span style={{transform: 'rotateZ(196.36363636363635deg) translate3d(0px, 0px, 0px)'}}>а</span>
          <span style={{transform: 'rotateZ(207.27272727272725deg) translate3d(0px, 0px, 0px)'}}>з</span>
          <span style={{transform: 'rotateZ(218.18181818181816deg) translate3d(0px, 0px, 0px)'}}>а</span>
          <span style={{transform: 'rotateZ(229.09090909090907deg) translate3d(0px, 0px, 0px)'}}>т</span>
          <span style={{transform: 'rotateZ(239.99999999999997deg) translate3d(0px, 0px, 0px)'}}>ь</span>
          <span style={{transform: 'rotateZ(250.90909090909088deg) translate3d(0px, 0px, 0px)'}}> </span>
          <span style={{transform: 'rotateZ(261.8181818181818deg) translate3d(0px, 0px, 0px)'}}>б</span>
          <span style={{transform: 'rotateZ(272.7272727272727deg) translate3d(0px, 0px, 0px)'}}>р</span>
          <span style={{transform: 'rotateZ(283.6363636363636deg) translate3d(0px, 0px, 0px)'}}>е</span>
          <span style={{transform: 'rotateZ(294.5454545454545deg) translate3d(0px, 0px, 0px)'}}>н</span>
          <span style={{transform: 'rotateZ(305.45454545454544deg) translate3d(0px, 0px, 0px)'}}>д</span>
          <span style={{transform: 'rotateZ(316.3636363636363deg) translate3d(0px, 0px, 0px)'}}>и</span>
          <span style={{transform: 'rotateZ(327.27272727272725deg) translate3d(0px, 0px, 0px)'}}>н</span>
          <span style={{transform: 'rotateZ(338.18181818181813deg) translate3d(0px, 0px, 0px)'}}>г</span>
          <span style={{transform: 'rotateZ(349.09090909090907deg) translate3d(0px, 0px, 0px)'}}> </span>
        </div>*/}
      </div>
    )
  }
}
