import React, { Component, Suspense } from 'react'
import * as css from 'classnames'
import Lottie from 'react-lottie'
import ReactBodymovin from 'react-bodymovin'
import anime from 'animejs'
import SVG from 'react-inlinesvg'

import Link from '../helpers/Link.js'

export default class extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isVisible: props.slideIn,
      bodymovinOptions: {}
    }

    this.setFlatplanAnim = this.setFlatplanAnim.bind(this)
    this.setBrobrolabAnim = this.setBrobrolabAnim.bind(this)
    this.setSemrushAnim = this.setSemrushAnim.bind(this)
  }

  componentDidMount () {
    const projectCell = this.refs.projectCell
    const animeName = this.props['data-anime']

    this.onScroll()

    document.addEventListener('scrolled', this.onScroll)

    if (this.props['data-bodymovin'] !== undefined) {
      fetch(`${this.props['data-bodymovin']}`).then(res => res.json()).then(data => {
        this.setState({
          bodymovinOptions: {
            loop: this.props.link === '/flacon' ? false : true,
            autoplay: false,
            prerender: true,
            animationData: data
          }
        })
      })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('scrolled', this.onScroll)
  }

  onScroll = (ev) => {
    const projectCell = this.refs.projectCell
    const animeName = this.props['data-anime']

    if (projectCell.getBoundingClientRect().top <= (window.innerHeight * .8)) {
      this.setState(state => {
        if (Object.entries(this.state.bodymovinOptions).length > 0) {
          return {
            bodymovinOptions: Object.assign(state.bodymovinOptions, {
              autoplay: true
            }),
            isVisible: true
          }
        } else {
          return {
            isVisible: true
          }
        }
      })

      if (animeName === 'flatplan') {
        this.setFlatplanAnim()
      } else if (animeName === 'sexispure') {
        const paths = [].slice.call(projectCell.querySelectorAll('.sexispure-path'))
        paths.forEach((path) => {
          path.classList.add('sexispure-path-play')
        })
      } else if (animeName === 'osome') {
        const sign = projectCell.querySelector('.sign')
        sign.classList.add('animated')
      } else if (animeName === 'brobrolab') {
        const workLogo = document.querySelector('.brobro-workpage')
        if (workLogo !== null) this.setBrobrolabAnim(workLogo)
      } else if (animeName === 'semrush') {
        const workLogo = document.querySelector('.semrush-workpage')
        if (workLogo !== null) this.setSemrushAnim(workLogo)
      }

      document.removeEventListener('scrolled', this.onScroll)
    }
  }

  setSemrushAnim(workLogo) {
    const workLogoAnimMob = anime({
      targets: workLogo,
      rotate: '-360deg',
      offset: 0,
      duration: 2000,
      easing: 'easeInOutQuart',
      direction: 'normal',
      autoplay: false,
      loop: false
    })

    const workLogoAnim = anime({
      targets: workLogo,
      rotate: '-360deg',
      translateY: 0,
      offset: 0,
      duration: 2000,
      easing: 'easeInOutQuart',
      direction: 'normal',
      autoplay: false,
      loop: false
    });

    workLogo.style.transformOrigin = '50% 50%'

    let initWindowWidth = window.innerWidth

    if (initWindowWidth < 984) {
      workLogoAnimMob.restart()
    } else {
      workLogo.addEventListener('mouseenter', function() {
        if (workLogoAnim.progress === 100 || workLogoAnim.progress === 0) {
          workLogoAnim.play()
        }
      })
    }

    window.addEventListener('resized', function() {
      if (initWindowWidth < 984 && window.innerWidth > 983) {
        workLogoAnimMob.pause()
        workLogo.addEventListener('mouseenter', function() {
          if (workLogoAnim.progress === 100 || workLogoAnim.progress === 0) {
            workLogoAnim.restart()
          }
        })
        initWindowWidth = window.innerWidth
      } else if (initWindowWidth > 983 && window.innerWidth < 984) {
        workLogoAnimMob.play()
        workLogo.removeEventListener('mouseenter', function() {
          if (workLogoAnim.progress === 100 || workLogoAnim.progress === 0) {
            workLogoAnim.restart()
          }
        })
        initWindowWidth = window.innerWidth
      }
    })
  }

  setBrobrolabAnim (workLogo) {
    const workLogoAnimMob = anime({
      targets: workLogo,
      rotate: '1080deg',
      offset: 900,
      duration: 2400,
      direction: 'normal',
      autoplay: false,
      loop: true
    })

    const workLogoAnim = anime({
      targets: workLogo,
      rotate: '1080deg',
      offset: 0,
      duration: 2400,
      direction: 'normal',
      autoplay: false,
      loop: false
    });

    // const bboxworkLogo = workLogo.getBBox()
    // const centerworkLogo = {
    //    x: bboxworkLogo.x + bboxworkLogo.width / 2,
    //    y: bboxworkLogo.y  + bboxworkLogo.height / 2
    // }
    // workLogo.style.transformOrigin = centerworkLogo.x + 'px ' + centerworkLogo.y + 'px'
    workLogo.style.transformOrigin = '50% 50%'

    let initWindowWidth = window.innerWidth

    if (initWindowWidth < 984) {
      workLogoAnimMob.restart()
    } else {
      workLogo.addEventListener('mouseenter', function() {
        if (workLogoAnim.progress === 100 || workLogoAnim.progress === 0) {
          workLogoAnim.play()
        }
      })
    }

    window.addEventListener('resized', function() {
      if (initWindowWidth < 984 && window.innerWidth > 983) {
        workLogoAnimMob.pause()
        workLogo.addEventListener('mouseenter', function() {
          if (workLogoAnim.progress === 100 || workLogoAnim.progress === 0) {
            workLogoAnim.restart()
          }
        })
        initWindowWidth = window.innerWidth
      } else if (initWindowWidth > 983 && window.innerWidth < 984) {
        workLogoAnimMob.play()
        workLogo.removeEventListener('mouseenter', function() {
          if (workLogoAnim.progress === 100 || workLogoAnim.progress === 0) {
            workLogoAnim.restart()
          }
        })
        initWindowWidth = window.innerWidth
      }
    })
  }

  setFlatplanAnim () {
    this.flatplanLogoSmall = anime.timeline({
      autoplay: true,
      direction: 'normal',
      loop: false
    })

    this.flatplanLogoSmall
    .add({
      targets: '.flatplan-logo-small .step-1',
      opacity: 1,
      duration: 1,
      offset: 300
    })
    .add({
      targets: '.flatplan-logo-small .step-2',
      opacity: 1,
      duration: 1,
      offset: 400
    })
    .add({
      targets: '.flatplan-logo-small .step-3',
      opacity: 1,
      duration: 1,
      offset: 500
    })
    .add({
      targets: '.flatplan-logo-small .step-4',
      opacity: 1,
      duration: 1,
      offset: 800
    })
    .add({
      targets: '.flatplan-logo-small .step-5',
      opacity: 1,
      duration: 1,
      offset: 900
    })
    .add({
      targets: '.flatplan-logo-small .step-6',
      opacity: 1,
      duration: 1,
      offset: 1000
    })
    .add({
      targets: '.flatplan-logo-small .step-7',
      opacity: 1,
      duration: 400,
      easing: 'easeInOutQuart',
      offset: 1300
    })
    .add({
      targets: '.flatplan-logo-small .step-8',
      opacity: 1,
      duration: 400,
      easing: 'easeInOutQuart',
      offset: 1700
    })
    .add({
      targets: '.flatplan-logo-small .step-9',
      translateY: [150,0],
      duration: 300,
      easing: 'easeInOutQuart',
      offset: 2100
    })
    .add({
      targets: '.flatplan-logo-small .step-10',
      translateY: [-150,0],
      duration: 300,
      easing: 'easeInOutQuart',
      offset: 2400
    })
  }

  render () {
    const name = this.props['data-bodymovin']
    let bodymovinOptions
    let imgFormat

    if (this.props.img !== undefined) {
      imgFormat = this.props.img.substr(this.props.img.length - 3)
    } else if (this.props.custom !== undefined) {
      imgFormat = 'custom'
    }

    if (name !== undefined) {
      imgFormat = 'bodymovin'
    }

    return (
      <div className={css("portfolio-cell", {'slide-in': this.state.isVisible})} data-parallax-item ref='projectCell'>
        <div className="portfolio-cell-inner">
          { (this.props.link !== undefined) &&
            <span className={css("portfolio-title", "mars", {
                'portfolio-title--iitp': (this.props.link === '/iitp-identity'),
                'portfolio-title--flatplan': (this.props.link === '/flatplan'),
                'portfolio-title--basp': (this.props.link === '/british-art-school-posters'),
                'portfolio-title--pure-app': (this.props.link === '/pure-app'),
                'portfolio-title--pure-dating': (this.props.link === '/pure-dating'),
              })}>
              <Link setPreset={this.props.setPreset} setExitAnimation={this.props.setExitAnimation} to={this.props.link} className="portfolio-href" content={this.props.title} subtitle={this.props.subtitle} sublink={this.props.sublink}></Link>
            </span>
          }
          { (this.props.text !== undefined) && <p className="portfolio-title pluto">{this.props.text}</p> }
          <Link setPreset={this.props.setPreset} setExitAnimation={this.props.setExitAnimation} className="portfolio-href portfolio-href--image" to={this.props.link}>
            <div className={css("portfolio-src", {
                'portfolio-src--bg': this.props.background,
                'portfolio-src--pure-dating': (this.props.link === '/pure-dating'),
                'portfolio-src--basp': (this.props.link === '/british-art-school-posters'),
                'portfolio-src--untitled': (this.props.link === '/the-untitled'),
                'portfolio-src--fragrance-library': (this.props.link === '/fragrance-library'),
                'portfolio-src--prm2': (this.props.link === '/the-prime-russian-magazine-part-2'),
                'portfolio-src--prm1': (this.props.link === '/the-prime-russian-magazine-part-1'),
                'portfolio-src--soul': (this.props.link === '/soul'),
                'portfolio-src--armageddon': (this.props.link === '/armageddon')
              })}>
              <div className="portfolio-src-inner" data-bodymovin={this.props['data-bodymovin']} style={{width: this.props.width}}>
                <Suspense>
                  { imgFormat === 'svg'
                    ? <SVG src={this.props.img} />
                    : (imgFormat === 'bodymovin' && (Object.entries(this.state.bodymovinOptions).length > 0))
                      ? <Lottie options={this.state.bodymovinOptions} />
                      : (imgFormat === 'custom')
                        ? <span dangerouslySetInnerHTML={{ __html: this.props.custom }}></span>
                        : <img alt='case preview' className="portfolio-img" src={this.props.img} onLoad={ev => ev.target.style.opacity = 1}/>
                  }
                </Suspense>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}
