import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'

import Link from '../../helpers/Link'

import img01 from '../../assets/main/01.gif'
import img02 from '../../assets/main/02.jpg'
import img03 from '../../assets/main/03.jpg'
import img04 from '../../assets/main/04.jpg'
import img05 from '../../assets/main/05.jpg'

export default class extends Component {
  state = {
    temperature: '0°C',
    condition: 'cloudy'
  }

  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentDidMount () {
  	/*
    const test = {
      api: '42Rf0Ds3p27b84F',
      link: 'https://test.shuka.design/api/settings/weather'
    }

    const production = {
      api: '42Rf0Ds3.p27b84F',
      link: '/api/settings/weather'
    }
    */

    const data = new FormData()
	  data.append('_api_key', process.env.REACT_APP_API_KEY)

	  fetch(process.env.REACT_APP_WEATHER_URL, {
		  method: 'POST',
		  body: data
	  })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad response from server")
        }
        return response.json()
      })
      .then((data) => {
        const { condition, temperature} = data._data

        this.setState({
          temperature,
          condition
        })
      })
  }

  render () {
    return (
      <div className='main-wrap'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka ★ Design Bureau</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="Moscow-based design studio. Tailoring identities. Producing websites. Making books. Being cool." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="Tailoring identities. Producing  websites. Making books. Being cool." />
          <meta property="og:title" content="Shuka Design Bureau" />
          <meta property="og:type" content="website" />
        </Helmet>
        <TouchNav />
        <header className="head new">
          <div className="head-body">
            <div className="head-body--inner">
              <Link to="/" className="head-logo mars">
                <div className='head-logo-text'>
                  <div className="head-logo-text--main"><span>shuka</span><span className="umlaut">shüka</span></div>
                  <div className="head-lead-alpha is-hidden--mob"><span className="mars zero">is</span></div>
                  <h2 className="head-lead-alpha--second mars zero">
                    <span className="is-hidden--desk">Is</span> the design <nobr><span className="icon">a</span> studio</nobr> <nobr><span className="icon">-</span> from</nobr> <nobr>{this.state.condition} <sup className="head-lead-small">{this.state.temperature}</sup></nobr><nobr><span className="icon is-hidden--desk"> f</span> moscow <span className="icon is-hidden--mob">f</span></nobr>
                  </h2>
                </div>
              </Link>
            </div>
          </div>
        </header>
        <div className='portfolio at-main'>
          <div className="section section-s">
            <div className="section-body">
              <Link to='/soul' className="section-link">
                <p className="section-head mars">s</p>
                <img src={img01} alt="" className="section-img" />
                <p className="section-caption mars">is for <span className="icon">e</span> soul</p>
              </Link>
            </div>
          </div>

          <div className="section section-h">
            <div className="section-body">
              <Link to='/moscow-heritage-2012' className="section-link">
                <p className="section-head--odd mars">h</p>
                <img src={img02} alt="" className="section-img--odd" />
                <p className="section-caption--odd mars">is for <span className="icon">r</span> heritage</p>
              </Link>
            </div>
          </div>

          <div className="section section-u">
            <div className="section-body">
              <Link to="/british-art-school-posters" className="section-link">
                <p className="section-head mars">u</p>
                <img src={img03} alt="" className="section-img" />
                <p className="section-caption mars">is for ultramodern</p>
              </Link>
            </div>
          </div>

          <div className="section section-k">
            <div className="section-body">
              <Link to="/pure-app" className="section-link">
                <p className="section-head--odd mars">k</p>
                <img src={img04} alt="" className="section-img--odd" />
                <p className="section-caption--odd mars">is for kinky <span className="icon">l</span></p>
              </Link>
            </div>
          </div>

          <div className="section section-a">
            <div className="section-body">
              <span className="section-link zero">
                <p className="section-head mars">a</p>
                <img src={img05} alt="" className="section-img" />
                <p className="section-caption mars">is for <nobr>american <span className="icon gold" style={{ position: 'relative', fontSize: '1.4em', top: '0.09em'}}>$</span></nobr></p>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
