import React, { Component, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import PortfolioCell from './PortfolioCell'
import Link from '../helpers/Link.js'

import TouchNav from './TouchNav'
import Preloader from './Preloader'
import og from '../assets/portfolio/os-porto.png'

export default class extends Component {
  state = {
    works: []
  }

  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentDidMount () {
    // if (!aidsRef.current.parentNode.classList.contains('is-loaded')) {
    //   aidsRef.current.parentNode.classList.add('is-loaded')
    //   window.scrollTo(0,0)
    //
    //   aidsRef.current.parentNode.previousSibling.style.position = 'fixed'
    //   aidsRef.current.parentNode.style.position = 'relative'
    //   document.documentElement.style.overflow = ''
    // }

	  /*
    const test = {
      api: '42Rf0Ds3p27b84F',
      link: 'https://test.shuka.design/api/works/list'
    }

    const production = {
      api: '42Rf0Ds3.p27b84F',
      link: '/api/works/list'
    }
    */

    const data = new FormData()
    data.append('_api_key', process.env.REACT_APP_API_KEY)

    fetch(process.env.REACT_APP_WORK_URL, {
      method: 'POST',
      body: data
    })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad response from server")
        }
        return response.json()
      })
      .then((data) => {
        const { works } = data._data

        this.setState({
          works
        })
      })
  }

  render () {
    const { works } = this.state

    return (
      <div className='main-wrap'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka ⚒ Deed</title>
          <meta name="keywords" content="shuka, design portfolio, branding, identity, books, mazagines" />
          <meta name="description" content="Branding. Identity. Design. Lots of our past works. Put together neatly on a single page" />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="Branding. Identity. Design. Lots of our past works. Put together neatly on a single page" />
          <meta property="og:title" content="Shuka ⚒ Deed" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <TouchNav />
        <header className="head margin-bottom--med-small">
          <div className="head-body">
            <div className="Cf head-body--inner">
              <Link to="/" className="head-logo Mb(0)--md mars">
                <span className="head-logo-icon on-touch"></span>
                <span className="head-logo-text">Shuka</span>
              </Link>
            </div>
          </div>
        </header>
        { (works.length === 0)
          ? <Preloader />
        : <div className='portfolio at-main' style={{overflow: 'hidden'}}>
              {
                works.map((work, idx) => {
                  const anime = (work.slug === 'semrush') ? 'semrush' : (work.slug === 'sex-is-pure') ? 'sexispure' : (work.slug === 'osome') ? 'osome' : (work.slug === 'brobrolab-identity') ? 'brobrolab' : (work.slug === 'flatplan') ? 'flatplan' : null

                  const opts = Object.assign({},
                    (work.name !== undefined) && { title: work.name },
                    (work.image_url !== undefined) && { img: work.image_url },
                    (work.custom_html !== undefined) && { custom: work.custom_html },
                    (work.slug === 'lamoda') && { background: true },
                    (work.subname !== undefined) && { subtitle: work.subname },
                    (work.description !== undefined) && { text: work.description },
                    (work.slug !== undefined) && { link: `/${work.slug}` },
                    (work.width_percentage !== undefined) && { width: `${work.width_percentage}%` },
                    (work.json_url !== undefined) && { ['data-bodymovin']: work.json_url },
                    (anime !== null) && { ['data-anime']: anime },
                  )

                  return (
                    <Suspense fallback={<div className='portfolio-cell'>Loading...</div>} key={`case-${idx}`}>
                      <PortfolioCell setPreset={this.props.setPreset} setExitAnimation={this.props.setExitAnimation} slideIn={idx === 0 || idx === 1}
                        {...opts} />
                    </Suspense>
                  )
                })
              }
            </div>
          }
      </div>
    )
  }
}
