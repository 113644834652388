import React, { Component, Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'
import * as css from 'classnames'
import Link from '../../helpers/Link.js'

import TouchNav from '../TouchNav'

const og = `${process.env.REACT_APP_MEDIA}/media/og3.png`
const chess_eyes = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/chess/girl_eyes.png`
const flacon = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/flacon_sign.svg`
const chess = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/chess/chess.jpg`
const nike = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/nike/nike.jpg`
const run = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/nike/Nike_kv.svg`
const lamoda = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/lamoda_main_logo.svg`
const ba = `${process.env.REACT_APP_MEDIA}/media/work-preview-new/biblioteka_main.jpg`
const valya2 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/valya-two.png`
const valya0 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/valya-zero.png`
const marina2 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/marina-two.svg`
const marina0 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/marina-zero.svg`
const dima2 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/dima-two.svg`
const dima0 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/dima-zero.svg`
const mashiq2 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/mashiq_2.svg`
const mashiq0 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/mashiq_0.svg`
const varya2 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/varya-two.svg`
const varya0 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/varya-zero.svg`
const dasha2 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/dasha-02.svg`
const dasha0 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/dasha-01.svg`
const kostya2 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/kostya-2.png`
const kostya0 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/kostya-0.png`
const sasha0 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/sasha_0.png`
const sasha2 = `${process.env.REACT_APP_MEDIA}/media/facades/2020/sasha_2.png`

const PortfolioCellLarge = lazy(() => import('../PortfolioCellLarge'));
const PortfolioCellNew = lazy(() => import('../PortfolioCellNew'));
const SubjectCases = lazy(() => import('../SubjectCases'));

const zeros = [
  {img: kostya0, word: 'toxicity', by: 'by Konstantin Frolov'},
  {img: dasha0, word: 'doodlebugs', by: 'by Dasha Zudina'},
  {img: valya0, word: 'messy', by: 'by Valya Lazareva'},
  {img: marina0, word: 'less', by: 'by Marina Gaiman'},
  {img: dima0, word: 'dry', by: 'by Dmitry Okulich-Kazarin'},
  {img: mashiq0, word: 'hangover', by: 'by Mashiq'},
  {img: varya0, word: 'headache', by: 'by Varya Goncharova'},
  {img: sasha0, word: 'for bad kids', by: 'by Alexander Koltsov'},
]

const zeros1 = [
  {img: marina0, word: 'less', by: 'by Marina Gaiman'},
  {img: valya0, word: 'messy', by: 'by Valya Lazareva'},
  {img: kostya0, word: 'toxicity', by: 'by Konstantin Frolov'},
  {img: dasha0, word: 'doodlebugs', by: 'by Dasha Zudina'},
]

const zeros2 = [
  {img: varya0, word: 'headache', by: 'by Varya Goncharova'},
  {img: dima0, word: 'dry', by: 'by Dmitry Okulich-Kazarin'},
  {img: sasha0, word: 'for bad kids', by: 'by Alexander Koltsov'},
  {img: mashiq0, word: 'hangover', by: 'by Mashiq'},
]

const twos = [
  {img: kostya2, word: 'efficiency', by: 'by Konstantin Frolov'},
  {img: dasha2, word: 'gardens', by: 'by Dasha Zudina'},
  {img: valya2, word: 'cozy', by: 'by Valya Lazareva'},
  {img: marina2, word: 'more', by: 'by Marina Gaiman'},
  {img: dima2, word: 'juicy', by: 'by Dmitry Okulich-Kazarin'},
  {img: mashiq2, word: 'x-mas', by: 'by Mashiq'},
  {img: varya2, word: 'likes', by: 'by Varya Goncharova'},
  {img: sasha2, word: 'for good kids', by: 'by Alexander Koltsov'},
]

const twos1 = [
  {img: kostya2, word: 'efficiency', by: 'by Konstantin Frolov'},
  {img: dasha2, word: 'gardens', by: 'by Dasha Zudina'},
  {img: valya2, word: 'cozy', by: 'by Valya Lazareva'},
  {img: marina2, word: 'more', by: 'by Marina Gaiman'}
]

const twos2 = [
  {img: dima2, word: 'juicy', by: 'by Dmitry Okulich-Kazarin'},
  {img: mashiq2, word: 'x-mas', by: 'by Mashiq'},
  {img: varya2, word: 'likes', by: 'by Varya Goncharova'},
  {img: sasha2, word: 'for good kids', by: 'by Alexander Koltsov'},
]

export default class extends Component {
  state = {
    work1Ran: false,
    work2Ran: false,
    showText: false,
    number0: zeros[1],
    number0_2: zeros[2],
    number0_visible: zeros[1],
    number2: twos1[0],
    number2_visible: twos2[0],
    number2_2: twos2[0],
    zeroAll: zeros,
    twoAll: twos,
    number2_mob: twos[1],
    number0_mob: zeros[2],
    number_visible_mob: zeros[0],
    startMob: false
  }

  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    document.removeEventListener('scrolled', this.onScroll)
  }

  componentDidMount() {
    document.addEventListener('scrolled', this.onScroll)

    let animStep2 = 1
    let animStep0 = 1
    let animStepMob = 1
    let iteration2_mob = 1
    let iteration0_mob = 6

    const number2FB_mob = () => {
      switch (animStepMob) {
        case 1:
          this.setState(state => {
            return {
              number_visible_mob: state.number0_mob,
            }
          })

          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 2:
          this.setState(state => {
            return {
              number_visible_mob: state.number2_mob,
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 3:
          this.setState(state => {
            return {
              number_visible_mob: state.number0_mob,
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 4:
          this.setState(state => {
            return {
              number_visible_mob: state.number2_mob,
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        default:
          this.setState(state => {
            return {
              number_visible_mob: state.number0_mob,
              showText: true
            }
          })
      }

      setTimeout(() => {
        this.setState({
          showText: false
        })

        setTimeout(() => {
          switch (animStepMob) {
            case 1:
              if (iteration2_mob >= (this.state.twoAll.length - 1)) {
                iteration2_mob = 0
              } else {
                iteration2_mob++
              }

              this.setState( state => {
                return {
                  number2_mob: state.twoAll[iteration2_mob],
                }
              })

              this.refs.number2_mob.classList.remove('animation__2_step_4')
              this.refs.number0_mob.classList.remove('animation__0_step_4')
              this.refs.number2_mob.classList.add('animation__2_step_2')
              this.refs.number0_mob.classList.add('animation__0_step_2')

              animStepMob++
              break
            case 2:
              if (iteration0_mob >= (this.state.zeroAll.length - 1)) {
                iteration0_mob = 0
              } else {
                iteration0_mob++
              }

              this.setState( state => {
                return {
                  number0_mob: state.zeroAll[iteration0_mob],
                }
              })

              this.refs.number2_mob.classList.remove('animation__2_step_1')
              this.refs.number0_mob.classList.remove('animation__0_step_1')
              this.refs.number2_mob.classList.add('animation__2_step_3')
              this.refs.number0_mob.classList.add('animation__0_step_3')

              animStepMob++
              break
            case 3:
              if (iteration2_mob >= (this.state.twoAll.length - 1)) {
                iteration2_mob = 0
              } else {
                iteration2_mob++
              }

              this.setState( state => {
                return {
                  number2_mob: state.twoAll[iteration2_mob],
                }
              })

              this.refs.number2_mob.classList.remove('animation__2_step_2')
              this.refs.number0_mob.classList.remove('animation__0_step_2')
              this.refs.number2_mob.classList.add('animation__2_step_4')
              this.refs.number0_mob.classList.add('animation__0_step_4')

              animStepMob++
              break
            case 4:
              if (iteration0_mob >= (this.state.zeroAll.length - 1)) {
                iteration0_mob = 0
              } else {
                iteration0_mob++
              }

              this.setState( state => {
                return {
                  number0_mob: state.zeroAll[iteration0_mob],
                }
              })

              this.refs.number2_mob.classList.remove('animation__2_step_3')
              this.refs.number0_mob.classList.remove('animation__0_step_3')
              this.refs.number2_mob.classList.remove('animation__2_step_4')
              this.refs.number0_mob.classList.remove('animation__0_step_4')
              this.refs.number2_mob.classList.add('animation__2_step_1')
              this.refs.number0_mob.classList.add('animation__0_step_1')

              animStepMob = 1
              break
            default:
              if (iteration2_mob >= (this.state.twoAll.length - 1)) {
                iteration2_mob = 0
              } else {
                iteration2_mob++
              }

              this.setState( state => {
                return {
                  number2_mob: state.twoAll[iteration2_mob],
                }
              })

              this.refs.number2_mob.classList.remove('animation__2_step_4')
              this.refs.number0_mob.classList.remove('animation__0_step_4')
              this.refs.number2_mob.classList.add('animation__2_step_2')
              this.refs.number0_mob.classList.add('animation__0_step_2')

              animStepMob++
          }
        }, 140)
      }, 2500)
    }

    const number2FB = () => {
      switch (animStep2) {
        case 1:
          this.setState(state => {
            return {
              number2_visible: twos2[0],
            }
          })

          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 2:
          this.setState(state => {
            return {
              number2_visible: twos1[1],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 3:
          this.setState(state => {
            return {
              number2_visible: twos2[1],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 4:
          this.setState(state => {
            return {
              number2_visible: twos1[2],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 5:
          this.setState(state => {
            return {
              number2_visible: twos2[2],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 6:
          this.setState(state => {
            return {
              number2_visible: twos1[3],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 7:
          this.setState(state => {
            return {
              number2_visible: twos2[3],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 8:
          this.setState(state => {
            return {
              number2_visible: twos1[0],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        default:
          this.setState(state => {
            return {
              number2_visible: state.number2_2,
              showText: true
            }
          })
      }

      setTimeout(() => {
        this.setState({
          showText: false
        })

        setTimeout(() => {
          switch (animStep2) {
            case 1:
              this.refs['number2_reverse-0'].classList.add('animation__0_step_2')
              this.refs['number2-1'].style.opacity = ''
              this.refs['number2-1'].classList.add('animation__2_step_2')
              this.refs['number2-1'].classList.remove('animation__2_step_3')
              this.refs['number2_reverse-1'].classList.remove('animation__0_step_3')

              animStep2++
              break
            case 2:
              this.refs['number2-1'].classList.add('animation__2_step_3')
              this.refs['number2_reverse-1'].style.opacity = ''
              this.refs['number2_reverse-1'].classList.add('animation__0_step_3')
              this.refs['number2-2'].classList.remove('animation__2_step_4')
              this.refs['number2_reverse-1'].classList.remove('animation__0_step_4')

              animStep2++
              break
            case 3:
              this.refs['number2-2'].classList.remove('animation__2_step_1')
              this.refs['number2-2'].classList.add('animation__2_step_4')
              this.refs['number2-2'].style.opacity = ''
              this.refs['number2_reverse-1'].classList.add('animation__0_step_4')
              this.refs['number2-2'].classList.remove('animation__2_step_1')
              this.refs['number2_reverse-2'].classList.remove('animation__0_step_1')

              animStep2++
              break
            case 4:
              this.refs['number2-2'].classList.remove('animation__2_step_4')
              this.refs['number2-2'].classList.add('animation__2_step_1')
              this.refs['number2_reverse-2'].style.opacity = ''
              this.refs['number2_reverse-2'].classList.add('animation__0_step_1')
              this.refs['number2_reverse-2'].classList.remove('animation__0_step_2')
              this.refs['number2-3'].classList.remove('animation__2_step_2')

              animStep2++
              break
            case 5:
              this.refs['number2_reverse-2'].classList.add('animation__0_step_2')
              this.refs['number2-3'].style.opacity = ''
              this.refs['number2-3'].classList.add('animation__2_step_2')
              this.refs['number2-3'].classList.remove('animation__2_step_3')
              this.refs['number2_reverse-3'].classList.remove('animation__0_step_3')

              animStep2++
              break
            case 6:
              this.refs['number2-3'].classList.add('animation__2_step_3')
              this.refs['number2_reverse-3'].style.opacity = ''
              this.refs['number2_reverse-3'].classList.add('animation__0_step_3')
              this.refs['number2_reverse-3'].classList.remove('animation__0_step_4')
              this.refs['number2-0'].classList.remove('animation__2_step_4')

              animStep2++
              break
            case 7:
              this.refs['number2_reverse-3'].classList.add('animation__0_step_4')
              this.refs['number2-0'].style.opacity = ''
              this.refs['number2-0'].classList.remove('animation__2_step_1')
              this.refs['number2-0'].classList.add('animation__2_step_4')

              animStep2++
              break
            case 8:
              this.refs['number2-0'].classList.remove('animation__2_step_4')
              this.refs['number2-0'].classList.add('animation__2_step_1')
              this.refs['number2_reverse-0'].classList.add('animation__0_step_1')
              this.refs['number2_reverse-0'].classList.remove('animation__0_step_2')
              this.refs['number2-1'].classList.remove('animation__2_step_2')

              animStep2 = 1
              break
            default:
              this.refs['number2_reverse-0'].classList.add('animation__0_step_2')
              this.refs['number2-1'].style.opacity = ''
              this.refs['number2-1'].classList.add('animation__2_step_2')

              animStep2++
          }
        }, 140)
      }, 2500)
    }

    const number0FB = () => {
      switch (animStep0) {
        case 1:
          this.setState(state => {
            return {
              number0_visible: zeros1[0],
            }
          })

          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 2:
          this.setState(state => {
            return {
              number0_visible: zeros2[0],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 3:
          this.setState(state => {
            return {
              number0_visible: zeros1[1],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 4:
          this.setState(state => {
            return {
              number0_visible: zeros2[1],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 5:
          this.setState(state => {
            return {
              number0_visible: zeros1[2],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 6:
          this.setState(state => {
            return {
              number0_visible: zeros2[2],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 7:
          this.setState(state => {
            return {
              number0_visible: zeros1[3],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        case 8:
          this.setState(state => {
            return {
              number0_visible: zeros2[3],
            }
          })
          setTimeout(() => {
            this.setState({
              showText: true
            })
          }, 100)
          break
        default:
          this.setState(state => {
            return {
              number0_visible: zeros1[0],
              showText: true
            }
          })
      }

      setTimeout(() => {
        this.setState({
          showText: false
        })

        setTimeout(() => {
          switch (animStep0) {
            case 1:
              this.refs['number0_reverse-0'].classList.remove('animation__0_reverse_step_1')
              this.refs['number0_reverse-0'].classList.remove('animation__0_reverse_step_2')
              this.refs['number0_reverse-0'].classList.remove('animation__0_reverse_step_3')
              this.refs['number0-0'].classList.add('animation__2_reverse_step_2')
              this.refs['number0_reverse-0'].style.opacity = ''
              this.refs['number0_reverse-0'].classList.add('animation__0_reverse_step_2')

              animStep0++
              break
            case 2:
              this.refs['number0_reverse-1'].classList.remove('animation__0_reverse_step_3')
              this.refs['number0_reverse-1'].classList.remove('animation__0_reverse_step_4')
              this.refs['number0_reverse-0'].classList.add('animation__0_reverse_step_3')
              this.refs['number0-1'].style.opacity = ''
              this.refs['number0-1'].classList.add('animation__2_reverse_step_3')
              this.refs['number0-1'].classList.remove('animation__2_reverse_step_4')

              animStep0++
              break
            case 3:
              this.refs['number0-1'].classList.add('animation__2_reverse_step_4')
              this.refs['number0_reverse-1'].style.opacity = ''
              this.refs['number0_reverse-1'].classList.add('animation__0_reverse_step_4')
              this.refs['number0-2'].classList.remove('animation__2_reverse_step_1')
              this.refs['number0_reverse-1'].classList.remove('animation__0_reverse_step_1')

              animStep0++
              break
            case 4:
              this.refs['number0_reverse-1'].classList.remove('animation__0_reverse_step_4')
              this.refs['number0_reverse-1'].classList.add('animation__0_reverse_step_1')
              this.refs['number0-2'].style.opacity = ''
              this.refs['number0-2'].classList.add('animation__2_reverse_step_1')
              this.refs['number0-2'].classList.remove('animation__2_reverse_step_2')
              this.refs['number0_reverse-2'].classList.remove('animation__0_reverse_step_2')

              animStep0++
              break
            case 5:
              this.refs['number0-2'].classList.add('animation__2_reverse_step_2')
              this.refs['number0_reverse-2'].style.opacity = ''
              this.refs['number0_reverse-2'].classList.add('animation__0_reverse_step_2')
              this.refs['number0-3'].classList.remove('animation__2_reverse_step_3')
              this.refs['number0_reverse-2'].classList.remove('animation__0_reverse_step_3')

              animStep0++
              break
            case 6:
              this.refs['number0_reverse-2'].classList.add('animation__0_reverse_step_3')
              this.refs['number0-3'].style.opacity = ''
              this.refs['number0-3'].classList.add('animation__2_reverse_step_3')
              this.refs['number0-3'].classList.remove('animation__2_reverse_step_4')
              this.refs['number0_reverse-3'].classList.remove('animation__0_reverse_step_4')

              animStep0++
              break
            case 7:
              this.refs['number0-3'].classList.add('animation__2_reverse_step_4')
              this.refs['number0_reverse-3'].style.opacity = ''
              this.refs['number0_reverse-3'].classList.add('animation__0_reverse_step_4')

              animStep0++
              break
            case 8:
              this.refs['number0_reverse-3'].classList.remove('animation__0_reverse_step_4')
              this.refs['number0_reverse-3'].classList.add('animation__0_reverse_step_1')
              this.refs['number0-0'].style.opacity = ''
              this.refs['number0-0'].classList.remove('animation__2_reverse_step_2')
              this.refs['number0-0'].classList.remove('animation__2_reverse_step_1')
              this.refs['number0-0'].classList.add('animation__2_reverse_step_1')

              animStep0 = 1
              break
            default:
              this.refs['number0_reverse-0'].classList.add('animation__2_reverse_step_2')
              this.refs['number0-1'].style.opacity = ''
              this.refs['number0-1'].classList.add('animation__0_reverse_step_2')

              animStep0++
          }
        }, 140)
      }, 2500)
    }

    this.refs['number2-0'].addEventListener('webkitAnimationEnd', number2FB, false)
    this.refs['number2-1'].addEventListener('webkitAnimationEnd', number2FB, false)
    this.refs['number2-2'].addEventListener('webkitAnimationEnd', number2FB, false)
    this.refs['number2-3'].addEventListener('webkitAnimationEnd', number2FB, false)
    this.refs['number0-0'].addEventListener('webkitAnimationEnd', number0FB, false)
    this.refs['number0-1'].addEventListener('webkitAnimationEnd', number0FB, false)
    this.refs['number0-2'].addEventListener('webkitAnimationEnd', number0FB, false)
    this.refs['number0-3'].addEventListener('webkitAnimationEnd', number0FB, false)
    this.refs.number2_mob.addEventListener('webkitAnimationEnd', number2FB_mob, false)
  }

  onScroll = (ev) => {
    const scrolled = ev.data.top

    if(window.innerWidth < 984 && !this.state.startMob && scrolled >= 80) {
      this.refs.number0_mob.style.opacity = ''
      this.setState({
        startMob: true
      })
    }

    if (scrolled + (window.innerHeight * .7) >= this.refs['more-work-1'].offsetTop && !this.state.work1Ran) {
      this.setState({
        work1Ran: true
      })
    }

    if (scrolled + (window.innerHeight * .7) >= this.refs['more-work-2'].offsetTop && !this.state.work2Ran) {
      this.setState({
        work2Ran: true
      })
    }
  }

  render () {
    return (
      <div className='main-wrap'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka ★ Brand Bureau</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="We tailor brand identities out of your own story. One size that uniquely fits you" />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="We tailor brand identities out of your own story. One size that uniquely fits you" />
          <meta property="og:title" content="Shuka ★ Brand Bureau" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <TouchNav />
        <header className="head new">
          <div className="head-body">
            <div className="head-body--inner">
              <div className="head-logo mars">
                <div className='head-logo-text'>
                  <div className="head-logo-text--main"><span>shuka</span><span className="umlaut">shüka</span></div>
                  <span className='head-logo-text--desc'>the brand bureau</span>
                  <p className='head-logo-text--moto'>we wish you a happy two and a merry zero</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="portfolio at-career">
          <div className="calendar-item-2020 is-hidden--desk">
            <div>
              <div style={{paddingTop: '100%', position: 'relative'}}>
                <img className={css('calendar-item-2020--img', 'on-mob', {'animation__2_step_1': this.state.startMob})} ref="number2_mob" src={this.state.number2_mob.img} />
                <img className={css('calendar-item-2020--img', 'on-mob', {'animation__0_step_1': this.state.startMob})} style={{opacity: '0'}} ref="number0_mob" src={this.state.number0_mob.img} />
              </div>
              <div className={css("mars", "calendar-item-2020--title", {'is-shown': this.state.showText})}>{this.state.number_visible_mob.word}</div>
              <div className={css("pluto", "calendar-item-2020--sub-title", {'is-shown': this.state.showText})}>{this.state.number_visible_mob.by}</div>
            </div>
          </div>
          <div className="calendar-item-2020 is-hidden--mob">
            <div className='case-block--auto-margins case-block--auto-margins-flacon'>
              <div className='case-block--halfed-flacon'>
                <div style={{paddingTop: '100%'}}>
                  { twos1.map((two, twoIdx) => {
                      return <img key={`number2-${twoIdx}`} style={{opacity: twoIdx === 0 ? '1': '0'}} className={css('calendar-item-2020--img', {'animation__2_step_1': twoIdx === 0})} ref={`number2-${twoIdx}`} src={two.img} />
                    })
                  }
                  { twos2.map((two, twoIdx) => {
                      return <img key={`number2_reverse-${twoIdx}`} style={{opacity: twoIdx === 0 ? '1': '0'}} className={css('calendar-item-2020--img', {'animation__0_step_1': twoIdx === 0})} ref={`number2_reverse-${twoIdx}`} src={two.img} />
                    })
                  }
                </div>
                <div className={css("mars", "calendar-item-2020--title", {'is-shown': this.state.showText})}>{this.state.number2_visible.word}</div>
                <div className={css("pluto", "calendar-item-2020--sub-title", {'is-shown': this.state.showText})}>{this.state.number2_visible.by}</div>
              </div>
              <div className='case-block--halfed-flacon'>
                <div style={{paddingTop: '100%'}}>
                  { zeros1.map((zero, zeroIdx) => {
                      return <img key={`number0-${zeroIdx}`} style={{opacity: zeroIdx === 0 ? '1': '0'}} className={css('calendar-item-2020--img', {'animation__2_reverse_step_1': zeroIdx === 0})} ref={`number0-${zeroIdx}`} src={zero.img} />
                    })
                  }
                  { zeros2.map((zero, zeroIdx) => {
                      return <img key={`number0_reverse-${zeroIdx}`} style={{opacity: zeroIdx === 0 ? '1': '0'}} className={css('calendar-item-2020--img', {'animation__0_reverse_step_1': zeroIdx === 0})} ref={`number0_reverse-${zeroIdx}`} src={zero.img} />
                    })
                  }
                </div>
                <div className={css("mars", "calendar-item-2020--title", {'is-shown': this.state.showText})}>{this.state.number0_visible.word}</div>
                <div className={css("pluto", "calendar-item-2020--sub-title", {'is-shown': this.state.showText})}>{this.state.number0_visible.by}</div>
              </div>
            </div>
          </div>
        </div>
        <div className='portfolio at-main'>
          <Suspense fallback={<div className='loader loader--small'></div>}>
            <PortfolioCellLarge slideIn={true} img={[nike, run]} side='left' link='/nike' title="enriching <br class='is-hidden--desk'/>nike berlin <br class='is-hidden--desk'/>collection" secondTitle="with <br class='is-hidden--desk'/>the <span class='icon'>D</span> raving <br class='is-hidden--desk'/>graphics" />
            <PortfolioCellLarge img={[chess, chess_eyes]} side='right' link='/world-chess-london' title="re-humanising <br class='is-hidden--desk'/>world chess <br class='is-hidden--desk'/>brand" secondTitle="with the <span class='icon'>q</span> <br class='is-hidden--desk'/>'pawnographic' identity" />
            <PortfolioCellNew img='https://shuka.design/images/derbent-main-rose_1-1560853573.gif' link='/rossiyskoye' title='rossiyskoe champagne identity' secondTitle='made of the primordial charm of nature’s wild' />
            <PortfolioCellNew img={lamoda} link='/lamoda' background={true} title='dressing up lamoda' secondTitle='into the daily fashion consultant' width="85%"/>
            <PortfolioCellNew img={flacon} link='/flacon' data-bodymovin="flacon" title='revitalising flacon brand' secondTitle='from the design factory into the design district' />
            <PortfolioCellNew img={ba} link='/fragrance-library' title='crafting an odd identity' secondTitle='for the biblioteka aromatov bizzare odors' />
          </Suspense>
        </div>
        <div className="portfolio-more-btn pluto" ref='more-work-1'>
          <Link to="/work" className="portfolio-href">
            <div className="portfolio-more-btn--inner">
              <span className={css("portfolio-more-btn--pretext", "pluto", {'is-animated': this.state.work1Ran})}>tap to view</span><span className="portfolio-more-btn--href">MORE PROJECTS</span>
            </div>
          </Link>
        </div>
        <SubjectCases />
        <div className="portfolio-more-btn pluto" ref='more-work-2'>
          <Link to="/work" className="portfolio-href">
            <div className="portfolio-more-btn--inner">
              <span className={css("portfolio-more-btn--pretext", 'is-long', "pluto", {'is-animated': this.state.work2Ran})}>last call to view</span><span className="portfolio-more-btn--href">MORE PROJECTS</span>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}
