import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import og from '../../assets/events.png'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'

import conf from '../../assets/publications/bnconf.jpg'

export default class extends Component {
  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    document.documentElement.style.overflow = ''
  }

  render () {
    return (
      <div className='case-root'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka 🍸 events</title>
          <meta name="keywords" content="Design Bureau, Shuka, Web Development, Portfolio, Identities, Commercials, Websites, Apps, Books" />
          <meta name="description" content="We try our best to be a better citizen of the international design community. We participate in workshops and seminars, and tell design-related jokes at parties." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="We try our best to be a better citizen of the international design community. We participate in workshops and seminars, and tell design-related jokes at parties." />
          <meta property="og:title" content="Shuka 🍸 events" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <TouchNav navBg='transparent' color='dark' />
          <CaseHead leftTitle='events:' rightTitle="lectures, workshops and parties" menuColor='dark' isTech='true' />
        </div>
        <div className='head-line Cf'>
          <div className="head-line-start icon zero">ead</div>
          <p className="head-line-end mercury">you can book us for a lecture or a workshop. we are eager to pass our experience of working with illustrators, editorial offices and agencies, or to share everything we know about fonts and typography. if you are interested, please let us know:<nobr><span className="zero">→ <a href="mailto:hello@shuka.design"><b className="underline"></b>hello@shuka.design</a></span></nobr></p>
        </div>
        <div className='events-table Cf'>
          <div className='events-table--row'>
            <div className='events-cell events-cell--head mars zero'>2019</div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">November, 9</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Sofia<br/><a href="https://festival.melba.bg/en" className=""><b className="underline"></b>Melba international design festival</a></span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/vanyavasin" className="events-cell-a"><b className="underline"></b>Ivan Vasin</a>, <a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Authenticity is a new global</span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--head mars zero'>2018</div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">September, 14</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">New York<br/><a href="https://www.underconsideration.com/brandnewconference/" className=""><b className="underline"></b>Brand New Conference</a></span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/vanyavasin" className="events-cell-a"><b className="underline"></b>Ivan Vasin</a>, <a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Chess, sex and horse riding. In search of meaningful signs</span>
            </div>
            <div className='events-cell--big mars zero'>
              <img src={conf} alt='event related' />
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">June, 7</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">St. Petersburg<br/><a href="http://www.newhollandsp.ru/" className=""><b className="underline"></b>New Holland</a></span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/profile.php?id=100000474430360" className="events-cell-a"><b className="underline"></b>Dasha Zudina</a>, <a href="https://www.facebook.com/mary.yudina.3" className="events-cell-a"><b className="underline"></b>Masha Yudina</a><br/>on Designer’s failure psychology</span>
              <span className="mercury zero event-video">→<a href="https://m.vk.com/video-28625572_456239178?list=e3be69760a3ae0651c&amp;from=wall3921485_8122&amp;fbclid=IwAR1JwCS6NbZTErTAAIlJSrswt7dU4a4lxvKkDV8pNQrpdHm_2jFW_RkHKaA"><b className="underline"></b>video</a></span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">April, 14</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">St. Petersburg<br/><a href="http://lektorij.ru/" className=""><b className="underline"></b>Lektorij</a></span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Parzifal’s Three Lessons: identity workshop</span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">February, 21</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>World Chess Club Moscow</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/vanyavasin" className="events-cell-a"><b className="underline"></b>Ivan Vasin</a>, <a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>on World Chess Branding</span>
              <span className="mercury zero event-video">→<a href="https://www.facebook.com/worldchessclubmoscow/videos/1605176522912223"><b className="underline"></b>video</a></span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--head mars zero'>2016</div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">December, 17-18</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">St. Petersburg<br/><a href="http://school.rs/" className=""><b className="underline"></b>Rasskazov’s Type school</a></span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Typography workshop</span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">April, 23</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">St. Petersburg<br/>Design Prosmotr</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Identification and identity</span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">March, 26</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Novosibirsk<br/>Codefest</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Turbo-identity in the turbo-world</span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">March, 9</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>Wordshop Academy</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>The Designer's Identification</span>
              <span className="mercury zero event-video">→<a href="https://youtu.be/tuYQ-fuGPnk?list=PLwtlEmyIMG8ElRAFB1arBsw7tJv5NwCR8"><b className="underline"></b>video</a></span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">March, 2</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>Culture club</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Text + Text, Pictures + Pictures</span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">January, 21</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>STRELKA</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/vanyavasin" className="events-cell-a"><b className="underline"></b>Ivan Vasin</a><br/>How culture works. Design in the age of fast consumption</span>
              <span className="mercury zero event-video">→<a href="https://www.facebook.com/strelkainrussian/photos/?tab=album&album_id=10153440580148269"><b className="underline"></b>photo</a></span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--head mars zero'>2015</div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">September, 19</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>Serebro Nabora Type Conference</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Working with font in the age of infitine diversity</span>
              <span className="mercury zero event-video">→<a href="https://www.youtube.com/watch?v=DbrasIfPgHw&list=PLHcJ63erF3W7g90x9zVyQ1JzhuITej1oU&index=4"><b className="underline"></b>video</a></span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">June, 23</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>Typomania</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/vanyavasin" className="events-cell-a"><b className="underline"></b>Ivan Vasin</a><br/>The wisdom behind the editorial design or how to treat the lack of formal text structure</span>
              <span className="mercury zero event-video">→<a href="https://www.youtube.com/watch?v=5gubSKCcC3I"><b className="underline"></b>video</a></span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">May, 28</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>re:store academy</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/vanyavasin" className="events-cell-a"><b className="underline"></b>Ivan Vasin</a>, <a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>What a graphic designer really does</span>
              <span className="mercury zero event-video">→<a href="https://www.youtube.com/watch?v=VUdF0eg4vUc"><b className="underline"></b>video</a></span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">April, 18</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Rostov-on-Don</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Text + Pictures</span>
              <span className="mercury zero event-video">→<a href="https://vimeo.com/125705941"><b className="underline"></b>video</a></span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--head mars zero'>2014</div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">November, 26—30</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>Non-fiction</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://tr.im/SYOKy" className="events-cell-a"><b className="underline"></b>Shuka</a><br/>The exhibition of declined cover art for Prime Russian Magazine</span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">November, 30</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>Non-fiction</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>Illustration in contemporary media</span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">November, 2</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Krasnoyarsk<br/>KRYAKK</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>The book design between contemporary art and mass market</span>
            </div>
          </div>
          <div className='events-table--row'>
            <div className='events-cell events-cell--a Cf'>
              <span className="mercury">September, 21</span>
            </div>
            <div className='events-cell events-cell--b Cf'>
              <span className="mercury">Moscow<br/>Lustra fest</span>
            </div>
            <div className='events-cell events-cell--c Cf' style={{marginRight: '0'}}>
              <span className="mercury"><a href="https://www.facebook.com/velichko" className="events-cell-a"><b className="underline"></b>Ivan Velichko</a><br/>The lettering: neither calligraphy, nor typography</span>
              <span className="mercury zero event-video">→<a href="http://vk.com/video-73999786_170704329"><b className="underline"></b>video</a></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
