import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'
import PublicationArticle from '../PublicationArticle'

import Link from '../../helpers/Link'

import facade2017 from '../../assets/archive/2017-calendar.png'
import facadeClassic from '../../assets/archive/classic.png'
import facadeRedEdition from '../../assets/archive/red-edition.jpg'
import facadeUnwraping from '../../assets/archive/unwraping.gif'
import og from '../../assets/publications/og_v2.png'

export default class extends Component {
  componentWillMount () {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    document.documentElement.style.overflow = ''
  }

  render () {
    return (
      <div className='main-wrap'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shuka ⚒ Deed</title>
          <meta name="keywords" content="shuka, design portfolio, branding, identity, books, mazagines" />
          <meta name="description" content="Branding. Identity. Design. Lots of our past works. Put together neatly on a single page" />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="Branding. Identity. Design. Lots of our past works. Put together neatly on a single page" />
          <meta property="og:title" content="Shuka ⚒ Deed" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <TouchNav />
        <header className="head new">
          <div className="head-body">
            <div className="Cf head-body--inner">
              <Link to="/" className="head-logo mars">
                <div className='head-logo-text'>
                  <div className="head-logo-text--main"><span>shuka</span><span className="umlaut">shüka</span></div>
                </div>
              </Link>
            </div>
          </div>
        </header>
        <div className='portfolio at-career'>
          <div className='portfolio-cell slide-in Facadelist'>
            <Link className='Facadelist--link' to="/facades/red-edition" target="_blank" rel="noopener noreferrer">
              <img src={facadeRedEdition} alt="facade preview" />
            </Link>
          </div>
          <div className='portfolio-cell slide-in Facadelist'>
            <Link className='Facadelist--link' to="/facades/unwrapping" target="_blank" rel="noopener noreferrer">
              <img src={facadeUnwraping} alt="facade preview" />
            </Link>
          </div>
          <div className='portfolio-cell slide-in Facadelist'>
            <Link className='Facadelist--link' to="/facades/1" target="_blank" rel="noopener noreferrer">
              <img src={facade2017} alt="facade preview" />
            </Link>
          </div>
          <div className='portfolio-cell slide-in Facadelist'>
            <Link className='Facadelist--link' to="/facades/2" target="_blank" rel="noopener noreferrer">
              <img src={facadeClassic} alt="facade preview" />
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
