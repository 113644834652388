import React, { Component } from 'react'
import * as css from 'classnames'
import Logo from './Logo'
import Link from '../helpers/Link.js'

export default class extends Component {
  state = {
    smallLogo: false
  }

  componentDidMount () {
    document.addEventListener('scrolled', this.onScroll)
  }

  componentWillUnmount () {
    document.removeEventListener('scrolled', this.onScroll)
  }

  onScroll = (ev) => {
    const scrolled = ev.data.top
    const shuka = document.querySelector('.nav-icon')

    if (shuka !== null) {
      if ((shuka.getBoundingClientRect().top <= scrolled) && !this.state.smallLogo) {
        this.setState({
          smallLogo: true
        })
      } else if ((shuka.getBoundingClientRect().top >= scrolled) && this.state.smallLogo) {
        this.setState({
          smallLogo: false
        })
      }
    }
  }

  render () {
    return (
      <div className={css('nav-body', 'nav-body--l', 'no-touch', 'pluto', `${this.props.color}`, {'small-logo': this.state.smallLogo})}>
        <Link setPreset={this.props.setPreset} setExitAnimation={this.props.setExitAnimation} className={this.props.page === '/' ? `nav-icon is-invalid icon` : `nav-icon icon`} to='/'>
          <Logo logoRef={el => this.logo = el} />
        </Link>
        <div className="nav-list nav-list--l">
          <Link setPreset={this.props.setPreset} setExitAnimation={this.props.setExitAnimation} to="/about" className={css('nav-a', 'caps', {'is-active': this.props.page.includes('/about')})} data-action="tap"><b className="underline"></b>About</Link>
          <Link setPreset={this.props.setPreset} setExitAnimation={this.props.setExitAnimation} to="/work" className={css('nav-a', 'caps', {'is-active': this.props.page.includes('/work')})} data-action="tap"><b className="underline"></b>Work</Link>
          <Link setPreset={this.props.setPreset} setExitAnimation={this.props.setExitAnimation} to="/newsletters" className={css('nav-a', 'nav-a--magin-l-r', 'caps', {'is-active': this.props.page.includes('/newsletters')})} data-action="tap"><b className="underline"></b>Newsletter</Link>
        </div>
      </div>
    )
  }
}
