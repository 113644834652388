import React, { Component } from 'react'
import * as css from 'classnames'
import { Helmet } from 'react-helmet'
import TouchNav from '../TouchNav'
import CaseHead from '../CaseHead'

import Newsletter from '../newsletters/base'

import sendDataToServer from '../../helpers/sendDataToServer'
import Link from '../../helpers/Link'

import charlotte from '../../assets/contact/charlotte.svg'
import og from '../../assets/letters/og_v2.png'

export default class extends Component {
  state = {
    newsletters: [],
    newsletters_heading: '',
    newsletters_description: '',
    canEnroll: false,
    email: '',
    newsletterFormHidden: false,
    successMsgHidden: true
  }

  componentDidMount() {
    document.documentElement.style.overflow = ''

	  /*
    const test = {
      api: '42Rf0Ds3p27b84F',
      link: 'https://test.shuka.design/api/newsletters/list'
    }

    const production = {
      api: '42Rf0Ds3.p27b84F',
      link: '/api/newsletters/list'
    }
    */

    const data = new FormData()
    data.append('_api_key', process.env.REACT_APP_API_KEY)

    fetch(process.env.REACT_APP_NEWS_LETTERS_LIST_URL, {
      method: 'POST',
      body: data
    })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad response from server")
        }
        return response.json()
      })
      .then((data) => {
        const { newsletters, newsletters_description, newsletters_heading, newsletters_title } = data._data

        this.setState({
          newsletters,
          newsletters_description,
          newsletters_heading,
          newsletters_title
        })
      })
  }

  componentWillMount () {
    window.scrollTo(0, 0)
  }

  constructor(props) {
    super(props)

    this.checkEmail = this.checkEmail.bind(this)
    this.subscribe = this.subscribe.bind(this)
  }

  subscribe (ev) {
    ev.preventDefault()

    let subscription = new window.FormData()
    subscription.set('email', this.state.email)

    sendDataToServer('/ajax/subscribe', (data) => {
      if( data.code === 0 ) {
        this.setState({
          newsletterFormHidden: true
        })

        setTimeout(() => {
          this.setState({
            successMsgHidden: false
          })
        }, 330)
      } else if( data.code === -1 ) {
        this.refs['email-input'].value = data.msg
      }
    }, {
      method: 'POST',
      body: subscription
    })
  }

  checkEmail (ev) {
    const email = ev.target.value
    const re = /\S+@\S+\.\S+/

    this.setState({
      canEnroll: email.match(re) !== null,
      email: ev.target.value
    })
  }

  render () {
    const { newsletters, newsletters_description, newsletters_heading, newsletters_title } = this.state

    return (
      <div className='case-root case-root--letters'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Charlotte Brontë 🙎‍♀️ plummy-yummy comic strips</title>
          <meta name="keywords" content="shuka, design portfolio, branding, identity, books, mazagines" />
          <meta name="description" content="Why do our subscribers adore Charlotte Brontë even though but few of them have read Jane Eyre? Simple. From time to time they receive plummy-yummy comic strips featuring Charlotte, the First Lady Of Design. Here, take a look." />
          <meta property="og:site_name" content="Shuka Brand Bureau" />
          <meta property="og:description" content="Why do our subscribers adore Charlotte Brontë even though but few of them have read Jane Eyre? Simple. From time to time they receive plummy-yummy comic strips featuring Charlotte, the First Lady Of Design. Here, take a look." />
          <meta property="og:title" content="Charlotte Brontë 🙎‍♀️ plummy-yummy comic strips" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={og} />
          <meta property="og:image:width" content="720" />
          <meta property="og:image:height" content="380" />
        </Helmet>
        <div className='case-root--inner' data-trigger="dark">
          <TouchNav navBg='transparent' color='dark' />
          <CaseHead leftTitle={ newsletters_title } rightTitle={ newsletters_heading } menuColor='dark' isTech='true' />
        </div>
        <div className='head-line Cf'>
          <div className="head-line-start">
            <img src={charlotte} alt='charlotte' />
          </div>
          <div className='head-line-end'>
            <div className="newsletter">
              <div className={css("success-msg", { 'is-shown': !this.state.successMsgHidden })}>
                <p className="success-msg-p">Thnx! Check your inbox to confirm the subscription</p>
              </div>
              { this.state.successMsgHidden &&
                  <form className={css("newsletter-form", {'is-hidden': this.state.newsletterFormHidden})} onSubmit={this.subscribe} ref='newsletter-form'>
                    <label htmlFor="email" className="newsletter-label">
                      <input type="text" className="newsletter-email" id="email" ref='email-input' placeholder="email, please" onChange={this.checkEmail} autoComplete="off" />
                    </label>
                    <button className={css('newsletter-success', 'bold', 'caps', {'is-active': this.state.canEnroll})} disabled={!this.state.canEnroll}>Enroll me</button>
                  </form>
              }
            </div>
            <p className="mercury" dangerouslySetInnerHTML={{ __html: newsletters_description }}></p>
          </div>
        </div>
        <div className='portfolio at-main'>
          { newsletters.map((newsletter, idx) => {
              return (
                <div className='portfolio-cell slide-in Letterlist' key={`newsletter-${idx}`}>
                  <Link className='Letterlist--link' to={`/newsletters/${newsletter.id}`} target="_blank" rel="noopener noreferrer">
                    <img src={newsletter.image_url} alt="newsletter cover" />
                  </Link>
                  <div className="Letterlist--date">
                    <span>{newsletter.published_y}</span>
                    <span>{newsletter.published_md}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}
