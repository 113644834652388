import React, { useState, lazy, Suspense, useEffect } from 'react'
import * as css from 'classnames'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'

import customHistory from './history'

import NavigationLeft from './components/NavigationLeft'
import NavigationRight from './components/NavigationRight'
import Footer from './components/Footer'

import Main from './components/Main'
import Work from './components/Work'
import Error404 from './components/Error'

import Careers from './components/tech-pages/Careers'
import Press from './components/tech-pages/Press'
import Awards from './components/tech-pages/Awards'
import Publications from './components/tech-pages/Publications'
import Events from './components/tech-pages/Events'
import Education from './components/tech-pages/Education'
import Contact from './components/tech-pages/Contact'
import About from './components/tech-pages/About'
import Newsletters from './components/tech-pages/Newsletters'
import Emojinarium from './components/tech-pages/Emojinarium'
import Hints from './components/tech-pages/Hints'
import Content from './components/tech-pages/Content'

import Newsletter from './components/newsletters/base'
import Newsletter1 from './components/newsletters/1'
import Newsletter2 from './components/newsletters/2'

import Facades from './components/tech-pages/Facades.js'
import FacadesClassic from './components/facades/classic'
import FacadesRedEdition from './components/facades/redEdition'
import FacadesUnwrapping from './components/facades/unwrapping'
import Facades2017 from './components/facades/2017'
import Facades2020 from './components/facades/2020'

const Lamoda = lazy(() => import('./components/cases/Lamoda'))
const WorldChessLondon = lazy(() => import('./components/cases/WorldChessLondon'))
const FragranceLibrary = lazy(() => import('./components/cases/FragranceLibrary'))
const Mercuryo = lazy(() => import('./components/cases/Mercuryo'))
const Gagbot = lazy(() => import('./components/cases/GagbotFunc'))
const Aids = lazy(() => import('./components/cases/AidsFunc'))
const Gravity = lazy(() => import('./components/cases/GravityFunc'))
const Hyperverse = lazy(() => import('./components/cases/HyperverseFunc'))
const Brobrolab = lazy(() => import('./components/cases/BrobrolabFunc'))
const Chessclubmoscow = lazy(() => import('./components/cases/ChessclubmoscowFunc'))
const PureApp = lazy(() => import('./components/cases/PureAppFunc'))
const Flatplan = lazy(() => import('./components/cases/FlatplanFunc'))
const Jederhaseeinkuenstler = lazy(() => import('./components/cases/JederhaseeinkuenstlerFunc'))
const WorldChessChampionship = lazy(() => import('./components/cases/WorldChessChampionshipFunc'))
const Osome = lazy(() => import('./components/cases/Osome'))
const Nachili = lazy(() => import('./components/cases/Nachili'))
const BritishArtSchoolPosters = lazy(() => import('./components/cases/BritishArtSchoolPosters'))
const Karabas = lazy(() => import('./components/cases/Karabas'))
const SexIsPure = lazy(() => import('./components/cases/SexIsPure'))
const TheUntitled = lazy(() => import('./components/cases/TheUntitled'))
const ParkRemesel = lazy(() => import('./components/cases/ParkRemesel'))
const PRM2 = lazy(() => import('./components/cases/PRM2'))
const PRM1 = lazy(() => import('./components/cases/PRM1'))
const Heritage2012 = lazy(() => import('./components/cases/Heritage2012Func'))
const Heritage2013 = lazy(() => import('./components/cases/Heritage2013Func'))
const PureDating = lazy(() => import('./components/cases/PureDatingFunc'))
const Soul = lazy(() => import('./components/cases/SoulFunc'))
const Rossiyskoye = lazy(() => import('./components/cases/Rossiyskoye'))
const Iitp = lazy(() => import('./components/cases/Iitp'))
const Flacon = lazy(() => import('./components/cases/Flacon'))
const Nike = lazy(() => import('./components/cases/Nike'))
const Armageddon = lazy(() => import('./components/cases/Armageddon'))
const Skyeng = lazy(() => import('./components/cases/Skyeng'))
const Orbi = lazy(() => import('./components/cases/Orbi'))
const Merchlab = lazy(() => import('./components/cases/Merchlab'))
const Semrush = lazy(() => import('./components/cases/Semrush'))
const PorterAndSail = lazy(() => import('./components/cases/PorterAndSail'))
const Abbyy = lazy(() => import('./components/cases/Abbyy'))
const Photozhir = lazy(() => import('./components/cases/Photozhir'))
const Dolyame = lazy(() => import('./components/cases/Dolyame'))
const Cian = lazy(() => import('./components/cases/Cian'))
const Sber = lazy(() => import('./components/cases/Sber'))
const Delivery1520 = lazy(() => import('./components/cases/Delivery1520'))
const Freezers = lazy(() => import('./components/cases/Freezers'))
const Seasons = lazy(() => import('./components/cases/Seasons'))
const Yermolova = lazy(() => import('./components/cases/Yermolova'))
const Mif = lazy(() => import('./components/cases/Mif'))
const Jiffy = lazy(() => import('./components/cases/Jiffy'))

const App2 = () => {
  const colors = ['/armageddon', '/gravity', '/iitp-identity', '/flacon', '/soul', '/nike', '/mercuryo', '/world-chess-championship', '/brobrolab-identity', '/semrush']
  const mobColors = ['/gravity', '/iitp-identity', '/soul', '/nike', '/semrush']
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')
  const [navColor, setNavColor] = useState(colors.includes(window.location.pathname) ? 'light' : 'dark')
  const switchNavColor = color => setNavColor(color)

  const techPages= ['/', '/newsletters', '/work', '/about', '/contact', '/publications', '/awards', '/education', '/press', '/careers', '/hints', '/emojinarium', '/events', '/Content']
  const bgs = {
    '/rossiyskoye': '#f9dd89',
    '/gravity': '#00002e'
  }

  customHistory.listen((newlocation) => {
    const isTech = techPages.includes(newlocation.pathname)

    if (isTech) {
      setNavColor('dark')
    }

    if (colors.includes(newlocation.pathname)) {
      setNavColor('light')
    }
  })

  const closeModal = () => {
    setShowModal(false)
    setMessage('')
  }

  useEffect(() => {
    const url_string = window.location.href
    const url = new URL(url_string)
    const activate = url.searchParams.get("activate")
    const deactivate = url.searchParams.get("deactivate")

    if (activate === 'yes') {
      setShowModal(true)
      setMessage('You have been successfully subscribed!')
    } else if (deactivate === 'yes') {
      setShowModal(true)
      setMessage('You have unsubscribed. See you!')
    }
  }, [])

  const location = useLocation()
  const re = /(newsletters\/\d+(\.\d)*)/i
  const showNav = location.pathname.match(re) === null
  const isTech = techPages.includes(location.pathname)
  const isPrevTech = location.state === undefined ? true : techPages.includes(location.state.prev)

  const transitions = useTransition(location, location => location.pathname, {
    initial: { opacity: 1 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
    trail: 2510, // animation duration + wanted pause
    config: {
      duration: 510
    }
  })

  return (
    <>
      { showNav && <NavigationLeft color={navColor} page={location.pathname} /> }
      { showNav && <NavigationRight color={navColor} page={location.pathname} /> }
      { transitions.map(({ item: location, props, key, state }) => {
          const { pathname } = location
          let wrapperCase = 'case'
          if (isTech && state === 'initial' || isTech && state === 'enter'|| isTech && state === 'update' || isPrevTech && state === 'leave') {
            wrapperCase = 'tech'
          }

          const caseProps = {
            open: true,
            switchNavColor: switchNavColor,
            state,
            isPrevCase: location.state !== undefined ? location.state.isPrevCase : false
          }

          let prev = ' from-tech-to-tech'
          if (state === 'leave') {
            if (isTech) {
              prev = ` from-${wrapperCase}-to-tech`
            } else {
              prev = ` from-${wrapperCase}-to-case`
            }
          }

          return (
            <animated.div key={key} style={props} className={`wrapper-is-${wrapperCase} wrapper-${state}${state === 'leave' ? prev : ''}`}>
              <Suspense fallback={<div className='loader'></div>}>
                <Switch location={location}>
                  <Route exact path="/" render={() => <Main state={state} /> } />
                  <Route path="/work" render={() => <Work state={state} /> } />
                  <Route path="/careers" render={() => <Careers state={state} /> } />
                  <Route path="/press" render={() => <Press state={state} /> } />
                  <Route path="/awards" render={() => <Awards state={state} /> } />
                  <Route path="/publications" render={() => <Publications state={state} /> } />
                  <Route path="/events" render={() => <Events state={state} /> } />
                  <Route path="/education" render={() => <Education state={state} /> } />
                  <Route path="/contact" render={() => <Contact state={state} /> } />
                  <Route path="/about" render={() => <About state={state} /> } />
                  <Route path="/hints" render={() => <Hints state={state} /> } />
                  <Route path="/emojinarium" render={() => <Emojinarium state={state} /> } />
                  <Route path="/content" render={() => <Content state={state} /> } />
                  <Route
                    path="/facades"
                    render={({ match: { url } }) => (
                      <>
                        <Route path={`${url}/`} component={Facades} exact />
                        <Route path={`${url}/2020`} component={Facades2020} />
                        <Route path={`${url}/1`} component={Facades2017} />
                        <Route path={`${url}/2`} component={FacadesClassic} />
                        <Route path={`${url}/red-edition`} component={FacadesRedEdition} />
                        <Route path={`${url}/unwrapping`} component={FacadesUnwrapping} />
                      </>
                    )}
                  />
                  <Route
                    path="/newsletters"
                    render={({ match: { url } }) => (
                      <>
                        <Route path={`${url}`} component={Newsletters} exact />
                        <Route path={`${url}/1`} component={Newsletter1} />
                        <Route path={`${url}/2`} component={Newsletter2} />
                        <Route path={`${url}/:id`} component={Newsletter} />
                      </>
                  )} />
	                {/*<Route path="/mif" render={() => <Mif {...caseProps} /> } />*/}
	                <Route path="/yermolova" render={() => <Yermolova {...caseProps} /> } />
	                <Route path="/jiffy" render={() => <Jiffy {...caseProps} /> } />
	                <Route path="/seasons" render={() => <Seasons {...caseProps} /> } />
	                <Route path="/freezers" render={() => <Freezers {...caseProps} /> } />
	                <Route path="/1520" render={() => <Delivery1520 {...caseProps} /> } />
	                <Route path="/sber" render={() => <Sber {...caseProps} /> } />
	                <Route path="/cian" render={() => <Cian {...caseProps} /> } />
	                <Route path="/dolyame" render={() => <Dolyame {...caseProps} /> } />
	                <Route path="/photozhir" render={() => <Photozhir {...caseProps} /> } />
                  <Route path="/abbyy" render={() => <Abbyy {...caseProps} /> } />
                  <Route path="/porter-and-sail" render={() => <PorterAndSail {...caseProps} /> } />
                  <Route path="/semrush" render={() => <Semrush {...caseProps} /> } />
                  <Route path="/merchlab" render={() => <Merchlab {...caseProps} /> } />
                  <Route path="/orbi" render={() => <Orbi {...caseProps} /> } />
                  <Route path="/skyeng" render={() => <Skyeng {...caseProps} /> } />
                  <Route path="/armageddon" render={() => <Armageddon {...caseProps} /> } />
                  <Route path="/nike" render={() => <Nike {...caseProps} /> } />
                  <Route path="/flacon" render={() => <Flacon {...caseProps} /> } />
                  <Route path="/rossiyskoye" render={() => <Rossiyskoye {...caseProps} /> } />
                  <Route path="/lamoda" render={() => <Lamoda {...caseProps} /> } />
                  <Route path="/iitp-identity" render={() => <Iitp {...caseProps} /> } />
                  <Route path="/fragrance-library" render={() => <FragranceLibrary {...caseProps} /> } />
                  <Route path="/world-chess-london" render={() => <WorldChessLondon {...caseProps} /> } />
                  <Route path="/mercuryo" render={() => <Mercuryo {...caseProps} /> } />
                  <Route path="/gagbot" render={() => <Gagbot {...caseProps} /> } />
                  <Route path="/aids" render={() => <Aids {...caseProps} /> } />
                  <Route path="/gravity" render={() => <Gravity {...caseProps} /> } />
                  <Route path="/hyperverse" render={() => <Hyperverse {...caseProps} /> } />
                  <Route path="/brobrolab-identity" render={() => <Brobrolab {...caseProps} /> } />
                  <Route path="/chessclubmoscow" render={() => <Chessclubmoscow {...caseProps} /> } />
                  <Route path="/pure-app" render={() => <PureApp {...caseProps} /> } />
                  <Route path="/flatplan" render={() => <Flatplan {...caseProps} /> } />
                  <Route path="/jederhaseeinkuenstler" render={() => <Jederhaseeinkuenstler {...caseProps} /> } />
                  <Route path="/world-chess-championship" render={() => <WorldChessChampionship {...caseProps} /> } />
                  <Route path="/osome" render={() => <Osome {...caseProps} /> } />
                  <Route path="/nachili" render={() => <Nachili {...caseProps} /> } />
                  <Route path="/british-art-school-posters" render={() => <BritishArtSchoolPosters {...caseProps} /> } />
                  <Route path="/karabas" render={() => <Karabas {...caseProps} /> } />
                  <Route path="/sex-is-pure" render={() => <SexIsPure {...caseProps} /> } />
                  <Route path="/the-untitled" render={() => <TheUntitled {...caseProps} /> } />
                  <Route path="/park-remesel" render={() => <ParkRemesel {...caseProps} /> } />
                  <Route path="/the-prime-russian-magazine-part-2" render={() => <PRM2 {...caseProps} /> } />
                  <Route path="/the-prime-russian-magazine-part-1" render={() => <PRM1 {...caseProps} /> } />
                  <Route path="/moscow-heritage-2012" render={() => <Heritage2012 {...caseProps} /> } />
                  <Route path="/pure-dating" render={() => <PureDating {...caseProps} /> } />
                  <Route path="/soul" render={() => <Soul {...caseProps} /> } />
                  <Route path="/moscow-heritage-2013" render={() => <Heritage2013 {...caseProps} /> } />
                  <Route component={Error404} />
                </Switch>
              </Suspense>
            </animated.div>
          )
        })
      }
      { showNav && isTech && <Footer /> }
      { showModal &&
        <div className="alert">
          <div className="alert-body">
            <div className="alert-close" onClick={closeModal}></div>
            <p className="alert-msg white">{message}</p>
          </div>
        </div>
      }
    </>
  )
}

export default App2
