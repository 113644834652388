import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class extends Component {
  state = {
    title: '',
    subtitle: '',
    likeThis: 'Like this one?',
    images: [],
    share: "<span style='border-bottom: 2px solid'>S</span>hare it on Facebook!",
    share_link: '',
    share_pic: ''
  }

  componentDidMount () {

  	/*
    const test = {
      api: '42Rf0Ds3p27b84F',
      link: 'https://test.shuka.design/api/newsletters/get',
      id: this.props.match.params.id
    }

    const production = {
      api: '42Rf0Ds3.p27b84F',
      link: '/api/newsletters/get',
      id: this.props.match.params.id
    }
    */

	  const id = this.props.match.params.id

    const data = new FormData()
    data.append('_api_key', process.env.REACT_APP_API_KEY)
    data.append('newsletter_id', id)

    fetch(process.env.REACT_APP_NEWS_LETTERS_GET_URL, {
      method: 'POST',
      body: data
    })
      .then((response) => {
        if (response.status >= 400) {
          console.log("Bad response from server")
        }
        return response.json()
      })
      .then((data) => {
        const { images, description, title, share_link, image_url } = data._data

        this.setState({
          subtitle: description,
          title,
          images,
          share_link,
          share_pic: image_url
        })
      })
  }

  render () {
    return (
      <center style={{backgroundColor: '#f2f2f2'}}>
        <div style={{ height: '20px' }} className="sm-hidden"></div>
        <Helmet>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="format-detection" content="telephone=no" />
          <style type="text/css">{`
              body, #bodyTable, #bodyCell, #bodyCell{height:100% !important; margin:0; padding:0; width:100% !important;font-family:Helvetica, Arial, "Lucida Grande", sans-serif;}
              table{border-collapse:collapse;}
              table[id=bodyTable] {width:100%!important;margin:auto;max-width:600px!important;color:#7A7A7A;font-weight:normal;}
              img, a img{border:0; outline:none; text-decoration:none;height:auto; line-height:100%;}
              a {text-decoration:none !important;color:inherit;}
              h1, h2, h3{color:#5F5F5F; font-weight:normal; font-family:Helvetica; font-size:20px; line-height:125%; text-align:Left; letter-spacing:normal;margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;padding-top:0;padding-bottom:0;padding-left:0;padding-right:0;}
              table, td{mso-table-lspace:0pt; mso-table-rspace:0pt;}
              img{-ms-interpolation-mode: bicubic;display:block;outline:none; text-decoration:none;}
              body, table, td, p, a, li, blockquote{-ms-text-size-adjust:100%; -webkit-text-size-adjust:100%; font-weight:normal!important;}
              h1{display:block;font-size:26px;font-style:normal;font-weight:normal;line-height:100%;}
              h2{display:block;font-size:20px;font-style:normal;font-weight:normal;line-height:120%;}
              h3{display:block;font-size:17px;font-style:normal;font-weight:normal;line-height:110%;}
              body, #bodyTable{background-color:#f2f2f2;}
              #emailHeader{background-color:#fff;}
              #emailBody{background-color:#FFFFFF;}
              .textContent, .textContentLast{color:#8B8B8B; font-family:Helvetica; font-size:16px; line-height:125%; text-align:Left;}
              .textContent a, .textContentLast a{color:#205478; text-decoration:underline;}
              .nestedContainer{background-color:#F8F8F8; border:1px solid #CCCCCC;}
              .emailCalendar{background-color:#FFFFFF; border:1px solid #CCCCCC;}
              .emailCalendarMonth{background-color:#205478; color:#FFFFFF; font-family:Helvetica, Arial, sans-serif; font-size:16px; font-weight:bold; padding-top:10px; padding-bottom:10px; text-align:center;}
              .emailCalendarDay{color:#205478; font-family:Helvetica, Arial, sans-serif; font-size:60px; font-weight:bold; line-height:100%; padding-top:20px; padding-bottom:20px; text-align:center;}
              .imageContentText {margin-top: 10px;line-height:0;}
              .imageContentText a {line-height:0;}
              #invisibleIntroduction {display:none !important;}
              .XPstart{padding-left: 72px;}
              span[className=ios-color-hack] a {color:#275100!important;text-decoration:none!important;}
              span[className=ios-color-hack2] a {color:#205478!important;text-decoration:none!important;}
              span[className=ios-color-hack3] a {color:#8B8B8B!important;text-decoration:none!important;}
              .a[href^="tel"], a[href^="sms"] {text-decoration:none!important;color:#606060!important;pointer-events:none!important;cursor:default!important;}
              .mobile_link a[href^="tel"], .mobile_link a[href^="sms"] {text-decoration:none!important;color:#606060!important;pointer-events:auto!important;cursor:default!important;}
              input {-webkit-appearance: none;border-radius:0;}
              @media only screen and (max-width: 580px){
                  .sm-single {
                    display: block;
                    width: 100%;
                  }

                  .sm-visible{
                      display: block !important;
                  }
                  .XPstart {
                      padding-left: 20px;
                  }
                  .sm-centered{
                      margin-left:auto !important;
                      margin-right:auto !important;
                  }
                  .sm-hidden{
                      display: none !important;
                  }
                  .sm-center{
                      text-align: center !important;
                  }
                  .sm-w-90p{
                      width: 90% !important;
                  }
                  .sm-w-80p{
                      width: 80% !important;
                  }
                  .sm-w-50p{
                      width: 50% !important;
                  }
                  .sm-w-60p{
                      width: 50% !important;
                  }
                  .sm-w-150{
                      width: 150px !important;
                  }
                  .sm-space-left-10{
                      padding-left: 10px !important;
                  }
                  .sm-space-left-30{
                      padding-left: 30px !important;
                  }
                  .sm-space-right-10{
                      padding-right: 10px !important;
                  }
                  .sm-space-right-30{
                      padding-right: 30px !important;
                  }
                  .sm-buffer-top-20{
                      margin-top: 20px !important;
                  }
                  .sm-space-top-20{
                      padding-top: 20px !important;
                  }
                  .sm-space-bottom-10{
                      padding-right: 10px !important;
                  }
                  .sm-space-0{
                      padding: 0px !important;
                  }
                  .sm-margin-0{
                      margin: 0px !important;
                  }
                  .sm-space-10{
                      padding: 10px;
                  }
                  .sm-font-10{
                      font-size: 10px !important;
                  }
                  .sm-font-16{
                      font-size: 16px !important;
                  }
                  .sm-font-19{
                      font-size: 19px !important;
                  }
                  .sm-font-20{
                      font-size: 20px !important;
                  }
                  .sm-font-30{
                      font-size: 30px !important;
                  }
                  .sm-font-35{
                      font-size: 35px !important;
                  }
                  .sm-font-40{
                      font-size: 40px !important;
                  }
                  .sm-font-46{
                      font-size: 46px !important;
                  }
                  .sm-top-space-20{
                      padding-top: 20px !important;
                  }
                  .sm-top-space-50{
                      padding-top: 50px !important;
                  }
                  .sm-top-space-50{
                      padding-top: 50px !important;
                  }
                  .sm-bottom-space-50{
                      padding-bottom: 50px !important;
                  }
                  .sm-h-10{
                      height: 10px !important;
                  }
                  .sm-h-15{
                      height: 15px !important;
                  }
                  .sm-h-20{
                      height: 20px !important;
                  }
                  .sm-h-30{
                      height: 30px !important;
                  }
                  .sm-h-40{
                      height: 40px !important;
                  }
                  .sm-h-50{
                      height: 50px !important;
                  }
                  .sm-h-def{
                      height: auto !important;
                  }
                  .sm-h-300{
                      height: 300px !important;
                  }
                  .sm-strange-50{
                      min-height: 50px;
                  }
                  .sm-w-def{
                      width: auto !important;
                  }
                  .sm-w-100p{
                      width: 100% !important;
                  }
                  .sm-top-space-300{
                      margin-top: 390px;
                  }
                  .sm-l-sp-4{
                      letter-spacing: 4px !important;
                  }
                  .sm-block{
                      display: block !important;
                  }
                  body{width:100% !important; min-width:100% !important;}
                  table[id="emailHeader"], table[id="emailBody"], table[className="flexibleContainer"] {width:100% !important;}
                  td[className="flexibleContainerBox"], td[className="flexibleContainerBox"] table {display: block;width: 100%;text-align: left;}
                  td[className="imageContent"] img {height:auto !important; width:100% !important; max-width:100% !important;}
                  table[className="flexibleContainerBoxNext"]{padding-top: 10px !important;}
              }
          `}</style>
          <meta property="og:description" content={this.state.subtitle} />
          <meta property="og:site_name" content="Shuka Design" />
          <meta property="og:title" content={this.state.title} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={this.state.share_pic} />
          <meta property="og:image:width" content="1048" />
          <meta property="og:image:height" content="550" />
          <meta property="fb:app_id" content="1639675322998456" />
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:url" content={`https://shuka.design/newsletters/${this.props.match.params.id}`} />
        </Helmet>
        <table bgcolor="#fff" border="0" cellPadding="0" cellSpacing="0" width="100%" id="emailHeader" style={{ color: '#8C8C8C', maxWidth: '600px' }}>
          <tbody>
            <tr>
                <td align="center" valign="top">
                    <table border="0" cellPadding="0" cellSpacing="0" width="100%">
                      <tbody>
                        <tr>
                            <td align="center" valign="top">
                                <table border="0" cellPadding="0" cellSpacing="0" style={{ maxWidth: '600px' }} width="100%" className="flexibleContainer">
                                  <tbody>
                                    <tr>
                                        <td valign="top" style={{ maxWidth: '600px' }} width="100%" className="flexibleContainerCell">
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td width="100%" height='10'>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td width="100%" height='10'>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td align="left" valign="top" className="flexibleContainerBox sm-hidden" width="200">
                                                    </td>
                                                    <td align="left" valign="top" className="flexibleContainerBox" width="300" style={{ maxWidth: '100%' }}>
                                                        <div style={{ paddingRight: '30px', fontFamily: 'Arial,sans-serif', lineHeight: '1.4', fontSize: '19px', fontWeight: 'bold', letterSpacing: '1px' }} className='sm-space-0 sm-top-space-20 sm-w-80p sm-center sm-centered'>
                                                            <span style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html:this.state.title }}></span><br/>
                                                            <span dangerouslySetInnerHTML={{ __html: this.state.subtitle }}></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td width="100%" height='50'>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td align="left" valign="top" className="flexibleContainerBox" width="100%" style={{ maxWidth: '100%' }}>
                                                        <div style={{ paddingLeft: '15px', paddingRight: '15px' }} className='sm-space-0'>
                                                            <a href="https://shuka.design/?utm_source=shuka_newsletter&utm_medium=email" style={{ textDecoration: 'none !important', color: 'inherit' }}>
                                                            { this.state.images.map((img,idx) => {
                                                                return (
                                                                  <img key={idx} src={img} width="100%" alt='comics' className='sm-centered' style={{ display: 'block' }} />
                                                                )
                                                              })
                                                            }
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td width="100%" height='70'>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                  <td align="right" valign="top" className="flexibleContainerBox sm-hidden" width="200" style={{ maxWidth: '100%' }}>
                                                </td>
                                                <td align="left" valign="middle" className="flexibleContainerBox" width="300" style={{ maxWidth: '100%' }}>
                                                  <div style={{ paddingTop: '25px', paddingRight: '30px', fontFamily: 'Arial,sans-serif', lineHeight: '1.4', fontSize: '18px', fontWeight: 'bold', letterSpacing: '1px' }} className="sm-space-0 sm-top-space-20 sm-w-80p sm-center sm-centered">
                                                    { (this.state.likeThis !== undefined) ? this.state.likeThis : 'Like this one?' }<br/>
                                                    <a href={this.state.share_link} style={{ color: 'rgb(255, 255, 255)', mixBlendMode: 'difference', textDecoration: 'none', fontWeight: 'bold' }}><b dangerouslySetInnerHTML={{ __html: this.state.share }}></b></a>
                                                  </div>
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td width="100%" height='50'>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>

                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td align="left" valign="top" className="flexibleContainerBox sm-single" width="200" style={{ maxWidth: '100%' }}>
                                                        <div style={{ paddingLeft: '20px' }} className='sm-space-0 sm-center'>
                                                            <a href="https://shuka.design/?utm_source=shuka_newsletter&utm_medium=email">
                                                                <img src="https://shuka.design/lib/images/email/shuka.gif" style={{ height: '40px', maxHeight: '40px', display: 'inline-block' }} />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td align="left" valign="middle" className="flexibleContainerBox sm-single" width="1000">
                                                          <div style={{ marginBottom: '25px', paddingRight: '20px' }} className="XPstart">
                                                              <div id='status-label' style={{ fontFamily: 'Arial,sans-serif', fontSize: '12px', fontWeight: 'bold', letterSpacing: '1px', marginBottom: '10px' }} className='sm-center'>Want to receive beautiful things?</div>
                                                              <div className='sm-center' style={{display:'block'}}>
                                                                  <input id='email' style={{ display: 'block', boxSizing: 'border-box', paddingLeft: '10px', height: '45px', paddingBottom: '4px', fontSize: '15px', border: '1px solid #6e6e6e', outline: '0', width: '100%', textTransform: 'lowercase' }} placeholder='your email, please' />
                                                              </div>
                                                              <div style={{ marginTop: '15px', textAlign: 'right' }} className='sm-center'>
                                                                <button style={{ paddingTop:'10px', paddingLeft:'18px', paddingRight:'18px',paddingBottom:'10px',fontSize:'15px',border:'1px solid #000',textAlign:'center',cursor:'pointer',backgroundColor:'#000',color:'#fff',outline:'none' }} id='subscribe-button'>YAY!</button>
                                                              </div>
                                                          </div>

                                                          <script>{`
                                                              $( document ).ready( function(){
                                                                  $( '#subscribe-button' ).click( function(){
                                                                      $.ajax({
                                                                        type: 'POST',
                                                                        url: "{{ route( 'ajax.subscribe' ) }}",
                                                                        data: { email: $('#email').val() },
                                                                        success: function(data){ $('#status-label').text( data.msg ) },
                                                                        dataType: 'json'
                                                                      });
                                                                  } );
                                                              } );`}
                                                          </script>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td width="100%" height='10'>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>

                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td align="left" valign="bottom" className="flexibleContainerBox sm-hidden" width="170" style={{ maxWidth: '100%' }}>
                                                    </td>
                                                    <td align="left" valign="bottom" className="flexibleContainerBox" width="330" style={{ maxWidth: '100%' }}>
                                                        <div style={{ paddingLeft: '30px', paddingRight: '30px', color: '#8c8c8c', fontSize: '12px', lineHeight: '1.3', fontFamily: 'Arial', fontWeight: 'bold' }} className='sm-w-80p sm-center sm-centered'>
                                                            <span style={{ letterSpacing: '1px' }}>liberté, visualité, identité</span><span style={{ marginLeft: '20px' }}>{ new Date().getFullYear() }</span>

                                                            <div style={{height:'10px'}}>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table align="left" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                              <tbody>
                                                <tr>
                                                    <td align="left" valign="bottom" className="flexibleContainerBox sm-hidden" width="170" style={{ maxWidth: '100%' }}>
                                                    </td>
                                                    <td align="left" valign="bottom" className="flexibleContainerBox" width="330" style={{ maxWidth: '100%' }}>
                                                        <div style={{ paddingLeft: '30px', paddingRight: '30px', color: '#8c8c8c', fontSize: '10px', lineHeight: '1.3', fontFamily: 'Arial' }} className='sm-w-80p sm-center sm-centered'>
                                                            This email is from Shuka Design Bureau. You have received it because you have previously requested the subscription at our website. Follow this <a href="https://shuka.design/?utm_source=shuka_newsletter&utm_medium=email" target="_blank" style={{ color: '#8c8c8c', fontFamily: 'Arial', textDecoration:'none'}}>link to unsubscribe</a>

                                                            <div style={{ height: '30px' }}>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                              </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                  </tbody>
                                </table>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </td>
            </tr>
          </tbody>
        </table>
        <div style={{ height: '20px' }} className="sm-hidden"></div>
      </center>
    )
  }
}
